import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  ComposedChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Cell,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import createTrend from 'trendline';

export default function RechartsScatter({ data }) {
  let data5 = [];
  const [data6, setData6] = useState([]);
  const [xDomain, setXDomain] = useState([]);
  const [yDomain, setYDomain] = useState([]);

  const scores = data6?.map((data) => data?.score);
  const yMax = Math.max(...scores);
  const yMin = Math.min(...scores);
  const dates = data6?.map((data) => data?.date);
  const xMax = Math.max(...dates);
  const xMin = Math.min(...dates);

  const dateFormatter = (date) => {
    return format(new Date(date), 'MM/yyyy');
  };

  const tooltipFormatter = (date) => {
    return format(new Date(date), 'MM/dd/yyyy');
  };

  useEffect(() => {
    data?.forEach((d) => {
      data5 = [
        ...data5,
        {
          date: new Date(d.test_date.replace(/-/g, '/')).getTime(),
          score: parseInt(d.unified_scaled_score),
          school_benchmark_cat: d.school_benchmark_cat,
        },
      ];
    });
    setData6(data5);
    setXDomain([data6[0]?.date, data6[data6?.length - 1]?.date]);
    setYDomain([yMin - 25, yMax + 25]);
  }, [data]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className='custom-tooltip focus-ring-none rounded-md border-0 bg-white p-4 text-lg font-medium text-brandText ring-0'>
          <p className=''>Test Results</p>
          <p className=''>{`Test Date: ${tooltipFormatter(
            payload[0]?.payload?.date,
          )}`}</p>
          <p className='label'>{`Scale Score: ${payload[0]?.payload?.score.toFixed(
            2,
          )}`}</p>
        </div>
      );
    }
    return null;
  };

  const getScatterColor = (d) => {
    if (d.school_benchmark_cat === 'Urgent Intervention') {
      return '#ec0000';
    }
    if (d.school_benchmark_cat === 'Intervention') {
      return '#ffcc00';
    }
    if (d.school_benchmark_cat === 'On Watch') {
      return '#0a628d';
    }
    if (d.school_benchmark_cat === 'At/Above Benchmark') {
      return '#00a929';
    } else {
      return '#f1f5f9';
    }
  };

  const trendData = () => {
    const trend = createTrend(data6, 'date', 'score');

    return [
      { score: trend.calcY(xMin), date: xMin },
      { score: trend.calcY(xMax), date: xMax },
    ];
  };

  const [trendFirstCoord, trendLastCoord] = trendData();

  return (
    <div className='mx-auto flex h-96 w-full max-w-7xl flex-col justify-center rounded-2xl p-10 shadow-lg ring-2 ring-gray-100 md:h-[450px] lg:h-[600px]'>
      <ResponsiveContainer width='100%' height='100%'>
        <ComposedChart
          width={400}
          height={400}
          data={data6}
          margin={{
            top: 20,
            right: 40,
            bottom: 0,
            left: 7,
          }}
        >
          <YAxis
            type='number'
            domain={yDomain}
            dataKey='score'
            name='Scale Score'
            ticks={[yMin - 25, (yMin - 25 + (yMax + 25)) / 2, yMax + 25]}
            tickCount={3}
          />
          <XAxis
            type='number'
            domain={xDomain}
            tickFormatter={dateFormatter}
            /* scale={'time'} */
            dataKey={'date'}
            name='Test Date'
            minTickGap={10}
          />
          <ZAxis dataKey={'score'} range={[250, 900]} />
          <Tooltip
            content={CustomTooltip}
            /* formatter={tooltipFormatter}
            cursor={{ strokeDasharray: '3 3' }} */
          />
          <Scatter
            name='A school'
            shape={'circle'}
            isAnimationActive={true}
            animationDuration={1500}
            dataKey={'score'}
            fill='#8884d8'
          >
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={getScatterColor(entry) ?? '#f1f5f9'}
              />
            ))}
          </Scatter>
          <ReferenceLine
            ifOverflow='extendDomain'
            segment={[
              { x: trendFirstCoord.date, y: trendFirstCoord.score },
              { x: trendLastCoord.date, y: trendLastCoord.score },
            ]}
            stroke='#0cb2da'
            strokeDasharray={'6, 2'}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}
