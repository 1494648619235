import SidebarLayout from '../components/SidebarLayout';
import Cards from '../components/Cards';
import Card from '../components/Card';
import CardSchool from '../components/CardSchool';
import { useContext, useState, useEffect } from 'react';
import AuthContext from '../context/AuthContext';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';

/* Tiles */
import mtssManagementCard from '../images/dashboard_cards/mtss_management.png';
import schoolCard from '../images/dashboard_cards/school.webp';
import openReferralsCard from '../images/dashboard_cards/open_referrals.webp';
import currentActionsCard from '../images/dashboard_cards/current_actions.webp';
import watchlistCard from '../images/dashboard_cards/watchlist.webp';
import compassCard from '../images/dashboard_cards/compass.webp';

/* Icons */
import manageIcon from '../images/dashboard_icons/manage.svg';
import openReferralsIcon from '../images/dashboard_icons/open_referrals.svg';
import currentActionsIcon from '../images/dashboard_icons/current_actions.svg';
import watchlistIcon from '../images/dashboard_icons/watchlist.svg';
import schoolIcon from '../images/dashboard_icons/school.svg';
import compassIcon from '../images/dashboard_icons/compass.svg';

export default function Lighthouse() {
  let { authTokens, baseUrl, user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [schools, setSchools] = useState([]);

  useEffect(() => {
    getSchools();
  }, []);

  const getSchools = async () => {
    let response = await fetch(`${baseUrl}/core/profiles/me/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setSchools(data?.sst_schools);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-12 sm:pb-16 lg:pb-20'>
              <div>
                <Cards
                  cardProps={
                    <>
                      {(user?.groups?.includes('mtss_member') ||
                        user?.groups?.includes('mtss_admin')) && (
                        <Card
                          cardImage={openReferralsCard}
                          href={'/mtss/referrals'}
                          cardName={'Open Referrals'}
                          cardIcon={openReferralsIcon}
                        />
                      )}

                      {(user?.groups?.includes('mtss_member') ||
                        user?.groups?.includes('mtss_admin')) && (
                        <Card
                          cardImage={currentActionsCard}
                          href={'/mtss/current-actions'}
                          cardName={'Current Actions'}
                          cardIcon={currentActionsIcon}
                        />
                      )}

                      {(user?.groups?.includes('mtss_member') ||
                        user?.groups?.includes('mtss_admin')) && (
                        <Card
                          cardImage={watchlistCard}
                          href={'/mtss/watchlist'}
                          cardName={'Watchlist'}
                          cardIcon={watchlistIcon}
                        />
                      )}

                      {(user?.groups?.includes('mtss_member') ||
                        user?.groups?.includes('mtss_admin') ||
                        user?.groups?.includes('district_admin')) && (
                        <Card
                          cardImage={compassCard}
                          href={'/mtss/school'}
                          cardName={'Compass'}
                          cardIcon={compassIcon}
                        />
                      )}

                      {user?.groups?.includes('mtss_admin') && (
                        <Card
                          cardImage={mtssManagementCard}
                          href={'/mtss-management'}
                          cardName={'MTSS Management'}
                          cardIcon={manageIcon}
                        />
                      )}

                      {(user?.groups?.includes('mtss_member') ||
                        user?.groups?.includes('mtss_admin') ||
                        user?.groups?.includes('district_admin')) && (
                        <>
                          {schools?.map((school, itemIdx) => (
                            <CardSchool
                              key={itemIdx}
                              cardImage={schoolCard}
                              href={school?.id}
                              cardName={school?.name}
                              cardIcon={schoolIcon}
                            />
                          ))}
                        </>
                      )}
                    </>
                  }
                />
              </div>
            </div>
          }
          heading={'Lighthouse'}
          mtssCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
