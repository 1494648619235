import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

const CustomTooltip = ({ active, payload, label }) => {
  const term = payload[0]?.payload?.term;
  if (active && payload && payload.length) {
    return (
      <div className='rounded-medium bg-white p-4 text-lg font-medium shadow-md'>
        <p className='text-brandText'>{`Year: ${label}`}</p>
        {term && <p className='text-brandText'>{`Term: ${term}`}</p>}
        <p className='text-brandText'>{`Pass Rate: ${payload[0].value}%`}</p>
      </div>
    );
  }
  return null;
};

export default function PassRateTrendChart({
  data,
  loading,
  selectorOne,
  selectorTwo,
  customNoDataMessage = 'No Records',
  customMessageClasses = 'text-4xl text-gray-200',
}) {
  const [uniqueDates, setUniqueDates] = useState([]);

  useEffect(() => {
    const data2 = data;
    setUniqueDates(Array.from(new Set(data2.map((item) => item[selectorOne]))));
  }, [data, selectorOne]);

  return (
    <div className='flex h-96 w-full flex-row text-brandText'>
      <div className='flex h-full w-full flex-col items-center justify-center'>
        {loading ? (
          <div className='flex h-full w-full items-center justify-center'>
            <Oval
              height={80}
              width={80}
              color='#0CB2DA'
              wrapperStyle={{}}
              wrapperClass=''
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor='#e5e7eb'
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : data?.length > 0 ? (
          <div className='mx-auto flex h-full w-full items-center justify-center font-bold text-brandText'>
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart
                data={data}
                margin={{ top: 5, right: 20, left: 0, bottom: 5 }}
                padding={{ left: 30 }}
              >
                <Tooltip content={<CustomTooltip />} />
                <XAxis
                  dataKey={selectorOne}
                  axisLine={false}
                  tickLine={false}
                  tickMargin={10}
                  stroke='#636363'
                  fontWeight={600}
                  fontSize={13}
                  ticks={uniqueDates}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickMargin={10}
                  stroke='#636363'
                  fontWeight={600}
                  fontSize={13}
                />
                <Line
                  type='linear'
                  dataKey={selectorTwo}
                  stroke='#11CCEA'
                  strokeWidth={3}
                  isAnimationActive={false}
                />
                <path
                  d={`M${59},${385 - 36} h ${1000 - 200}`}
                  stroke='#11CCEA'
                  strokeWidth={3}
                />
                <path
                  d={`M${60},${350} v ${-347}`}
                  stroke='#11CCEA'
                  strokeWidth={3}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div
            className={`${customMessageClasses} flex h-full w-full flex-col items-center justify-center pb-5 font-normal`}
          >
            <div>{customNoDataMessage}</div>
          </div>
        )}
      </div>
    </div>
  );
}
