import React, { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

export default function HorizontalBarChart({
  starBarData,
  loadingStarBarData,
  iarProficiencyData,
  loadingIarProficiencyData,
}) {
  const [combinedData, setCombinedData] = useState([]);
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    const tempData = starBarData.map((starItem) => {
      const iarItem = iarProficiencyData.find(
        (iar) =>
          String(iar.grade_level_iar) === String(starItem.grade_level_star),
      );

      return {
        ...starItem,
        iarProficiency: iarItem ? iarItem.proficiency : null,
      };
    });

    // Remove items where grade is 'NA'
    const filteredData = tempData.filter(
      (item) => String(item.grade_level_star) !== 'NA',
    );

    const sortOrder = ['8', '7', '6', '5', '4', '3', '2', '1', 'K'];
    filteredData.sort(
      (a, b) =>
        sortOrder.indexOf(String(a.grade_level_star)) -
        sortOrder.indexOf(String(b.grade_level_star)),
    );

    setCombinedData(filteredData);
    setKey(Math.random());
  }, [starBarData, iarProficiencyData]);

  function renderCustomizedLabel(props) {
    const { x, y, width, height, value } = props;
    const iarValue = combinedData[props.index].iarProficiency;

    return (
      <text
        x={x + width + 10}
        y={y + height / 2}
        fill='#636363'
        textAnchor='start'
        fontSize={16}
        fontWeight={600}
        dominantBaseline='middle'
      >
        {value} / {iarValue !== null ? iarValue + '%' : 'N/A'}
      </text>
    );
  }

  return (
    <div className='flex w-full flex-col items-center'>
      {loadingStarBarData ? (
        <div className='flex h-[500px] w-full items-center justify-center'>
          <Oval
            height={80}
            width={80}
            color='#0CB2DA'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor='#e5e7eb'
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div style={{ width: '100%', height: 500 }}>
          <ResponsiveContainer key={key}>
            <BarChart
              layout='vertical'
              data={combinedData}
              margin={{ top: 5, right: 85, left: 0, bottom: 15 }}
            >
              <defs>
                <linearGradient id='colorUv2' x1='0' y1='0' x2='1' y2='0'>
                  <stop offset='0%' stopColor='#11CCEA' stopOpacity={1} />
                  <stop offset='100%' stopColor='#e9fbfd' stopOpacity={1} />
                </linearGradient>
              </defs>
              <XAxis type='number' hide={true} />
              <YAxis
                dataKey='grade_level_star'
                type='category'
                tickLine={false}
                axisLine={false}
                stroke='#636363'
                fontWeight={600}
                fontSize={18}
                tickMargin={10}
              />
              <Bar
                dataKey='score_avg'
                fill='#11CCEA'
                radius={[5, 5, 5, 5]}
                stroke='#636363'
                strokeWidth={1}
              >
                <LabelList
                  dataKey='score_avg'
                  content={renderCustomizedLabel}
                  position='right'
                />
              </Bar>
              <path
                d={`M${0},${498} h ${1000}`}
                stroke='#636363'
                strokeWidth={3}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}
