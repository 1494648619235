import React from 'react';
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

export default function ValueAddedChart({ data, loading }) {
  if (loading || !data.all_scores || !data.by_subject) {
    return (
      <div className='flex h-[225px] flex-col items-center justify-center'>
        <Oval
          height={80}
          width={80}
          color='#0CB2DA'
          wrapperStyle={{}}
          wrapperClass=''
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor='#e5e7eb'
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    );
  }

  const all_scores = data.all_scores;
  const by_subject = data.by_subject;

  const chartData = [
    {
      name: 'Reading',
      value:
        by_subject.find((item) => item.subject === 'Reading')?.score_avg || 0,
      fill: '#636363',
    },
    {
      name: 'Math',
      value: by_subject.find((item) => item.subject === 'Math')?.score_avg || 0,
      fill: '#94a3b8',
    },
    {
      name: 'Total',
      value: all_scores[0]?.score_avg || 0,
      fill: 'white',
    },
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload;

      return (
        <div className='rounded-md bg-white p-4 text-lg font-medium shadow-md'>
          <p className='text-brandText'>{`Subject: ${name}`}</p>
          <p className='text-brandText'>{`Proficiency: ${value}`}</p>
        </div>
      );
    }

    return null;
  };

  const xTicks = [-0.3, 0.0, 0.3];

  return (
    <div className='flex h-full w-full'>
      <ResponsiveContainer width='100%' height={250}>
        <ScatterChart
          margin={{
            top: 0,
            right: 5,
            bottom: 20,
            left: 22,
          }}
        >
          <defs>
            <linearGradient
              id='colorGradient'
              x1='0%'
              y1='0%'
              x2='100%'
              y2='0%'
            >
              <stop offset='0%' stopColor='#fde047' />
              <stop offset='33%' stopColor='#fde047' />
              <stop offset='33%' stopColor='#a3e635' />
              <stop offset='67%' stopColor='#a3e635' />
              <stop offset='67%' stopColor='#11CCEA' />
              <stop offset='100%' stopColor='#11CCEA' />
            </linearGradient>
          </defs>

          {xTicks.map((tick, index) => (
            <ReferenceLine
              key={index}
              x={tick}
              stroke='#f3f4f6'
              strokeWidth={2}
              strokeDasharray='3 3'
            />
          ))}

          <Tooltip content={<CustomTooltip />} />

          <CartesianGrid
            fill='url(#colorGradient)'
            vertical={false}
            horizontal
            stroke='#f3f4f6'
            strokeWidth={2}
            horizontalPoints={[33, 100, 167]}
          />
          <XAxis
            type='number'
            dataKey='value'
            domain={[-0.5, 0.5]}
            ticks={[-0.3, 0.0, 0.3]}
            tickLine={false}
            stroke='#636363'
            fontWeight={600}
            fontSize={15}
            tickMargin={10}
          />
          <YAxis
            type='category'
            dataKey='name'
            domain={['Reading', 'Math', 'Total']}
            ticks={['Reading', 'Math', 'Total']}
            axisLine={true}
            tickLine={false}
            stroke='#636363'
            fontWeight={600}
            fontSize={13}
            tickMargin={3}
          />
          <ZAxis range={[250, 251]} />
          <Scatter data={chartData}>
            <Scatter name='Reading' fill='red' />
            <Scatter name='Math' fill='green' />
            <Scatter name='Total' fill='blue' />
          </Scatter>
          <path
            d={`M${80},${250 - 50} h ${1000 - 22}`}
            stroke='#11CCEA'
            strokeWidth={3}
          />
          <path
            d={`M${81},${250 - 49} v ${-(250 - 20)}`}
            stroke='#11CCEA'
            strokeWidth={3}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
}
