import React, { useState, useEffect } from 'react';
import {
  RadialBarChart,
  PolarAngleAxis,
  RadialBar,
  ResponsiveContainer,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

export default function PassPercentChart({ title, data, loading, selector }) {
  const [resizeKey, setResizeKey] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      // Check if the window's width has changed
      if (window.innerWidth !== windowWidth) {
        setWindowWidth(window.innerWidth);
        setResizeKey((prevKey) => prevKey + 1);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]);

  return (
    <div className='flex h-[225px] w-full flex-col justify-start'>
      {!loading && data?.length > 0 ? (
        <div className='mx-auto flex h-full w-full items-center justify-center'>
          <div className='relative mx-auto flex h-full w-full items-center justify-center'>
            <div className='text-text text-semibold absolute top-0 left-0 z-10 flex h-full w-full items-center justify-center text-xl font-bold text-brandText lg:text-base xl:text-xl'>
              {Math.floor(data[0][selector])}%
            </div>
            <ResponsiveContainer key={resizeKey} width='100%' height='100%'>
              <RadialBarChart
                innerRadius='75%'
                outerRadius='140%'
                barSize={80}
                data={data}
                startAngle={90}
                endAngle={-270}
              >
                <PolarAngleAxis
                  type='number'
                  domain={[0, 100]}
                  angleAxisId={0}
                  tick={false}
                />
                <RadialBar
                  background={{ fill: '#e5e7eb' }}
                  clockWise
                  dataKey={selector}
                  cornerRadius={3}
                  fill='#11CCEA'
                />
              </RadialBarChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className='flex h-full w-full flex-col items-center justify-center pb-5 font-normal'>
          {loading ? (
            <div>
              <Oval
                height={80}
                width={80}
                color='#0CB2DA'
                wrapperStyle={{}}
                wrapperClass=''
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor='#e5e7eb'
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            </div>
          ) : (
            <div className='flex h-full items-center justify-center pt-5 text-center text-3xl font-normal text-gray-200'>
              No Proficiency Data
            </div>
          )}
        </div>
      )}
      <div>
        <h3 className='mt-5 text-center text-lg font-medium text-brandPrimary sm:text-xl'>
          {title}
        </h3>
      </div>
    </div>
  );
}
