import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

export default function ScatterChartComponent({ data, loading }) {
  // Find the maximum value for the X and Y axes
  const minX = Math.min(
    ...data
      .map((item) => item.fall)
      .filter((x) => x !== null && x !== undefined),
  );
  const maxX = Math.max(...data.map((item) => item.fall));
  const minY = Math.min(
    ...data
      .map((item) => item.spring)
      .filter((x) => x !== null && x !== undefined),
  );
  const maxY = Math.max(...data.map((item) => item.spring));

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { spring, fall } = payload[0].payload; // De-structuring to get name and value

      return (
        <div className='rounded-md bg-white p-4 text-lg font-medium shadow-md'>
          <p className='text-brandText'>{`Spring Score: ${spring}`}</p>
          <p className='text-brandText'>{`Fall Score: ${fall}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className='h-[460px] w-full'>
      {loading ? (
        <div className='flex h-full w-full items-center justify-center'>
          <Oval
            height={80}
            width={80}
            color='#0CB2DA'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor='#e5e7eb'
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          {data?.length >= 1 ? (
            <ResponsiveContainer width='100%' height={460}>
              <ScatterChart
                width={500}
                height={500}
                margin={{
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: -25,
                }}
              >
                <XAxis
                  type='number'
                  dataKey='fall'
                  name='Fall Score'
                  domain={[minX - 50, maxX + 50]}
                  tick={false}
                  tickLine={false}
                  axisLine={true}
                >
                  <Label
                    value='Fall'
                    offset={0}
                    position='insideBottomRight'
                    stroke='#636363'
                    fontWeight={600}
                    fontSize={15}
                  />
                </XAxis>
                <YAxis
                  type='number'
                  dataKey='spring'
                  name='spring Score'
                  domain={[minY - 50, maxY + 50]}
                  tickLine={false}
                  tick={false}
                  axisLine={true}
                >
                  <Label
                    value='Spring'
                    offset={32}
                    position='insideTop'
                    stroke='#636363'
                    fontWeight={600}
                    fontSize={15}
                    angle={-90}
                  />
                </YAxis>
                <Tooltip content={CustomTooltip} />
                {/* <ZAxis range={[200, 201]} /> */}
                <Scatter data={data} fill='#11CCEA' />
                {/* x axis line */}
                <path
                  d={`M${35},${480 - 50} h ${1000 - 42}`}
                  stroke='#11CCEA'
                  strokeWidth={3}
                />
                {/* y axis line */}
                <path
                  d={`M${35},${480 - 49} v ${-(1050 - 20)}`}
                  stroke='#11CCEA'
                  strokeWidth={3}
                />
              </ScatterChart>
            </ResponsiveContainer>
          ) : (
            <div className='flex h-full w-full items-center justify-center text-4xl text-gray-200'>
              No Records
            </div>
          )}
        </>
      )}
    </div>
  );
}
