import SidebarLayout from '../../components/SidebarLayout';
import ConcernsUpdateForm from '../../components/sections/MTSS/ConcernsUpdateForm';
import { useParams } from 'react-router-dom';
import AccessWrapper from '../../components/AccessWrapper';
import AuthContext from '../../context/AuthContext';
import { useContext, useEffect } from 'react';

export default function ConcernsUpdate() {
  const params = useParams();
  const id = params?.id;
  const { postPageView, baseUrl, user } = useContext(AuthContext);

  useEffect(() => {
    postPageView(baseUrl, user.role, 'form/update-concern');
  }, []);
  return (
    <AccessWrapper allowedGroups={['mtss_admin']}>
      <div>
        <SidebarLayout
          propComponents={<ConcernsUpdateForm params={id} />}
          heading={'Update Concern'}
        />
      </div>
    </AccessWrapper>
  );
}
