import StudentSidebarLayout from '../../components/StudentSidebarLayout';
import StudentPageTopper from '../../components/StudentPageTopper';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import BehavioralWellnessCard from '../../components/student_components/BehavioralWellnessCard';
import BehavioralRecentHistory from '../../components/student_components/BehavioralRecentHistory';
import AccessWrapper from '../../components/AccessWrapper';

export default function Behavioral() {
  const { authTokens, postPageView, baseUrl, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const params = useParams();
  const id = params?.studentId;
  const [loadingCard, setLoadingCard] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);

  /* state variable to hold selected student */
  const [student, setStudent] = useState();
  const [studentCounts, setStudentCounts] = useState();
  const [studentRecentDiscipline, setStudentRecentDiscipline] = useState();
  const [studentAllDiscipline, setStudentAllDiscipline] = useState();
  const [studentDisciplineDisplay, setStudentDisciplineDisplay] = useState();
  const [showingRecentDiscipline, setShowingRecentDiscipline] = useState(true);
  const [disciplineTimeframe, setDisciplineTimeframe] =
    useState('Recent History');
  const [buttonText, setButtonText] = useState('Show All');

  useEffect(() => {
    getStudent();
    getStudentRecentDiscipline();
    getStudentAllDiscipline();
    getStudentCounts();
    postPageView(baseUrl, user.role, 'student/behavioral');
  }, []);

  let getStudent = async () => {
    let response = await fetch(`${baseUrl}/sis/students/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudent(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  const handleSubmit = () => {
    if (showingRecentDiscipline) {
      setStudentDisciplineDisplay(studentAllDiscipline);
      setDisciplineTimeframe('All History');
      setShowingRecentDiscipline(false);
      setButtonText('Show Recent');
    } else {
      setStudentDisciplineDisplay(studentRecentDiscipline);
      setDisciplineTimeframe('Recent History');
      setShowingRecentDiscipline(true);
      setButtonText('Show All');
    }
  };

  let getStudentRecentDiscipline = async () => {
    setLoadingTable(true);
    let response = await fetch(
      `${baseUrl}/sis/discipline/?student=${id}&recent=true&`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setStudentRecentDiscipline(data.results);
      setLoadingTable(false);
    } else if (response.status === 403) {
      setLoadingTable(false);
      navigate('/unauthorized');
    }
  };

  let getStudentAllDiscipline = async () => {
    let response = await fetch(`${baseUrl}/sis/discipline/?student=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudentAllDiscipline(data.results);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getStudentCounts = async () => {
    setLoadingCard(true);
    let response = await fetch(`${baseUrl}/sis/discipline-summary/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudentCounts(data);
      setLoadingCard(false);
    } else if (response.status === 403) {
      setLoadingCard(false);
      navigate('/unauthorized');
    }
  };

  /* let getStudentCounts = async () => {
    let response = await fetch(
      `${baseUrl}/mtss/mtss-counts/${id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setStudentCounts(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  }; */

  return (
    <AccessWrapper
      allowedGroups={[
        'teacher',
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <StudentSidebarLayout
          propComponents={
            <div>
              <StudentPageTopper student={student} />
              <div className='relative -top-14 mx-auto'>
                <div className='mx-auto px-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl '>
                    <h1 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:mb-10 sm:text-3xl'>
                      Behavioral Wellness
                    </h1>
                    <div className='mx-auto flex flex-row justify-between space-x-8 '>
                      <BehavioralWellnessCard
                        loading={loadingCard}
                        studentCounts={studentCounts}
                      />
                    </div>
                  </div>
                </div>
                <div className='mx-auto mt-10 bg-gray-100 px-4 pt-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl'>
                    <h2 className='mt-3 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                      {disciplineTimeframe}
                    </h2>
                  </div>
                  <div className='mx-auto mt-5 flex w-full max-w-7xl flex-row sm:mt-7'>
                    <BehavioralRecentHistory
                      loading={loadingTable}
                      data={studentRecentDiscipline}
                    />
                  </div>
                </div>
                <div className='mx-auto bg-gray-100 px-4 pt-5 pb-12 sm:px-6 sm:pb-16 lg:px-8 lg:pb-20'>
                  <div className='mx-auto flex w-full max-w-7xl flex-row justify-end'>
                    <button
                      onClick={handleSubmit}
                      className='rounded-md border border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
                    >
                      {buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
          heading={'Student Report'}
          id={id}
          heading2={'Behavioral'}
          behavioralCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
