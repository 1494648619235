import LoadingSpinner from '../../../../elements/LoadingSpinner';
export default function StatCard({ loading, lineOne, lineTwo, lineThree }) {
  return (
    <div className='flex h-36 w-full flex-col items-center justify-center space-y-2 rounded-2xl border border-gray-100 bg-white p-8 shadow-md sm:h-40 sm:w-[calc(50%-12px)] lg:w-[calc(50%-16px)] xl:w-[calc(33%-21px)]'>
      {loading ? (
        <div>
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {lineOne && (
            <p className='text-center text-3xl font-bold text-brandPrimary sm:text-4xl'>
              {lineOne}
            </p>
          )}
          {lineTwo && (
            <p className='text-center text-lg font-medium text-brandText sm:text-xl'>
              {lineTwo}
            </p>
          )}
          {lineThree && (
            <p className='text-center text-sm font-semibold text-brandPrimary sm:text-base'>
              {lineThree}
            </p>
          )}
        </>
      )}
    </div>
  );
}

StatCard.defaultProps = {
  loading: false,
};
