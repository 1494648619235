import { BarChart, Bar, XAxis, LabelList, ResponsiveContainer } from 'recharts';
import { Oval } from 'react-loader-spinner';
import { useState } from 'react';

export default function VerticalBarChart({ data, loading }) {
  const [error, setError] = useState(null);

  const processData = () => {
    try {
      const totalScoreCount = data.reduce(
        (total, item) => total + item.score_count,
        0,
      );
      const exactPercentages = data.map(
        (item) => (item.score_count / totalScoreCount) * 100,
      );
      const roundedPercentages = exactPercentages.map((percentage) =>
        Math.round(percentage),
      );
      const difference =
        100 -
        roundedPercentages.reduce((total, percentage) => total + percentage, 0);
      roundedPercentages[0] += difference;
      return roundedPercentages;
    } catch (err) {
      setError('An error occurred while processing the data.');
      console.error(error);
      return null;
    }
  };

  const labelMap = {
    'Did Not Yet Meet Expectations': 'Below',
    'Partially Met Expectations': 'Partial',
    'Approached Expectations': 'Approaching',
    'Met Expectations': 'Meets',
    'Exceeded Expectations': 'Exceeds',
  };

  const roundedPercentages = processData();

  function renderCustomizedLabel(props) {
    const { x, y, width, index } = props;
    return (
      <text
        x={x + width / 2}
        y={y}
        fill='#636363'
        textAnchor='middle'
        fontSize={16}
        fontWeight={600}
        dy={-10}
      >
        {roundedPercentages[index]}%
      </text>
    );
  }

  // Define the custom order for labels
  const customLabelOrder = [
    'Did Not Yet Meet Expectations',
    'Partially Met Expectations',
    'Approached Expectations',
    'Met Expectations',
    'Exceeded Expectations',
  ];

  // Sort the data and labels based on the custom order
  const sortedData = [...data].sort(
    (a, b) =>
      customLabelOrder.indexOf(a.state_benchmark_cat) -
      customLabelOrder.indexOf(b.state_benchmark_cat),
  );

  return (
    <div className='flex w-full flex-col items-center'>
      {loading ? (
        <div className='flex h-[225px] w-full items-center justify-center'>
          <Oval
            height={80}
            width={80}
            color='#0CB2DA'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor='#e5e7eb'
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          {sortedData?.length >= 1 ? (
            <div style={{ width: '100%', height: 225 }}>
              <ResponsiveContainer>
                <BarChart
                  data={sortedData}
                  margin={{ top: 27, right: 0, left: 5, bottom: 40 }}
                >
                  <defs>
                    <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                      <stop offset='0%' stopColor='#e9fbfd' stopOpacity={1} />
                      <stop offset='100%' stopColor='#11CCEA' stopOpacity={1} />
                    </linearGradient>
                  </defs>
                  <XAxis
                    dataKey='state_benchmark_cat'
                    tickFormatter={(value) => labelMap[value] || value}
                    axisLine={false}
                    tickLine={false}
                    stroke='#636363'
                    fontWeight={600}
                    fontSize={10}
                    angle={-35}
                    tickMargin={0}
                    textAnchor='end'
                  />
                  <Bar
                    dataKey='score_count'
                    fill='#11CCEA'
                    radius={[5, 5, 0, 0]}
                    stroke='#636363'
                    strokeWidth={1}
                    isAnimationActive={false}
                  >
                    <LabelList
                      dataKey='score_count'
                      content={renderCustomizedLabel}
                    />
                  </Bar>
                  <path
                    d={`M${0},${186 - 30} h ${1000}`}
                    stroke='#636363'
                    strokeWidth={3}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className='flex h-[225px] items-center justify-center text-4xl font-normal text-gray-200'>
              No Records
            </div>
          )}
        </>
      )}
      <div>
        <h2 className='mt-5 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
          Star
        </h2>
      </div>
    </div>
  );
}
