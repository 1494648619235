import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import ProtectedRoutes from './utils/ProtectedRoutes';
import { AuthProvider } from './context/AuthContext';
import Login from './pages/Login';
import LoginForRedirect from './pages/LoginForRedirect';
import Dashboard from './pages/Dashboard';
import NoteReferralForm from './pages/forms/NoteReferral';
import NoteReferralUpdateForm from './pages/forms/NoteReferralUpdate';
import AddActionForm from './pages/forms/AddAction';
import NotFound from './pages/NotFound';
/* import CategoriesForm from './pages/forms/Categories'; */
import SubjectsForm from './pages/forms/Subjects';
import ConcernsForm from './pages/forms/Concerns';
import OutcomesForm from './pages/forms/Outcomes';
import ChecksForm from './pages/forms/Checks';
import ChecksUpdateForm from './pages/forms/ChecksUpdate';
import MtssMemberManagementUpdateForm from './pages/mtss_management/MtssMemberManagementUpdate';
import TreatmentForm from './pages/forms/Treatments';
import MtssManagement from './pages/MtssManagement';
/* import Categories from './pages/mtss_management/Categories'; */
import Subjects from './pages/mtss_management/Subjects';
import Concerns from './pages/mtss_management/Concerns';
import Treatments from './pages/mtss_management/Treatments';
import Outcomes from './pages/mtss_management/Outcomes';
import AtAGlance from './pages/students/AtAGlance';
import NotesAndReferrals from './pages/NotesAndReferrals';
import ActionsAndChecks from './pages/ActionsAndChecks';
import TreatmentsUpdateForm from './pages/forms/TreatmentsUpdate';
import SubjectsUpdateForm from './pages/forms/SubjectsUpdate';
/* import CategoriesUpdateForm from './pages/forms/CategoriesUpdate'; */
import ConcernsUpdateForm from './pages/forms/ConcernsUpdate';
import AssessmentsUpdateForm from './pages/forms/AssessmentsUpdate';
import OutcomeUpdateForm from './pages/forms/OutcomesUpdate';
import OpenReferrals from './pages/OpenReferrals';
import Referral from './pages/Referral';
import StaffManagement from './pages/mtss_management/StaffManagement';
import CurrentActions from './pages/CurrentActions';
import ActionDetails from './pages/ActionDetails';
import Watchlist from './pages/Watchlist';
import Behavioral from './pages/students/Behavioral';
import Actions from './pages/students/Actions';
import Grades from './pages/students/Grades';
import Notes from './pages/students/Notes';
import Attendance from './pages/students/Attendance';
import Reading from './pages/students/Reading';
import Math from './pages/students/Math';
import StudentsISupervise from './pages/StudentsISupervise';
import Students from './pages/Students';
import MtssListsStudents from './pages/MtssListsStudents';
import CaregiverReports from './pages/CaregiverReports';
/* import StudentsWithActions from './pages/StudentsWithActions'; */
import Lighthouse from './pages/Lighthouse';
import CaregiverActions from './pages/CaregiverActions';
import Standards from './pages/Standards';
import PasswordReset from './pages/PasswordReset';
import PasswordUpdate from './pages/PasswordUpdate';
import TeacherList from './pages/TeacherList';
import CourseList from './pages/CourseList';
import Unauthorized from './components/Unauthorized';
import Assessments from './pages/mtss_management/Assessments';
import AssessmentsForm from './pages/forms/Assessments';
import TeacherDetails from './pages/TeacherDetails';
import CourseDetails from './pages/CourseDetails';
import Compass from './pages/Compass';
import SchoolList from './pages/SchoolList';
import SchoolDetails from './pages/SchoolDetails';
import YearlyPerformanceSummary from './pages/YearlyPerformanceSummary';
import UsageReport from './pages/UsageReport';

export default function App() {
  return (
    <Router>
      <ScrollToTop>
        <AuthProvider>
          <Routes>
            <Route path={'/login'} element={<Login />} />
            <Route path={'/login/:id'} element={<LoginForRedirect />} />
            <Route path={'/password-reset'} element={<PasswordReset />} />
            <Route
              path='/accounts/reset/:uidb64/:token'
              element={<PasswordUpdate />}
            />
            <Route path={'/unauthorized'} element={<Unauthorized />} />

            {/* protected routes */}
            <Route element={<ProtectedRoutes />}>
              <Route path={'/'} element={<Dashboard />} exact />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/mtss' element={<Lighthouse />} />
              <Route path='/standards' element={<Standards />} />
              <Route path='/mtss-management' element={<MtssManagement />} />
              <Route
                path='/notes-and-referrals'
                element={<NotesAndReferrals />}
              />
              <Route
                path='/actions-and-checks'
                element={<ActionsAndChecks />}
              />
              <Route path='/students' element={<Students />} />
              {/* <Route
                path='/students/students-with-actions'
                element={<StudentsWithActions />}
              /> */}
              <Route
                path='/mtss/current-actions'
                element={<CurrentActions />}
              />
              <Route
                path='/mtss/caregiver-actions'
                element={<CaregiverActions />}
              />
              <Route path='/mtss/watchlist' element={<Watchlist />} />
              <Route path='/mtss/referrals' element={<OpenReferrals />} />
              <Route path='/mtss/school' element={<Compass />} />
              {/* <Route
                path='/mtss-management/categories'
                element={<Categories />}
              /> */}
              <Route path='/mtss-management/subjects' element={<Subjects />} />
              <Route path='/mtss-management/concerns' element={<Concerns />} />
              <Route
                path='/mtss-management/treatments'
                element={<Treatments />}
              />
              <Route path='/mtss-management/outcomes' element={<Outcomes />} />
              <Route
                path='/mtss-management/assessments'
                element={<Assessments />}
              />
              <Route
                path='/mtss-management/staff-management'
                element={<StaffManagement />}
              />
              <Route
                path='/mtss/note-referral'
                element={<NoteReferralForm />}
              />
              <Route path='/mtss/add-action' element={<AddActionForm />} />
              {/* <Route path='/mtss/add-category' element={<CategoriesForm />} /> */}
              <Route path='/mtss/add-subject' element={<SubjectsForm />} />
              <Route path='/mtss/add-concern' element={<ConcernsForm />} />
              <Route path='/mtss/add-outcome' element={<OutcomesForm />} />
              <Route
                path='/mtss/add-assessment'
                element={<AssessmentsForm />}
              />
              <Route path='/mtss/add-check' element={<ChecksForm />} />
              <Route
                path='/mtss/check-update/:id'
                element={<ChecksUpdateForm />}
              />
              <Route
                path='/mtss-management/update-user'
                element={<MtssMemberManagementUpdateForm />}
              />
              <Route path='/mtss/add-treatment' element={<TreatmentForm />} />
              <Route
                path='/mtss/students-i-supervise'
                element={<StudentsISupervise />}
              />
              <Route
                path='/mtss/caregiver-reports'
                element={<CaregiverReports />}
              />
              <Route path='/mtss/teacher-list' element={<TeacherList />} />
              <Route path='/mtss/course-list' element={<CourseList />} />
              <Route
                path='/mtss/teacher-details/:teacherId'
                element={<TeacherDetails />}
              />
              <Route
                path='/mtss/course-details/:courseId'
                element={<CourseDetails />}
              />
              <Route
                path='/mtss/yearly-performance-summary'
                element={<YearlyPerformanceSummary />}
              />
              <Route path='/mtss/usage-report' element={<UsageReport />} />
              <Route path='/mtss/school-list' element={<SchoolList />} />
              <Route
                path='/mtss/school-details/:schoolId'
                element={<SchoolDetails />}
              />
              <Route path='/mtss/action-details' element={<ActionDetails />} />
              {/* dynamic routes */
              /* Student Routes */}
              <Route
                path='students/at-a-glance/:studentId'
                element={<AtAGlance />}
              />
              <Route path='students/grades/:studentId' element={<Grades />} />
              <Route
                path='students/attendance/:studentId'
                element={<Attendance />}
              />
              <Route
                path='students/behavioral/:studentId'
                element={<Behavioral />}
              />
              <Route path='students/notes/:studentId' element={<Notes />} />
              <Route path='students/actions/:studentId' element={<Actions />} />
              <Route path='students/reading/:studentId' element={<Reading />} />
              <Route path='students/math/:studentId' element={<Math />} />
              <Route
                path='/mtss/note-referral-update/:id'
                element={<NoteReferralUpdateForm />}
              />
              <Route
                path='/mtss/treatment-update/:id'
                element={<TreatmentsUpdateForm />}
              />
              <Route
                path='/mtss/subject-update/:id'
                element={<SubjectsUpdateForm />}
              />
              {/* <Route
                path='/mtss/category-update/:id'
                element={<CategoriesUpdateForm />}
              /> */}
              <Route
                path='/mtss/concern-update/:id'
                element={<ConcernsUpdateForm />}
              />
              <Route
                path='/mtss/assessment-update/:id'
                element={<AssessmentsUpdateForm />}
              />
              <Route
                path='/mtss/outcome-update/:id'
                element={<OutcomeUpdateForm />}
              />
              <Route path='/mtss/referrals/:id' element={<Referral />} />
              <Route
                path='/mtss/mtss-lists/students/:id'
                element={<MtssListsStudents />}
              />
            </Route>
            <Route path={'*'} element={<NotFound />} />
          </Routes>
        </AuthProvider>
      </ScrollToTop>
    </Router>
  );
}
