import { Oval } from 'react-loader-spinner';

export default function LoadingSpinner({ height, width }) {
  return (
    <div className='flex h-full w-full flex-col items-center justify-center'>
      <Oval
        height={height}
        width={width}
        color='#0CB2DA'
        wrapperStyle={{}}
        wrapperClass=''
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor='#e5e7eb'
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
}

LoadingSpinner.defaultProps = {
  height: 80,
  width: 80,
};
