import SidebarLayout from '../../components/SidebarLayout';
import ConcernsForm from '../../components/sections/MTSS/ConcernsForm';
import AccessWrapper from '../../components/AccessWrapper';
import AuthContext from '../../context/AuthContext';
import React, { useEffect, useContext } from 'react';

export default function Categories() {
  const { postPageView, baseUrl, user } = useContext(AuthContext);

  useEffect(() => {
    postPageView(baseUrl, user.role, 'form/add-concern');
  }, []);
  return (
    <AccessWrapper allowedGroups={['mtss_admin']}>
      <div>
        <SidebarLayout
          propComponents={<ConcernsForm />}
          heading={'Add Concern'}
        />
      </div>
    </AccessWrapper>
  );
}
