import StudentSidebarLayout from '../../components/StudentSidebarLayout';
import StudentPageTopper from '../../components/StudentPageTopper';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useParams } from 'react-router-dom';
import GradesTable from '../../components/student_components/GradesTable';
import AAGCoursesTable from '../../components/student_components/AAGCoursesTable';
import lexileIcon from '../../images/circle_icons/lexile.svg';
import { getHistoricalGradeSummary } from '../../common/api/student';
import { Oval } from 'react-loader-spinner';
import AccessWrapper from '../../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';
import CustomBarChart from '../../components/CustomBarChart';

export default function Grades() {
  const { authTokens, logoutUser, postPageView, baseUrl, user } =
    useContext(AuthContext);
  const params = useParams();
  const id = params?.studentId;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  /* state variable to hold selected student */
  const [student, setStudent] = useState();
  const [studentGrades, setStudentGrades] = useState(undefined);
  const [studentSchedule, setStudentSchedule] = useState(undefined);
  const [studentGradeSummary, setStudentGradeSummary] = useState(undefined);
  const [loadingHistoricalGrades, setLoadingHistoricalGrades] = useState(false);

  useEffect(() => {
    getStudent();
    getStudentSchedule();
    getStudentGrades();
    postPageView(baseUrl, user.role, 'student/grades');
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingHistoricalGrades(true);
      try {
        const responseData = await getHistoricalGradeSummary(
          authTokens,
          logoutUser,
          id,
          baseUrl,
        );
        const data = responseData?.data;
        setStudentGradeSummary(
          Array.isArray(data) && data.length ? data : data,
        );
        setLoadingHistoricalGrades(false);
      } catch (error) {
        // already caught
        setLoadingHistoricalGrades(false);
      }
    })();
  }, [id]);

  let getStudent = async () => {
    let response = await fetch(`${baseUrl}/sis/students/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudent(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getStudentSchedule = async () => {
    let response = await fetch(`${baseUrl}/sis/stu-scheds/?student=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudentSchedule(data.results);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getStudentGrades = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/sis/grade-summary-list/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudentGrades(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'teacher',
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div className=''>
        <StudentSidebarLayout
          propComponents={
            <div>
              <StudentPageTopper student={student} />
              <div className='relative -top-14 mx-auto'>
                <section className='mx-auto px-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl '>
                    <h1 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:mb-10 sm:text-3xl'>
                      Grades
                    </h1>
                    <div className='mx-auto flex w-full flex-col justify-between md:flex-row md:space-x-6'>
                      {studentSchedule === undefined ? (
                        <div className='flex h-80 w-full flex-row items-center justify-center'>
                          <Oval
                            height={80}
                            width={80}
                            color='#0CB2DA'
                            wrapperStyle={{}}
                            wrapperClass=''
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor='#e5e7eb'
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                          />
                        </div>
                      ) : (
                        <div className='flex w-full'>
                          <AAGCoursesTable
                            studentSchedule={studentSchedule}
                            student={student}
                          />
                        </div>
                      )}
                      {!studentGradeSummary && (
                        <div className='flex h-80 w-full flex-row items-center justify-center'>
                          <Oval
                            height={80}
                            width={80}
                            color='#0CB2DA'
                            wrapperStyle={{}}
                            wrapperClass=''
                            visible={true}
                            ariaLabel='oval-loading'
                            secondaryColor='#e5e7eb'
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                          />
                        </div>
                      )}
                      <div className='mt-5 flex h-full w-full md:mt-0'>
                        <CustomBarChart
                          title={'Historical'}
                          subtitle={'Grades'}
                          icon={lexileIcon}
                          data={studentGradeSummary}
                          loading={loadingHistoricalGrades}
                          selectorOne={'grade'}
                          selectorTwo={'grade_count'}
                          customNoDataMessage={'No current grade data found'}
                          customMessageClasses={'text-xl text-gray-400 px-8'}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <div className='mx-auto mt-10 bg-gray-100 px-4 pt-4 pb-12 sm:px-6 sm:pb-16 lg:px-8 lg:pb-20'>
                  <div className='mx-auto max-w-7xl'>
                    <h2 className='mt-3 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                      Grades
                    </h2>
                  </div>
                  <div className='mx-auto mt-5 flex w-full max-w-7xl flex-row sm:mt-7'>
                    <GradesTable loading={loading} data={studentGrades} />
                  </div>
                </div>
              </div>
            </div>
          }
          heading={'Student Report'}
          id={id}
          heading2={'Grades'}
          gradesCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
