import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import CustomBarChart from '../components/CustomBarChart';
import gradeDistIcon from '../images/circle_icons/grade_dist_icon.svg';
import passPercentIcon from '../images/circle_icons/pass_percent_icon.svg';
import PassPercentChart from '../components/PassPercentChart';
import PassRateTrendChart from '../components/PassRateTrendChart';
import CourseDetailsGradeTable from '../components/CourseDetailsGradeTable';
import SchoolDetailsFilter from '../components/SchoolDetailsFilter';

export default function CourseDetails() {
  const { authTokens, postPageView, user, baseUrl } = useContext(AuthContext);
  const params = useParams();
  const id = params?.courseId;
  const [course, setCourse] = useState({});
  const [distData, setDistData] = useState([]);
  const [loadingDistData, setLoadingDistData] = useState(false);
  const [passPercentData, setPassPercentData] = useState([]);
  const [loadingPassPercentData, setLoadingPassPercentData] = useState(false);
  const [passTrendData, setPassTrendData] = useState([]);
  const [loadingPassTrendData, setLoadingPassTrendData] = useState(false);
  const [gradesByTeacher, setGradesByTeacher] = useState(null);
  const [loadingGradesByTeacher, setLoadingGradesByTeacher] = useState(false);

  // variables to hold selected filters
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [sesOptions, setSesOptions] = useState([]);
  const [selectedGenderOptions, setSelectedGenderOptions] = useState([]);
  const [selectedEthnicityOptions, setSelectedEthnicityOptions] = useState([]);
  const [selectedSesOptions, setSelectedSesOptions] = useState([]);
  const [selected504Options, setSelected504Options] = useState([]);
  const [selectedIepOptions, setSelectedIepOptions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getCourse();
    getDistributionData();
    getPassPercentData();
    getPassTrendData();
    getGradesByTeacher();
    getEthnicityOptions();
    getSesOptions();
  }, [
    selectedGenderOptions,
    selectedEthnicityOptions,
    selectedSesOptions,
    selected504Options,
    selectedIepOptions,
  ]);

  useEffect(() => {
    postPageView(baseUrl, user.role, 'school-details');
  }, []);

  let getCourse = async () => {
    try {
      let response = await fetch(`${baseUrl}/sis/courses/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });
      let data = await response.json();

      if (response.status === 200) {
        setCourse(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getDistributionData = async () => {
    try {
      setLoadingDistData(true);
      let response = await fetch(
        `${baseUrl}/sis/course-grades/${id}/?gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setDistData(data);
        setLoadingDistData(false);
      } else if (response.status === 403) {
        setLoadingDistData(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getPassPercentData = async () => {
    try {
      setLoadingPassPercentData(true);
      let response = await fetch(
        `${baseUrl}/sis/course-pass-percent/${id}/?gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setPassPercentData(data);
        setLoadingPassPercentData(false);
      } else if (response.status === 403) {
        setLoadingPassPercentData(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getPassTrendData = async () => {
    try {
      setLoadingPassTrendData(true);
      let response = await fetch(
        `${baseUrl}/sis/course-pass-trend/${id}/?gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setPassTrendData(data);
        setLoadingPassTrendData(false);
      } else if (response.status === 403) {
        setLoadingPassTrendData(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getGradesByTeacher = async () => {
    try {
      setLoadingGradesByTeacher(true);
      let response = await fetch(
        `${baseUrl}/sis/teacher-grades-for-course/?course_id=${id}&gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setGradesByTeacher(data);
        setLoadingGradesByTeacher(false);
      } else if (response.status === 403) {
        setLoadingGradesByTeacher(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getEthnicityOptions = async () => {
    try {
      let response = await fetch(`${baseUrl}/sis/fer/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });
      let data = await response.json();

      if (response.status === 200) {
        setEthnicityOptions(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getSesOptions = async () => {
    try {
      let response = await fetch(`${baseUrl}/sis/ses/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });
      let data = await response.json();

      if (response.status === 200) {
        setSesOptions(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='mt-12 pb-20 sm:mt-16 lg:mt-20'>
              <div className='mx-auto'>
                <section className='mx-auto px-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl '>
                    <h1 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:mb-10 sm:text-3xl'>
                      Grades
                    </h1>
                    <div className='mx-auto flex w-full flex-col justify-between md:flex-row md:space-x-6'>
                      <div className='flex w-full md:w-1/2'>
                        <CustomBarChart
                          title={'Current Grade'}
                          subtitle={'Distribution'}
                          icon={gradeDistIcon}
                          data={distData}
                          loading={loadingDistData}
                          selectorOne={'letter_grade'}
                          selectorTwo={'grade_count'}
                        />
                      </div>
                      <div className='mt-10 flex h-full w-full md:mt-0 md:w-1/2'>
                        <PassPercentChart
                          title={'Current Pass'}
                          subtitle={'Rate'}
                          icon={passPercentIcon}
                          data={passPercentData}
                          loading={loadingPassPercentData}
                          selector={'pass_percent'}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section className='mx-auto mt-3 px-4 pt-7 sm:px-6 lg:mt-8 lg:px-8'>
                  <div className='mx-auto max-w-7xl '>
                    <div className='mx-auto flex w-full flex-col justify-between md:space-x-6 xl:flex-row'>
                      <div className='flex w-full flex-col xl:w-1/2'>
                        <h2 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:mb-7 sm:text-3xl'>
                          Grades By Teacher
                        </h2>
                        <CourseDetailsGradeTable
                          loading={loadingGradesByTeacher}
                          data={gradesByTeacher}
                        />
                      </div>
                      <div className='mt-10 flex h-full w-full flex-col xl:mt-0 xl:w-1/2'>
                        <h2 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:mb-7 sm:text-3xl'>
                          Pass Rate Trend
                        </h2>
                        <PassRateTrendChart
                          data={passTrendData}
                          loading={loadingPassTrendData}
                          selectorOne={'end_year'}
                          selectorTwo={'pass_percent'}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          }
          altHeading={course?.number}
          altSubheading={course?.title}
          CourseDetailsCurrent={true}
          sortComponent={
            <SchoolDetailsFilter
              ethnicityOptions={ethnicityOptions}
              sesOptions={sesOptions}
              setSelectedGenderOptions={setSelectedGenderOptions}
              setSelectedEthnicityOptions={setSelectedEthnicityOptions}
              setSelectedSesOptions={setSelectedSesOptions}
              setSelected504Options={setSelected504Options}
              setSelectedIepOptions={setSelectedIepOptions}
            />
          }
        />
      </div>
    </AccessWrapper>
  );
}
