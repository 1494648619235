import React, { useEffect, useState } from 'react';
import FormSpinner from '../../formComponents/FormSpinner';
import NoRecords from '../../formComponents/NoRecords';
import { useNavigate } from 'react-router-dom';

export default function TeacherListTable({
  loading,
  data,
  allData,
  setShowPagination,
  sortCoursesByNumber,
  sortCoursesByTitle,
  sortCoursesByLevel,
  sortCoursesByDepartment,
  sortCoursesBySchool,
  sortCoursesBySectionCount,
  sortCoursesByStudentCount,
  sortCoursesByPassRate,
  lastSortedColumn,
  numberSortDirection,
  titleSortDirection,
  levelSortDirection,
  departmentSortDirection,
  schoolSortDirection,
  sectionCountSortDirection,
  studentCountSortDirection,
  passRateSortDirection,
}) {
  const [displayData, setDisplayData] = useState();
  const navigate = useNavigate();

  const handleClick = (item) => {
    navigate(`/mtss/course-details/${item?.id}`);
  };

  let courses = allData;

  const [query, setQuery] = useState('');

  let filteredCourses =
    query === ''
      ? courses
      : courses?.filter((course) => {
          const lowerCaseQuery = query.toLowerCase();
          const number = course?.number?.toLowerCase() || '';
          const title = course?.title?.toLowerCase() || '';
          const levelName = course?.level_name?.toLowerCase() || '';
          const department = course?.department?.toLowerCase() || '';
          const schoolName = course?.school_name?.toLowerCase() || '';

          return (
            number.includes(lowerCaseQuery) ||
            title.includes(lowerCaseQuery) ||
            levelName.includes(lowerCaseQuery) ||
            department.includes(lowerCaseQuery) ||
            schoolName.includes(lowerCaseQuery)
          );
        });

  useEffect(() => {
    query !== '' ? setDisplayData(filteredCourses) : setDisplayData(data);
    query !== '' ? setShowPagination(false) : setShowPagination(true);
  }, [query, courses, data]);

  const scoreCalc = (item) => {
    if (item === null) {
      return ' bg-[#f1f5f9] ';
    } else if (item === 0) {
      return ' bg-[#f1f5f9] ';
    } else if (item < 75) {
      return ' bg-[#dc2626] ';
    } else if (item < 85) {
      return ' bg-[#facc15] ';
    } else if (item < 100 || item === 100) {
      return ' bg-[#84cc16] ';
    } else {
      return ' bg-[#f1f5f9] ';
    }
  };

  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <div className='sm:items-center md:flex'></div>
      <div className='mt-12 flex flex-col items-center justify-between sm:mt-16 lg:mt-20 lg:flex-row'>
        <div className='w-full lg:max-w-sm'>
          <div className='relative mt-2'>
            <input
              className='w-full rounded-md border-2 border-gray-200 bg-white py-3 pl-3 pr-10 text-brandText  focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:text-sm'
              onChange={(event) => setQuery(event.target.value)}
              type='text'
              placeholder='Filter results'
            />
          </div>
        </div>
      </div>
      <div className='-mx-4 mt-5 overflow-x-auto overflow-y-hidden shadow-lg ring-1 ring-gray-100 sm:-mx-6 md:mx-0 md:mt-5 md:rounded-lg'>
        <table className=' min-w-full divide-y divide-gray-100'>
          <thead className='bg-brandPrimary'>
            <tr>
              <th
                scope='col'
                className='py-3.5 pl-4 pr-3 text-left text-lg font-bold text-white sm:pl-6'
              >
                <button onClick={() => sortCoursesByNumber()}>
                  Course Number{' '}
                  {lastSortedColumn === 'number' &&
                    (numberSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                <button
                  onClick={() => {
                    sortCoursesByTitle();
                  }}
                >
                  Course Title{' '}
                  {lastSortedColumn === 'title' &&
                    (titleSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                <button
                  onClick={() => {
                    sortCoursesByLevel();
                  }}
                >
                  Level{' '}
                  {lastSortedColumn === 'level' &&
                    (levelSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                <button
                  onClick={() => {
                    sortCoursesByDepartment();
                  }}
                >
                  Department{' '}
                  {lastSortedColumn === 'department' &&
                    (departmentSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                <button
                  onClick={() => {
                    sortCoursesBySchool();
                  }}
                >
                  Schools{' '}
                  {lastSortedColumn === 'school' &&
                    (schoolSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 text-left text-lg font-bold text-white lg:table-cell'
              >
                <button
                  onClick={() => {
                    sortCoursesBySectionCount();
                  }}
                >
                  Current Sections{' '}
                  {lastSortedColumn === 'sectionCount' &&
                    (sectionCountSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='hidden px-3 py-3.5 pr-4 text-left text-lg font-bold text-white sm:pr-6 lg:table-cell'
              >
                <button
                  onClick={() => {
                    sortCoursesByStudentCount();
                  }}
                >
                  Current Student Count{' '}
                  {lastSortedColumn === 'studentCount' &&
                    (studentCountSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
              <th
                scope='col'
                className='px-3 py-3.5 pr-4 text-left text-lg font-bold text-white sm:pr-6'
              >
                <button
                  onClick={() => {
                    sortCoursesByPassRate();
                  }}
                >
                  Current Pass Rate{' '}
                  {lastSortedColumn === 'passRate' &&
                    (passRateSortDirection
                      ? String.fromCharCode(8593)
                      : String.fromCharCode(8595))}
                </button>
              </th>
            </tr>
          </thead>
          <tbody className='divide-y divide-gray-100 bg-white'>
            {displayData?.map((item, itemIdx) => (
              <tr key={itemIdx}>
                <td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-normal text-brandText sm:w-auto sm:max-w-none sm:pl-6'>
                  <button
                    className='rounded-md font-medium text-brandPrimary duration-300 ease-in-out hover:text-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-2 focus:ring-brandPrimary lg:hover:scale-110'
                    onClick={() => {
                      handleClick(item);
                    }}
                  >
                    <span className='font-bold text-brandText lg:hidden'>
                      Course Number:{' '}
                    </span>
                    {item?.number}
                  </button>
                  <dl className='font-normal lg:hidden'>
                    <dt className='sr-only'>Course Title</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Course Title: </span>
                      {item?.title}
                    </dd>
                    <dt className='sr-only lg:hidden'>Level</dt>
                    <dd className='mt-1 truncate text-brandText lg:hidden'>
                      <span className='font-bold text-brandText'>
                        Level Name:{' '}
                      </span>
                      {item?.level_name}
                    </dd>
                    <dt className='sr-only'>Department</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Department: </span>
                      {item?.department}
                    </dd>
                    <dt className='sr-only'>Schools</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Schools: </span>
                      {item?.school_name}
                    </dd>
                    <dt className='sr-only'>Schools</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Schools: </span>
                      {item?.section_count}
                    </dd>
                    <dt className='sr-only'>Schools</dt>
                    <dd className='mt-1 truncate'>
                      <span className='font-bold'>Schools: </span>
                      {item?.student_count}
                    </dd>
                  </dl>
                </td>

                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.title}
                </td>

                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.level_name}
                </td>
                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.department}
                </td>
                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.school_name}
                </td>
                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.section_count}
                </td>
                <td className='hidden px-3 py-4 text-sm font-normal text-brandText lg:table-cell'>
                  {item?.student_count}
                </td>
                <td className='px-3 py-4 text-sm font-normal text-brandText'>
                  <div
                    className={`${scoreCalc(
                      item?.pass_percent,
                    )} ml-5 h-5 w-5 rounded-full`}
                  ></div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <FormSpinner loading={loading} />
        <NoRecords loading={loading} data={displayData} />
      </div>
    </div>
  );
}
