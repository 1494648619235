import React from 'react';
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import LoadingSpinner from '../../../../elements/LoadingSpinner';

export default function DailyActivityLineChart({ data, loading, title }) {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className='rounded-md bg-white p-4 text-lg font-medium shadow-md'>
          <p className='text-brandText'>Date: {data?.created}</p>
          <p className='text-brandText'>Users: {data?.Users}</p>
          <p className='text-brandText'>Clicks: {data?.Kliqs}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className='flex w-full flex-col items-center'>
      <h2 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
        {title}
      </h2>
      {loading ? (
        <div className='flex h-[225px] w-full items-center justify-center'>
          <LoadingSpinner />
        </div>
      ) : (
        <div style={{ height: 225, width: '100%' }}>
          <div style={{ width: '100%', height: 198 }}>
            <ResponsiveContainer>
              <LineChart
                data={data}
                margin={{
                  top: 0,
                  right: 8,
                  bottom: -15,
                  left: 8,
                }}
              >
                <XAxis
                  dataKey='created'
                  tickLine={false}
                  tick={false}
                  axisLine={false}
                />
                <Tooltip content={CustomTooltip} />
                <Line
                  type='monotone'
                  dataKey='Users'
                  stroke='#636363'
                  strokeWidth={3}
                  activeDot={{ r: 8 }}
                  isAnimationActive={false}
                />
                <Line
                  type='monotone'
                  dataKey='Kliqs'
                  stroke='#11CCEA'
                  strokeWidth={3}
                  isAnimationActive={false}
                />
                <path
                  d={`M${0},${246 - 50} h ${1000 - 22}`}
                  stroke='#11CCEA'
                  strokeWidth={3}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
          <div className='mt-2.5 flex w-full flex-row items-center justify-between text-sm font-medium text-brandText'>
            {data?.length >= 1 && (
              <div>
                <span>{data[0]?.created}</span>
              </div>
            )}
            <div className='flex flex-row items-center justify-center space-x-6'>
              <span className='text-sm font-semibold text-brandText'>
                Users
              </span>
              <span className='text-sm font-semibold text-brandPrimary'>
                Clicks
              </span>
            </div>
            {data?.length >= 1 && (
              <div>
                <span>{data[data?.length - 1]?.created}</span>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
