import { TrendingUpIcon } from '@heroicons/react/solid';

export default function StarTrendTable({ data }) {
  return (
    <div className='flex h-full w-full flex-row space-x-8 text-brandText'>
      <div className='flex w-full flex-col overflow-x-auto overflow-y-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-8 shadow-md'>
        <div className='flex flex-row items-center'>
          <div>
            <div className='ml-3 flex'>
              <TrendingUpIcon className='flex h-12 w-12 items-center justify-center rounded-full bg-brandPrimaryLight p-3 text-white sm:h-14 sm:w-14' />
            </div>
          </div>
          <div>
            <h2 className='ml-2 text-2xl font-medium sm:text-3xl'>Star</h2>
            <p className='ml-2 text-sm'>Trend</p>
          </div>
        </div>
        <table className='mt-3 w-full table-auto border-collapse bg-gradient-to-r from-slate-100 text-xs'>
          {data?.length >= 1 ? (
            <thead>
              <tr className='text-base'>
                <th className='p-4 pl-9 pt-1 pb-1 text-left text-base font-medium sm:pl-14 sm:text-lg'>
                  Date
                </th>
                <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-lg'>
                  All&nbsp;Scores
                </th>
                <th className='p-4 pt-1 pb-1 text-left text-base font-medium md:text-lg'>
                  Proficient&nbsp;Scores
                </th>
                <th className='p-4 pt-1 pr-9 pb-1 text-left text-base font-medium sm:pr-14 md:text-lg'>
                  Proficiency
                </th>
              </tr>
            </thead>
          ) : (
            <thead>
              <tr className='py-1 text-xl'>
                <td>&nbsp;</td>
              </tr>
            </thead>
          )}
          <tbody className='bg-white text-brandText'>
            {data?.length >= 1 &&
              data?.map((item, itemIdx) => (
                <tr key={itemIdx}>
                  <td className='p-4 py-2 pl-9 text-sm font-medium sm:pl-14'>
                    {item?.year}
                  </td>
                  <td className='p-4 py-2 text-sm font-medium'>
                    {item?.all_scores}
                  </td>
                  <td className='p-4 py-2 text-sm font-medium'>
                    {item?.proficient_scores}
                  </td>
                  <td className='p-4 py-2 pr-9 text-sm font-medium sm:pr-14'>
                    {item?.proficiency}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {data?.length === 0 && (
          <div className='ml-10 mt-7 text-lg font-medium'>No Records</div>
        )}
      </div>
    </div>
  );
}
