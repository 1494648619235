import squareLogo from '../../images/logos/square_logo.png';
import React, { useContext, useState } from 'react';
import Notification from '../formComponents/Notification';
import AuthContext from '../../context/AuthContext';
import Spinner from '../sections/Login/Spinner';
import { Link } from 'react-router-dom';

export default function PasswordResetForm() {
  const { baseUrl, user } = useContext(AuthContext);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [labelColor, setLabelColor] = useState('text-brandText');
  const [borderColors, setBorderColors] = useState(
    'border-gray-300 focus:ring-brandPrimary focus:border-brandPrimary',
  );

  const validateFormData = (e) => {
    e.preventDefault();
    if (username?.trim().length >= 1) {
      setLabelColor('text-brandText');
      setBorderColors(
        'border-gray-300 focus:ring-brandPrimary focus:border-brandPrimary',
      );
      handleSubmit(e);
    } else {
      setLabelColor('text-red-500');
      setBorderColors('border-red-500 focus:ring-red-500 focus:border-red-500');
      setShowFailed(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const url = `${baseUrl}/core/password_reset/?username=${username}`;
    const headers = {
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers,
      });

      if (response.status === 200) {
        setShowSuccess(true);
        setLoading(false);
      } else {
        const data = await response.json();
        setShowFailed(true);
        setLoading(false);

        logError('/password-reset', baseUrl, user?.name, {
          error_description:
            'User submitted their username but received an error code',
          response_text: JSON.stringify(data),
        });
      }
    } catch (error) {
      setShowFailed(true);
      setLoading(false);
      console.error(error);

      logError('/password-reset', baseUrl, user?.name, {
        error_description:
          'User submitted their username and received an error.',
        response_text: JSON.stringify(error),
      });
    }
  };

  const logError = async (page, district, userName, errorData) => {
    const url = `${baseUrl}/support/error-log/`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const requestBody = {
      page: page,
      district: district,
      user: userName,
      ...errorData,
    };

    try {
      await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
      });
    } catch (error) {
      console.error('Failed to log error:', error);
    }
  };

  return (
    <>
      <Spinner loading={loading} />
      <div className='relative flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <img
            className='mx-auto h-20 w-auto'
            src={squareLogo}
            alt='Polaris Education Logo'
          />
          <h2 className='mt-6 text-center text-3xl font-extrabold tracking-tight text-brandText'>
            Reset your password
          </h2>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <form
              className='space-y-6'
              onSubmit={(e) => {
                validateFormData(e);
              }}
            >
              <div>
                <label
                  htmlFor='username'
                  className={`${labelColor} block text-sm font-medium text-brandText`}
                >
                  Username
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    id='username'
                    name='username'
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    value={username}
                    placeholder='Username'
                    className={`${borderColors} block w-full appearance-none rounded-md border bg-white px-3 py-2 text-brandText placeholder-gray-400 shadow-sm focus:bg-white focus:text-brandText focus:outline-none sm:text-sm`}
                  />
                </div>
              </div>
              <div className=''>
                <button
                  type='submit'
                  className='flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary py-2 px-4 text-base font-medium text-white duration-300 ease-in-out hover:bg-brandPrimaryLight focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 sm:hover:scale-105'
                >
                  Reset password
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className='absolute top-8 left-8 sm:top-10 sm:left-10'>
          <Link
            to={'/login'}
            className='flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary py-2 px-4 text-base font-medium text-white duration-300 ease-in-out hover:bg-brandPrimaryLight focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 sm:hover:scale-105'
          >
            Back to login
          </Link>
        </div>
      </div>
      <Notification
        success={false}
        show={showFailed}
        setShow={setShowFailed}
        messageOne={'Something went wrong!'}
        messageTwo={
          'Please enter your username and try again. If you continue to have issues, please contact support.'
        }
      />
      <Notification
        success={true}
        show={showSuccess}
        setShow={setShowSuccess}
        messageOne={'Success!'}
        messageTwo={
          'Please check your email for a link to reset your password.'
        }
      />
    </>
  );
}
