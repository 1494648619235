import textLogo from '../images/logos/text_logo.png';
import { Link } from 'react-router-dom';
import { Fragment, useState, useEffect, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MenuAlt2Icon, XIcon } from '@heroicons/react/outline';
import HomeSearch from './HomeSearch';
import AuthContext from '../context/AuthContext';
import facebookIcon from '../images/social/facebook.svg';
import linkedinIcon from '../images/social/linkedin.svg';
import MyShortcuts from './MyShortcuts';
import MobileMyShortcuts from './MobileMyShortcuts';
import blueCompassIcon from '../images/shortcut_icons/blue_compass_icon.png';
import whiteCompassIcon from '../images/shortcut_icons/white_compass_icon.png';
import NavDropdown from './NavDropdown';

/* Dashboard Icons */
import notesAndReferralsIcon from '../images/dashboard_icons/notes_and_referrals.svg';
import actionsAndChecksIcon from '../images/dashboard_icons/actions_and_checks.svg';
import studentsIcon from '../images/dashboard_icons/students.svg';
import caregiverActionsIcon from '../images/dashboard_icons/caregiver_actions.svg';
import studentsISuperviseIcon from '../images/dashboard_icons/students_i_supervise.svg';
import caregiverReportsIcon from '../images/dashboard_icons/caregiver_reports.svg';
import mtssIcon from '../images/dashboard_icons/mtss.svg';
import standardsIcon from '../images/standards_icons/standards_icon.svg';
import mtssSchoolIcon from '../images/dashboard_icons/mtss_school.svg';

export default function SidebarLayout({
  propComponents,
  maxWidth,
  heading,
  altHeading,
  altSubheading,
  sortComponent,
  studentsCurrent,
  notesAndReferralsCurrent,
  caregiverActionsCurrent,
  actionsAndChecksCurrent,
  studentsISuperviseCurrent,
  caregiverReportsCurrent,
  dashboardCurrent,
  mtssCurrent,
  standardsCurrent,
  mtssSchoolCurrent,
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, logoutUser } = useContext(AuthContext);
  const [navigation, setNavigation] = useState([]);
  const todaysDate = new Date();

  useEffect(() => {
    setNavigation([]);

    if (user?.groups?.includes('teacher')) {
      setNavigation((oldNavigation) => [
        ...oldNavigation,
        {
          name: 'Students',
          href: '/students',
          icon: studentsIcon,
          current: studentsCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('teacher') ||
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin')
    ) {
      setNavigation((oldNavigation) => [
        ...oldNavigation,
        {
          name: 'Notes & Referrals',
          href: '/notes-and-referrals',
          icon: notesAndReferralsIcon,
          current: notesAndReferralsCurrent,
        },
      ]);
    }

    if (user?.groups?.includes('teacher')) {
      setNavigation((oldNavigation) => [
        ...oldNavigation,
        {
          name: 'Caregiver Actions',
          href: '/mtss/caregiver-actions',
          icon: caregiverActionsIcon,
          current: caregiverActionsCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin')
    ) {
      setNavigation((oldNavigation) => [
        ...oldNavigation,
        {
          name: 'Actions & Checks',
          href: '/actions-and-checks',
          icon: actionsAndChecksIcon,
          current: actionsAndChecksCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin')
    ) {
      setNavigation((oldNavigation) => [
        ...oldNavigation,
        {
          name: 'Students I supervise',
          href: '/mtss/students-i-supervise',
          icon: studentsISuperviseIcon,
          current: studentsISuperviseCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin')
    ) {
      setNavigation((oldNavigation) => [
        ...oldNavigation,
        {
          name: 'Caregiver Reports',
          href: '/mtss/caregiver-reports',
          icon: caregiverReportsIcon,
          current: caregiverReportsCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin') ||
      user?.groups?.includes('district_admin')
    ) {
      setNavigation((oldNavigation) => [
        ...oldNavigation,
        {
          name: 'MTSS',
          href: '/mtss',
          icon: mtssIcon,
          current: mtssCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin') ||
      user?.groups?.includes('district_admin')
    ) {
      setNavigation((oldNavigation) => [
        ...oldNavigation,
        {
          name: 'School',
          href: '/mtss/school',
          icon: mtssSchoolIcon,
          current: mtssSchoolCurrent,
        },
      ]);
    }

    /* if (user?.groups?.includes('standards_admin')) {
      setNavigation((oldNavigation) => [
        ...oldNavigation,
        {
          name: 'Standards',
          href: '/standards',
          icon: standardsIcon,
          current: standardsCurrent,
        },
      ]);
    } */
  }, [user?.groups]);

  useEffect(() => {
    if (user?.exp < Math.floor(todaysDate.getTime() / 1000)) {
      logoutUser();
    }
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className='fixed top-0 z-40 flex w-screen flex-row scroll-smooth bg-white'>
        <div>
          <div className='flex w-screen items-center justify-between px-4 py-4 sm:px-6 lg:flex-row lg:px-8'>
            <Link to={'/'}>
              <div className='flex justify-center'>
                <img
                  className='h-10 w-auto'
                  src={textLogo}
                  alt='Polaris Logo'
                />
              </div>
            </Link>
            <div className='flex flex-row justify-end'>
              <div className='flex flex-row items-center justify-center'>
                <NavDropdown logoutUser={logoutUser} user={user} />
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-between bg-gray-100 px-4 sm:px-6 md:justify-between lg:px-0'>
            <button
              type='button'
              className='text-brandText focus:outline-none focus:ring-0 focus:ring-inset focus:ring-brandPrimary lg:hidden'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
            </button>
            <div className='hidden w-full max-w-[230px] lg:flex'></div>
            <div className='w-full lg:flex lg:pl-14'>
              {heading ? (
                <h1 className='px-4 py-3 text-center text-2xl font-extrabold text-brandText sm:text-3xl lg:text-4xl'>
                  {heading && heading}
                </h1>
              ) : (
                <div className='flex flex-col space-y-1 py-2 pl-4 sm:pl-6 lg:pl-0'>
                  <h1 className='text-2xl font-bold text-brandText'>
                    {altHeading && altHeading}
                  </h1>
                  <p className='text-md font-bold text-brandText'>
                    {altSubheading && altSubheading}
                  </p>
                </div>
              )}
            </div>
            <div className='w-7 md:hidden'></div>
          </div>
        </div>
      </div>
      <div className='mt-32 lg:mt-[136px]'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-50 lg:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 top-0 z-50 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-brandPrimary pb-4'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XIcon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className='h-0 flex-1 overflow-x-hidden overflow-y-hidden'>
                    <Link
                      className=' duration-300 ease-in-out hover:scale-110'
                      to={'/'}
                    >
                      <div
                        className={`${
                          dashboardCurrent
                            ? ' bg-white text-brandPrimary '
                            : ' bg-brandPrimary text-white '
                        }flex h-14 flex-row items-center justify-between`}
                      >
                        <div className='ml-4 flex h-8 w-8'></div>
                        <div className='text-base font-bold'>Dashboard</div>
                        <div className='mr-4 h-8 w-8'>
                          {/* <CogIcon className='mr-4 h-10 w-10' /> */}
                          {dashboardCurrent ? (
                            <img className='h-8 w-8' src={blueCompassIcon} />
                          ) : (
                            <img className='h-8 w-8' src={whiteCompassIcon} />
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className='flex w-full justify-center bg-white'>
                      <MobileMyShortcuts data={navigation} />
                    </div>
                    <div className='flex min-h-[calc(100vh-128px)] flex-col justify-start'>
                      <div className='px-4 pt-4'>
                        <p className='mx-auto mb-2 text-center text-base font-bold text-white'>
                          Look Up Student Insight
                        </p>
                        <HomeSearch />
                      </div>
                      <div className='flex flex-col pt-10 lg:hidden'>
                        {sortComponent}
                      </div>
                      <div className='flex-1'></div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0' aria-hidden='true'>
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className=' z-40 hidden lg:fixed lg:inset-y-0 lg:top-16 lg:mt-2 lg:flex lg:w-64 lg:flex-col'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='max-x-screen flex w-full flex-grow flex-col overflow-x-hidden overflow-y-hidden bg-brandPrimary'>
            <div className='flex w-full flex-1 flex-col'>
              <Link
                className=' duration-300 ease-in-out hover:scale-110'
                to={'/'}
              >
                <div
                  className={`${
                    dashboardCurrent
                      ? ' border-t-2 border-gray-50 bg-white text-brandPrimary '
                      : ' bg-brandPrimary text-white '
                  }flex h-14 flex-row items-center justify-between`}
                >
                  <div className='ml-4 flex h-8 w-8'></div>
                  <div className='text-base font-bold'>Dashboard</div>
                  <div className='mr-4 h-8 w-8'>
                    {dashboardCurrent ? (
                      <img
                        className='h-8 w-8'
                        src={blueCompassIcon}
                        alt='blue compass'
                      />
                    ) : (
                      <img
                        className='h-8 w-8'
                        src={whiteCompassIcon}
                        alt='white compass'
                      />
                    )}
                  </div>
                </div>
              </Link>
              <div className='flex h-14 bg-white'></div>
              <div className='mt-4 px-4'>
                <p className='mx-auto mb-2 text-center text-base font-bold text-white'>
                  Look Up Student Insight
                </p>
                <HomeSearch />
              </div>
              {/* this dive will hold optional filtering and sorting */}
              {/* <div className='flex-1 overflow-x-hidden overflow-y-hidden pb-4 pt-5'>
                test
              </div> */}
              <div className='hidden flex-col pt-10 lg:flex'>
                {windowDimensions.width >= 1024 && sortComponent}
              </div>
              <div className='flex-1'></div>
            </div>
          </div>
        </div>
        <div className='flex min-h-[calc(100vh-128px)] flex-1 flex-col justify-between sm:min-h-[calc(100vh-128px)] lg:min-h-[calc(100vh-136px)] lg:pl-64'>
          <main>
            <div className='mx-auto'>
              <div className='top-[128px] left-[0px] z-50 hidden lg:fixed lg:block'>
                <MyShortcuts data={navigation} />
              </div>
              <div className={`${maxWidth} mx-auto`}>{propComponents}</div>
            </div>
          </main>
          <div className='flex h-14 flex-row items-center justify-between bg-gradient-to-r from-brandPrimary to-brandPrimaryLight px-8'>
            <div>
              <p className='text-sm font-medium text-white'>
                &copy; {new Date().getFullYear()} Polaris Education.{' '}
                <br className='sm:hidden' /> All rights reserved.
              </p>
            </div>
            <div className='flex space-x-2'>
              <div className=''>
                <a
                  href={
                    'https://www.linkedin.com/company/polaris-education-llc'
                  }
                  target='_blank'
                  rel='noreferrer'
                  className='text-brandText hover:text-brandText'
                >
                  <img
                    className='h-8 w-8 duration-300 ease-in-out lg:hover:scale-110'
                    src={linkedinIcon}
                    alt={`Linkedin icon`}
                  />
                </a>
              </div>
              <div className=''>
                <a
                  href={'https://www.facebook.com/PolarisEduApp'}
                  target='_blank'
                  rel='noreferrer'
                  className='text-white hover:text-brandText'
                >
                  <img
                    className='h-8 w-8 duration-300 ease-in-out lg:hover:scale-110'
                    src={facebookIcon}
                    alt={`Facebook icon`}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

SidebarLayout.defaultProps = {
  heading: null,
  maxWidth: 'max-w-7xl',
  altHeading: null,
  altSubheading: null,
  studentsCurrent: false,
  notesAndReferralsCurrent: false,
  caregiverActionsCurrent: false,
  mtssManagementCurrent: false,
  currentActionsCurrent: false,
  openReferralsCurrent: false,
  actionsAndChecksCurrent: false,
  studentsISuperviseCurrent: false,
  caregiverReportsCurrent: false,
  watchlistCurrent: false,
  mtssListsCurrent: false,
  dashboardCurrent: false,
  mtssCurrent: false,
  standardsCurrent: false,
  mtssSchoolCurrent: false,
};
