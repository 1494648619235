import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className='rounded-md bg-white p-4 text-lg font-medium shadow-md'>
        <p className='text-brandText'>{`Year: ${label}`}</p>
        <p className='text-brandText'>{`Proficiency: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export default function TrendChart({ data, loading, title }) {
  /* const lastFiveYearsData = data?.slice(-5); */

  return (
    <div className='flex h-full w-full flex-col items-center justify-center'>
      <div className='flex h-[350px] w-full flex-col items-center'>
        {loading ? (
          <div className='flex h-full w-full items-center justify-center'>
            <Oval
              height={80}
              width={80}
              color='#0CB2DA'
              wrapperStyle={{}}
              wrapperClass=''
              visible={true}
              ariaLabel='oval-loading'
              secondaryColor='#e5e7eb'
              strokeWidth={2}
              strokeWidthSecondary={2}
            />
          </div>
        ) : (
          <div className='flex h-full w-full'>
            <ResponsiveContainer className={''}>
              <LineChart
                data={data}
                margin={{ top: 5, right: 20, left: 0, bottom: 5 }}
                padding={{ left: 30 }}
              >
                <Tooltip content={<CustomTooltip />} />
                <XAxis
                  dataKey='year'
                  axisLine={false}
                  tickLine={false}
                  tickMargin={10}
                  stroke='#636363'
                  fontWeight={600}
                  fontSize={13}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickMargin={10}
                  stroke='#636363'
                  fontWeight={600}
                  fontSize={13}
                />
                <Line
                  type='linear'
                  dataKey='proficiency'
                  stroke='#11CCEA'
                  strokeWidth={3}
                />
                <path
                  d={`M${59},${350 - 36} h ${1000 - 100}`}
                  stroke='#11CCEA'
                  strokeWidth={3}
                />
                <path
                  d={`M${60},${350 - 35} v ${-350}`}
                  stroke='#11CCEA'
                  strokeWidth={3}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
      <div className=''>
        <h2 className='mt-5 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
          {title}
        </h2>
      </div>
    </div>
  );
}
