import CheckBox from '../../formComponents/CheckBox';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import Notification from '../../formComponents/Notification';
import Select from 'react-select';
import MultiCheckBox from '../../formComponents/MultiCheckBox';

export default function NoteReferralForm() {
  const { authTokens, logoutUser, baseUrl, user } = useContext(AuthContext);

  /* state variables to hold json data from the back-end */
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [academicBehaviorConcerns, setAcademicBehaviorConcerns] = useState([]);
  const [academicSkillsConcerns, setAcademicSkillsConcerns] = useState([]);
  const [socialBehaviorConcerns, setSocialBehaviorConcerns] = useState([]);

  /* state variables to hold form data to submit to the back-end */
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState();
  const [issue, setIssue] = useState('');
  const [teacherActions, setTeacherActions] = useState('');
  const [isReferral, setIsReferral] = useState(true);
  const [
    selectedAcademicBehaviorConcerns,
    setSelectedAcademicBehaviorConcerns,
  ] = useState([]);
  const [
    selectedAcademicBehaviorConcernIds,
    setSelectedAcademicBehaviorConcernIds,
  ] = useState([]);
  const [selectedAcademicSkillsConcerns, setSelectedAcademicSkillsConcerns] =
    useState([]);
  const [
    selectedAcademicSkillsConcernIds,
    setSelectedAcademicSkillsConcernIds,
  ] = useState([]);
  const [academicComment, setAcademicComment] = useState('');
  const [selectedSocialBehaviorConcerns, setSelectedSocialBehaviorConcerns] =
    useState([]);
  const [
    selectedSocialBehaviorConcernIds,
    setSelectedSocialBehaviorConcernIds,
  ] = useState([]);
  const [behavioralComment, setBehavioralComment] = useState('');
  const [attendance, setAttendance] = useState(false);
  const [attendanceComment, setAttendanceComment] = useState('');
  const [parent, setParent] = useState(false);
  const [parentComment, setParentComment] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const [show, setShow] = useState(false);
  const [issueBorderColor, setIssueBorderColor] = useState('border-gray-300 ');
  const [issueLabelColor, setIssueLabelColor] = useState('text-brandText ');
  const [actionBorderColor, setActionBorderColor] =
    useState('border-gray-300 ');
  const [actionLabelColor, setActionLabelColor] = useState('text-brandText ');
  const [categoryBorderColor, setCategoryBorderColor] =
    useState('border-gray-300 ');
  const [categoryLabelColor, setCategoryLabelColor] =
    useState('text-brandText ');

  useEffect(() => {
    setSelectedSubjectIds([]);
    selectedSubjects.forEach((object) => {
      setSelectedSubjectIds((selectedSubjectIds) => [
        ...selectedSubjectIds,
        object?.value,
      ]);
    });
    setSelectedAcademicBehaviorConcernIds([]);
    selectedAcademicBehaviorConcerns.forEach((object) => {
      setSelectedAcademicBehaviorConcernIds(
        (selectedAcademicBehaviorConcernIds) => [
          ...selectedAcademicBehaviorConcernIds,
          object?.value,
        ],
      );
    });
    setSelectedAcademicSkillsConcernIds([]);
    selectedAcademicSkillsConcerns.forEach((object) => {
      setSelectedAcademicSkillsConcernIds(
        (selectedAcademicSkillsConcernIds) => [
          ...selectedAcademicSkillsConcernIds,
          object?.value,
        ],
      );
    });
    setSelectedSocialBehaviorConcernIds([]);
    selectedSocialBehaviorConcerns.forEach((object) => {
      setSelectedSocialBehaviorConcernIds(
        (selectedSocialBehavioralConcernIds) => [
          ...selectedSocialBehavioralConcernIds,
          object?.value,
        ],
      );
    });
  }, [
    selectedCategories,
    selectedSubjects,
    selectedAcademicBehaviorConcerns,
    selectedAcademicSkillsConcerns,
    selectedSocialBehaviorConcerns,
  ]);

  const validateFormData = (e) => {
    e.preventDefault();
    if (isReferral) {
      if (
        selectedPerson !== '' &&
        issue?.trim().length >= 1 &&
        teacherActions?.trim().length >= 1 &&
        selectedCategories?.length >= 1
      ) {
        setIssueLabelColor('text-brandText ');
        setIssueBorderColor('border-gray-300 ');
        setActionLabelColor('text-brandText ');
        setActionBorderColor('border-gray-300 ');
        handleSubmit(e);
      } else {
        setShow(true);
        if (issue?.trim().length < 1) {
          setIssueBorderColor('border-red-500 ');
          setIssueLabelColor('text-red-500 ');
        } else {
          setIssueBorderColor('border-gray-300 ');
          setIssueLabelColor('text-brandText ');
        }
        if (teacherActions?.trim().length < 1) {
          setActionBorderColor('border-red-500 ');
          setActionLabelColor('text-red-500 ');
        } else {
          setActionBorderColor('border-gray-300 ');
          setActionLabelColor('text-brandText ');
        }
        if (selectedCategories?.length < 1) {
          setCategoryLabelColor('text-red-500 ');
          setCategoryBorderColor('border-red-500 ');
        } else {
          setCategoryLabelColor('text-brandText ');
          setCategoryBorderColor('text-gray-300 ');
        }
      }
    } else {
      if (
        selectedPerson !== '' &&
        issue?.trim().length >= 1 &&
        teacherActions?.trim().length >= 1
      ) {
        setIssueLabelColor('text-brandText ');
        setIssueBorderColor('border-gray-300 ');
        setActionLabelColor('text-brandText ');
        setActionBorderColor('border-gray-300 ');
        handleSubmit(e);
      } else {
        setShow(true);
        if (issue?.trim().length < 1) {
          setIssueBorderColor('border-red-500 ');
          setIssueLabelColor('text-red-500 ');
        } else {
          setIssueBorderColor('border-gray-300 ');
          setIssueLabelColor('text-brandText ');
        }
        if (teacherActions?.trim().length < 1) {
          setActionBorderColor('border-red-500 ');
          setActionLabelColor('text-red-500 ');
        } else {
          setActionBorderColor('border-gray-300 ');
          setActionLabelColor('text-brandText ');
        }
      }
    }
  };

  useEffect(() => {
    getCategories();
    getSubjects();
    getAcademicBehaviorConcerns();
    getAcademicSkillsConcerns();
    getSocialBehaviorConcerns();
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedPerson(location?.state?.studentId);
  }, [location?.state?.studentId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseUrl}/mtss/referrals/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
        body: JSON.stringify({
          student: selectedPerson,
          issue: issue,
          teacher_actions: teacherActions,
          is_referral: isReferral,
          category: selectedCategories,
          subject: selectedSubjectIds,
          academic: selectedAcademicBehaviorConcernIds,
          academic_skills: selectedAcademicSkillsConcernIds,
          academic_comment: academicComment,
          behavioral: selectedSocialBehaviorConcernIds,
          behavioral_comment: behavioralComment,
          attendance: attendance,
          attendance_comment: attendanceComment,
          parent: parent,
          parent_comment: parentComment,
        }),
      });

      let data = await response.json();

      if (response.status === 201) {
        navigate(-1);
      } else {
        setShow(true);

        let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: '/mtss/note-referral',
            district: baseUrl,
            user: user.name,
            error_description:
              'User failed to add the note/referral and received a status code.',
            response_text: JSON.stringify(data),
          }),
        });
      }
    } catch (error) {
      setShow(true);
      console.log(error);

      let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: '/mtss/note-referral',
          district: baseUrl,
          user: user.name,
          error_description:
            'User failed to add the note/referral and received an error.',
          response_text: JSON.stringify(error),
        }),
      });
    }
  };

  const getCategories = async () => {
    const response = await fetch(`${baseUrl}/mtss/categories/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    const data = await response.json();

    if (response.status === 200) {
      setCategories(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getSubjects = async () => {
    const response = await fetch(`${baseUrl}/mtss/subjects/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    const data = await response.json();

    if (response.status === 200) {
      setSubjects(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getAcademicBehaviorConcerns = async () => {
    const response = await fetch(
      `${baseUrl}/mtss/concerns/?category__category_name=Academic+Behaviors`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    const data = await response.json();

    if (response.status === 200) {
      setAcademicBehaviorConcerns(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getAcademicSkillsConcerns = async () => {
    const response = await fetch(
      `${baseUrl}/mtss/concerns/?category__category_name=Academic+Skills`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    const data = await response.json();

    if (response.status === 200) {
      setAcademicSkillsConcerns(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const getSocialBehaviorConcerns = async () => {
    const response = await fetch(
      `${baseUrl}/mtss/concerns/?category__category_name=Social+Behaviors`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    const data = await response.json();

    if (response.status === 200) {
      setSocialBehaviorConcerns(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? 'white' : '#636363',
    }),
  };

  return (
    <section>
      <form
        className='mx-auto flex max-w-3xl flex-col space-y-8 px-4 py-16 text-brandText sm:px-6 lg:px-8 lg:py-32'
        action=''
      >
        <div>
          <label
            htmlFor={'student'}
            className='block text-sm font-medium text-brandText'
          >
            Student*
          </label>
          <input
            className={`mt-1 block w-full rounded-md border-gray-300 text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm`}
            type='text'
            disabled={true}
            value={`${location?.state?.studentFirstName} ${location?.state?.studentLastName}`}
          />
        </div>
        <div>
          <label
            htmlFor='issue'
            className={`${issueLabelColor} block text-sm font-medium`}
          >
            What is the issue?*
          </label>
          <div className='mt-1'>
            <textarea
              rows={4}
              name='issue'
              id='issue'
              required={true}
              onChange={(e) => setIssue(e.target.value)}
              value={issue}
              className={`${issueBorderColor} block w-full rounded-md shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm`}
            />
          </div>
        </div>
        <div>
          <label
            htmlFor='teacher_actions'
            className={`${actionLabelColor} block text-sm font-medium`}
          >
            What have you tried?*
          </label>
          <div className='mt-1'>
            <textarea
              rows={4}
              name='teacher_actions'
              id='teacher_actions'
              onChange={(e) => setTeacherActions(e.target.value)}
              className={`${actionBorderColor} block w-full rounded-md shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm`}
              value={teacherActions}
            />
          </div>
        </div>
        <CheckBox
          srName={'Referral Checkbox'}
          id={'is_referral'}
          name={'is_referral'}
          text={'Check box for referral or leave blank for note'}
          textHint={''}
          isChecked={isReferral}
          setIsChecked={setIsReferral}
        />
        {isReferral ? (
          <div className='flex flex-col space-y-8'>
            <MultiCheckBox
              title={'Category*'}
              data={categories}
              setSelectedData={setSelectedCategories}
              dotName={'category_name'}
              labelColor={categoryLabelColor}
              borderColor={categoryBorderColor}
              previousData={selectedCategories}
            />
            <div>
              <label className='text-sm font-medium text-brandText'>
                Subject
              </label>
              <div className='mt-1'>
                <Select
                  defaultValue={selectedSubjects}
                  onChange={setSelectedSubjects}
                  options={subjects}
                  isClearable={true}
                  isMulti={true}
                  isSearchable={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    fontWeight: 500,
                    colors: {
                      ...theme.colors,
                      primary25: '#0CB2DA',
                      primary: '#0CB2DA',
                    },
                  })}
                  styles={customStyles}
                />
              </div>
            </div>
            <div>
              <label className='text-sm font-medium text-brandText'>
                Academic Behavior Concerns
              </label>
              <div className='mt-1'>
                <Select
                  defaultValue={selectedAcademicBehaviorConcerns}
                  onChange={setSelectedAcademicBehaviorConcerns}
                  options={academicBehaviorConcerns}
                  isClearable={true}
                  isMulti={true}
                  isSearchable={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    fontWeight: 500,
                    colors: {
                      ...theme.colors,
                      primary25: '#0CB2DA',
                      primary: '#0CB2DA',
                    },
                  })}
                  styles={customStyles}
                />
              </div>
            </div>
            <div>
              <label className='text-sm font-medium text-brandText'>
                Academic Skills Concerns
              </label>
              <div className='mt-1'>
                <Select
                  defaultValue={selectedAcademicSkillsConcerns}
                  onChange={setSelectedAcademicSkillsConcerns}
                  options={academicSkillsConcerns}
                  isClearable={true}
                  isMulti={true}
                  isSearchable={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    fontWeight: 500,
                    colors: {
                      ...theme.colors,
                      primary25: '#0CB2DA',
                      primary: '#0CB2DA',
                    },
                  })}
                  styles={customStyles}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor='issue'
                className='block text-sm font-medium text-brandText'
              >
                Academic Comments
              </label>
              <div className='mt-1'>
                <textarea
                  rows={4}
                  name='academic_comment'
                  id='academic_comment'
                  onChange={(e) => setAcademicComment(e.target.value)}
                  value={academicComment}
                  className='block w-full rounded-md border-gray-300 shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
                />
              </div>
            </div>
            <div>
              <label className='text-sm font-medium text-brandText'>
                Social Behavioral Concerns
              </label>
              <div className='mt-1'>
                <Select
                  defaultValue={selectedSocialBehaviorConcerns}
                  onChange={setSelectedSocialBehaviorConcerns}
                  options={socialBehaviorConcerns}
                  isClearable={true}
                  isMulti={true}
                  isSearchable={false}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    fontWeight: 500,
                    colors: {
                      ...theme.colors,
                      primary25: '#0CB2DA',
                      primary: '#0CB2DA',
                    },
                  })}
                  styles={customStyles}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor='issue'
                className='block text-sm font-medium text-brandText'
              >
                Behavioral Comments
              </label>
              <div className='mt-1'>
                <textarea
                  rows={4}
                  name='behavioral_comment'
                  id='behavioral_comment'
                  onChange={(e) => setBehavioralComment(e.target.value)}
                  value={behavioralComment}
                  className='block w-full rounded-md border-gray-300 shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
                />
              </div>
            </div>
            <CheckBox
              srName={'Attendance Checkbox'}
              id={'attendance'}
              name={'attendance'}
              text={'Attendance is a contributing factor'}
              textHint={''}
              isChecked={attendance}
              setIsChecked={setAttendance}
            />
            <div>
              <label
                htmlFor='issue'
                className='block text-sm font-medium text-brandText'
              >
                Attendance Comments
              </label>
              <div className='mt-1'>
                <textarea
                  rows={4}
                  name='attendance_comments'
                  id='attendance_comments'
                  onChange={(e) => setAttendanceComment(e.target.value)}
                  value={attendanceComment}
                  className='block w-full rounded-md border-gray-300 shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
                />
              </div>
            </div>
            <CheckBox
              srName={'Parent Checkbox'}
              id={'parent'}
              name={'parent'}
              text={'Parent has been contacted'}
              textHint={''}
              isChecked={parent}
              setIsChecked={setParent}
            />
            <div>
              <label
                htmlFor='issue'
                className='block text-sm font-medium text-brandText'
              >
                Please describe the dates and outcomes
              </label>
              <div className='mt-1'>
                <textarea
                  rows={4}
                  name='parent_comment'
                  id='parent_comment'
                  onChange={(e) => setParentComment(e.target.value)}
                  value={parentComment}
                  className='block w-full rounded-md border-gray-300 shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
                />
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <button
          onClick={validateFormData}
          className='inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
        >
          Submit
        </button>
      </form>
      <Notification
        success={false}
        show={show}
        setShow={setShow}
        messageOne={'Failed to submit form!'}
        messageTwo={
          'Please ensure that all fields and selections have been compconsted.'
        }
      />
    </section>
  );
}
