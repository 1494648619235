import { createContext, useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export default AuthContext;

// AuthProvider component
export const AuthProvider = ({ children }) => {
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem('authTokens')
      ? JSON.parse(localStorage.getItem('authTokens'))
      : null,
  );
  let [user, setUser] = useState(() =>
    localStorage.getItem('authTokens')
      ? jwt_decode(localStorage.getItem('authTokens'))
      : null,
  );

  const baseUrl = process.env.REACT_APP_BASE_URL;
  let [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // Login user
  let loginUser = async (e) => {
    e.preventDefault();
    let response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/auth/token/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: e.target.username.value,
          password: e.target.password.value,
        }),
      },
    );
    try {
      let data = await response.json();

      if (response.status === 200) {
        setAuthTokens(data);
        setUser(jwt_decode(data.access));
        localStorage.setItem('authTokens', JSON.stringify(data));
        navigate('/');
      } else {
        let errorResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/support/error-log/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              page: 'Login',
              district: process.env.REACT_APP_BASE_URL,
              username: e.target.username.value,
              error_description:
                'User failed to log in and received a status code.',
              response_text: JSON.stringify(data),
            }),
          },
        );
      }
    } catch (error) {
      let data = await response.json();
      console.log(error);
      let errorResponse = await fetch(
        `${process.env.REACT_APP_BASE_URL}/support/error-log/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: 'Login',
            district: process.env.REACT_APP_BASE_URL,
            username: e.target.username.value,
            error_description:
              'User failed to log in and received a server error.',
            response_text: JSON.stringify(data),
          }),
        },
      );
    }
  };

  // Logout user
  let logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem('authTokens');
    /* localStorage.removeItem('baseUrl'); */
    navigate('/login');
  };

  // Update token
  let updateToken = async () => {
    let response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/auth/token/refresh/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: authTokens?.refresh }),
      },
    );

    /* let refreshToken = authTokens?.refresh; */

    let data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      /* setAuthTokens({ refresh: refreshToken, access: data.access }); */
      setUser(jwt_decode(data.access));
      localStorage.setItem('authTokens', JSON.stringify(data));
    } else {
      logoutUser();
    }

    if (loading) {
      setLoading(false);
    }
  };

  let postPageView = async (district, userType, pageName) => {
    let response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/support/kliqs/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens?.access}`, // Include auth token in the headers
        },
        body: JSON.stringify({
          district: district,
          user_type: userType,
          page: pageName,
        }),
      },
    );

    if (response.status === 201) {
    } else {
    }
  };

  // Context data
  let contextData = {
    user: user,
    authTokens: authTokens,
    baseUrl: baseUrl,
    loginUser: loginUser,
    logoutUser: logoutUser,
    postPageView: postPageView,
  };

  useEffect(() => {
    if (loading) {
      updateToken();
    }

    let fourMinutes = 1000 * 60 * 4;

    let interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
