import { Link } from 'react-router-dom';
import React, { useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';

export default function NotFound() {
  const { baseUrl, postPageView, user } = useContext(AuthContext);

  useEffect(() => {
    postPageView(baseUrl, user.role, '404/not-found');
  }, []);
  return (
    <>
      <div className='h-screen bg-white py-16 px-6 sm:py-24 md:grid md:place-items-center lg:px-8'>
        <div className='mx-auto max-w-max'>
          <main className='sm:flex'>
            <p className='text-4xl font-bold tracking-tight text-brandPrimary sm:text-5xl'>
              404
            </p>
            <div className='sm:ml-6'>
              <div className='sm:border-l sm:border-gray-200 sm:pl-6'>
                <h1 className='text-4xl font-bold tracking-tight text-brandText sm:text-5xl'>
                  Page not found
                </h1>
                <p className='mt-1 text-base text-brandText'>
                  Please check the URL in the address bar and try again.
                </p>
              </div>
              <div className='mt-10 sm:flex sm:justify-center lg:justify-start'>
                <div className='rounded-md shadow'>
                  <Link
                    to='/'
                    className='md:px-18 flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-2 text-base font-medium text-white duration-300 ease-in-out hover:bg-brandPrimaryLight md:py-3 md:text-lg md:hover:scale-110'
                  >
                    Go back home
                  </Link>
                </div>
                <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3'>
                  <a
                    href='https://www.polaris-edu.com/contact'
                    className='flex w-full items-center justify-center rounded-md border border-transparent bg-white px-6 py-2 text-base font-medium text-brandPrimary duration-300 ease-in-out hover:bg-gray-50 md:py-3 md:px-8 md:text-lg md:hover:scale-110'
                  >
                    Contact support
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
