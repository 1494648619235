import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';
import StatCard from '../components/pages/mtss/school/usage_report/StatCard';
import StatCardContainer from '../components/pages/mtss/school/usage_report/StatCardContainer';
import DailyActivityLineChart from '../components/pages/mtss/school/usage_report/DailyActivityLineChart';
import DividerLine from '../components/elements/DividerLine';
import HorizontalBarChart from '../components/pages/mtss/school/usage_report/HorizontalBarChart';
import DailyNotesLineChart from '../components/pages/mtss/school/usage_report/DailyNotesLineChart';
import SchoolFilter from '../components/pages/mtss/school/usage_report/SchoolFilter';
import DateSelectSlider from '../components/pages/mtss/school/usage_report/DateSelectSlider';

export default function YearlyPerformanceSummary() {
  const { authTokens, postPageView, baseUrl, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [schools, setSchools] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [selectedSchoolsIds, setSelectedSchools] = useState([]);
  const [usersActiveData, setUsersActiveData] = useState();
  const [loadingUsersActiveData, setLoadingUsersActiveData] = useState(false);
  const [usersPerDayData, setUsersPerDayData] = useState();
  const [loadingUsersPerDayData, setLoadingUsersPerDayData] = useState(false);
  const [activeViewsData, setActiveViewsData] = useState();
  const [loadingActiveViewsData, setLoadingActiveViewsData] = useState(false);
  const [dailyActivityData, setDailyActivityData] = useState();
  const [loadingDailyActivityData, setLoadingDailyActivityData] =
    useState(false);
  const [countByRoleData, setCountByRoleData] = useState();
  const [loadingCountByRoleData, setLoadingCountByRoleData] = useState(false);
  const [mostViewedPagesData, setMostViewedPagesData] = useState();
  const [loadingMostViewedPagesData, setLoadingMostViewedPagesData] =
    useState(false);
  const [whoAccessesViewsData, setWhoAccessesViewsData] = useState();
  const [loadingWhoAccessesViewsData, setLoadingWhoAccessesViewsData] =
    useState(false);
  const [interventionsActiveData, setInterventionsActiveData] = useState();
  const [loadingInterventionsActiveData, setLoadingInterventionsActiveData] =
    useState(false);
  const [newInterventionsData, setNewInterventionsData] = useState();
  const [loadingNewInterventionsData, setLoadingNewInterventionsData] =
    useState(false);
  const [interventionsEndedData, setInterventionsEndedData] = useState();
  const [loadingInterventionsEndedData, setLoadingInterventionsEndedData] =
    useState(false);
  const [openReferralsData, setOpenReferralsData] = useState();
  const [loadingOpenReferralsData, setLoadingOpenReferralsData] =
    useState(false);
  const [referralsSubmittedData, setReferralsSubmittedData] = useState();
  const [loadingReferralsSubmittedData, setLoadingReferralsSubmittedData] =
    useState(false);
  const [referralsResolvedData, setReferralsResolvedData] = useState();
  const [loadingReferralsResolvedData, setLoadingReferralsResolvedData] =
    useState(false);
  const [topTreatmentsData, setTopTreatmentsData] = useState();
  const [loadingTopTreatmentsData, setLoadingTopTreatmentsData] =
    useState(false);
  const [topConcernsData, setTopConcernsData] = useState();
  const [loadingTopConcernsData, setLoadingTopConcernsData] = useState(false);
  const [supervisorLoadData, setSupervisorLoadData] = useState();
  const [loadingSupervisorLoadData, setLoadingSupervisorLoadData] =
    useState(false);
  const [caregiverLoadData, setCaregiverLoadData] = useState();
  const [loadingCaregiverLoadData, setLoadingCaregiverLoadData] =
    useState(false);
  const [notesCreatedData, setNotesCreatedData] = useState();
  const [loadingNotesCreatedData, setLoadingNotesCreatedData] = useState(false);
  const [usersThatCreatedNotesData, setUsersThatCreatedNotesData] = useState();
  const [
    loadingUsersThatCreatedNotesData,
    setLoadingUsersThatCreatedNotesData,
  ] = useState(false);
  const [uniqueStudentsWithNotesData, setUniqueStudentsWithNotesData] =
    useState();
  const [
    loadingUniqueStudentsWithNotesData,
    setLoadingUniqueStudentsWithNotesData,
  ] = useState(false);
  const [mostFrequentNotesData, setMostFrequentNotesData] = useState();
  const [loadingMostFrequentNotesData, setLoadingMostFrequentNotesData] =
    useState(false);
  const [dailyNotesData, setDailyNotesData] = useState();
  const [loadingDailyNotesData, setLoadingDailyNotesData] = useState(false);

  useEffect(() => {
    getSchools();
  }, []);

  useEffect(() => {
    if (
      selectedSchoolsIds !== undefined &&
      selectedSchoolsIds?.length > 0 &&
      selectedStartDate !== undefined &&
      selectedEndDate !== undefined
    ) {
      getUsersActiveData();
      getUsersPerDayData();
      getActiveViewsData();
      getDailyActivityData();
      getCountByRoleData();
      getMostViewedPagesData();
      getWhoAccessesViewsData();
      getInterventionsActiveData();
      getNewInterventionsData();
      getInterventionsEndedData();
      getOpenReferralsData();
      getReferralsSubmittedData();
      getReferralsResolvedData();
      getTopTreatmentsData();
      getTopConcernsData();
      getSupervisorLoadData();
      getCaregiverLoadData();
      getNotesCreatedData();
      getUsersThatCreatedNotesData();
      getUniqueStudentsWithNotesData();
      getMostFrequentNotesData();
      getDailyNotesData();
    }
  }, [selectedSchoolsIds, selectedStartDate, selectedEndDate]);

  useEffect(() => {
    postPageView(baseUrl, user.role, 'usage-report');
  }, []);

  const getSchools = async () => {
    try {
      const response = await fetch(`${baseUrl}/sis/schools/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        setSchools(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Schools: ${response.status}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUsersActiveData = async () => {
    try {
      setLoadingUsersActiveData(true);

      const response = await fetch(
        `${baseUrl}/support/summary-report-active-users/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingUsersActiveData(false);

      if (response.ok) {
        setUsersActiveData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Users Active: ${response.status}`);
      }
    } catch (error) {
      setLoadingUsersActiveData(false);
      console.error(error);
    }
  };

  const getUsersPerDayData = async () => {
    try {
      setLoadingUsersPerDayData(true);

      const response = await fetch(
        `${baseUrl}/support/summary-report-daily-users/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingUsersPerDayData(false);

      if (response.ok) {
        setUsersPerDayData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Users Per Day: ${response.status}`);
      }
    } catch (error) {
      setLoadingUsersPerDayData(false);
      console.error(error);
    }
  };

  const getActiveViewsData = async () => {
    try {
      setLoadingActiveViewsData(true);

      const response = await fetch(
        `${baseUrl}/support/summary-report-active-views/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      setLoadingActiveViewsData(false);

      if (response.ok) {
        const data = await response.json();
        setActiveViewsData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Active Views: ${response.status}`);
      }
    } catch (error) {
      setLoadingActiveViewsData(false);
      console.error(error);
    }
  };

  const getDailyActivityData = async () => {
    try {
      setLoadingDailyActivityData(true);

      const response = await fetch(
        `${baseUrl}/support/summary-report-kliq-trend/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingDailyActivityData(false);

      if (response.ok) {
        setDailyActivityData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Daily Activity: ${response.status}`);
      }
    } catch (error) {
      setLoadingDailyActivityData(false);
      console.error(error);
    }
  };

  const getCountByRoleData = async () => {
    try {
      setLoadingCountByRoleData(true);

      const response = await fetch(
        `${baseUrl}/support/summary-report-kliq-groups/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingCountByRoleData(false);

      if (response.ok) {
        setCountByRoleData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Count By Role: ${response.status}`);
      }
    } catch (error) {
      setLoadingCountByRoleData(false);
      console.error(error);
    }
  };

  const getMostViewedPagesData = async () => {
    try {
      setLoadingMostViewedPagesData(true);

      const response = await fetch(
        `${baseUrl}/support/summary-report-kliq-pages/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingMostViewedPagesData(false);

      if (response.ok) {
        setMostViewedPagesData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Most Viewed Pages: ${response.status}`);
      }
    } catch (error) {
      setLoadingMostViewedPagesData(false);
      console.error(error);
    }
  };

  const getWhoAccessesViewsData = async () => {
    try {
      setLoadingWhoAccessesViewsData(true);

      const response = await fetch(
        `${baseUrl}/support/summary-report-kliq-users/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingWhoAccessesViewsData(false);

      if (response.ok) {
        setWhoAccessesViewsData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Who Accesses Views: ${response.status}`);
      }
    } catch (error) {
      setLoadingWhoAccessesViewsData(false);
      console.error(error);
    }
  };

  const getInterventionsActiveData = async () => {
    try {
      setLoadingInterventionsActiveData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-interventions-count/?schools=${selectedSchoolsIds}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingInterventionsActiveData(false);

      if (response.ok) {
        setInterventionsActiveData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(
          `Error fetching Interventions Active: ${response.status}`,
        );
      }
    } catch (error) {
      setLoadingInterventionsActiveData(false);
      console.error(error);
    }
  };

  const getNewInterventionsData = async () => {
    try {
      setLoadingNewInterventionsData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-interventions-created/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingNewInterventionsData(false);

      if (response.ok) {
        setNewInterventionsData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching New Interventions: ${response.status}`);
      }
    } catch (error) {
      setLoadingNewInterventionsData(false);
      console.error(error);
    }
  };

  const getInterventionsEndedData = async () => {
    try {
      setLoadingInterventionsEndedData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-interventions-ended/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      setLoadingInterventionsEndedData(false);

      if (response.ok) {
        const data = await response.json();
        setInterventionsEndedData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Interventions Ended: ${response.status}`);
      }
    } catch (error) {
      setLoadingInterventionsEndedData(false);
      console.error(error);
    }
  };

  const getOpenReferralsData = async () => {
    try {
      setLoadingOpenReferralsData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-referrals-open/?schools=${selectedSchoolsIds}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingOpenReferralsData(false);

      if (response.ok) {
        const data = await response.json();
        setOpenReferralsData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Open Referrals: ${response.status}`);
      }
    } catch (error) {
      setLoadingOpenReferralsData(false);
      console.error(error);
    }
  };

  const getReferralsSubmittedData = async () => {
    try {
      setLoadingReferralsSubmittedData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-referrals-created/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingReferralsSubmittedData(false);

      if (response.ok) {
        const data = await response.json();
        setReferralsSubmittedData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Referrals Submitted: ${response.status}`);
      }
    } catch (error) {
      setLoadingReferralsSubmittedData(false);
      console.error(error);
    }
  };

  const getReferralsResolvedData = async () => {
    try {
      setLoadingReferralsResolvedData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-referrals-resolved/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingReferralsResolvedData(false);

      if (response.ok) {
        const data = await response.json();
        setReferralsResolvedData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Referrals Resolved: ${response.status}`);
      }
    } catch (error) {
      setLoadingReferralsResolvedData(false);
      console.error(error);
    }
  };

  const getTopTreatmentsData = async () => {
    try {
      setLoadingTopTreatmentsData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-top-treatments/?schools=${selectedSchoolsIds}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingTopTreatmentsData(false);

      if (response.ok) {
        const data = await response.json();
        setTopTreatmentsData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Top Treatments: ${response.status}`);
      }
    } catch (error) {
      setLoadingTopTreatmentsData(false);
      console.error(error);
    }
  };

  const getTopConcernsData = async () => {
    try {
      setLoadingTopConcernsData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-top-concerns/?schools=${selectedSchoolsIds}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingTopConcernsData(false);

      if (response.ok) {
        const data = await response.json();
        setTopConcernsData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Top Concerns: ${response.status}`);
      }
    } catch (error) {
      setLoadingTopConcernsData(false);
      console.error(error);
    }
  };

  const getSupervisorLoadData = async () => {
    try {
      setLoadingSupervisorLoadData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-top-supervisors/?schools=${selectedSchoolsIds}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingSupervisorLoadData(false);

      if (response.ok) {
        const data = await response.json();
        setSupervisorLoadData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Supervisor Load: ${response.status}`);
      }
    } catch (error) {
      setLoadingSupervisorLoadData(false);
      console.error(error);
    }
  };

  const getCaregiverLoadData = async () => {
    try {
      setLoadingCaregiverLoadData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-top-caregivers/?schools=${selectedSchoolsIds}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingCaregiverLoadData(false);

      if (response.ok) {
        const data = await response.json();
        setCaregiverLoadData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Caregiver Load: ${response.status}`);
      }
    } catch (error) {
      setLoadingCaregiverLoadData(false);
      console.error(error);
    }
  };

  const getNotesCreatedData = async () => {
    try {
      setLoadingNotesCreatedData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-notes-created/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingNotesCreatedData(false);

      if (response.ok) {
        const data = await response.json();
        setNotesCreatedData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Notes Created: ${response.status}`);
      }
    } catch (error) {
      setLoadingNotesCreatedData(false);
      console.error(error);
    }
  };

  const getUsersThatCreatedNotesData = async () => {
    try {
      setLoadingUsersThatCreatedNotesData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-notes-authors/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingUsersThatCreatedNotesData(false);

      if (response.ok) {
        const data = await response.json();
        setUsersThatCreatedNotesData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(
          `Error fetching Users That Created Notes: ${response.status}`,
        );
      }
    } catch (error) {
      setLoadingUsersThatCreatedNotesData(false);
      console.error(error);
    }
  };

  const getUniqueStudentsWithNotesData = async () => {
    try {
      setLoadingUniqueStudentsWithNotesData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-notes-students/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingUniqueStudentsWithNotesData(false);

      if (response.ok) {
        const data = await response.json();
        setUniqueStudentsWithNotesData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(
          `Error fetching Unique Students With Notes: ${response.status}`,
        );
      }
    } catch (error) {
      setLoadingUniqueStudentsWithNotesData(false);
      console.error(error);
    }
  };

  const getMostFrequentNotesData = async () => {
    try {
      setLoadingMostFrequentNotesData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-top-authors/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingMostFrequentNotesData(false);

      if (response.ok) {
        const data = await response.json();
        setMostFrequentNotesData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Most Frequent Notes: ${response.status}`);
      }
    } catch (error) {
      setLoadingMostFrequentNotesData(false);
      console.error(error);
    }
  };

  const getDailyNotesData = async () => {
    try {
      setLoadingDailyNotesData(true);

      const response = await fetch(
        `${baseUrl}/mtss/summary-report-daily-notes/?schools=${selectedSchoolsIds}&start=${selectedStartDate}&end=${selectedEndDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );
      setLoadingDailyNotesData(false);

      if (response.ok) {
        const data = await response.json();
        setDailyNotesData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching Daily Notes: ${response.status}`);
      }
    } catch (error) {
      setLoadingDailyNotesData(false);
      console.error(error);
    }
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='mx-auto mt-12 pb-20 sm:mt-16 lg:mt-20'>
              {/* section one */}
              <section className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                <StatCardContainer>
                  {/* users active */}
                  <StatCard
                    loading={loadingUsersActiveData}
                    lineOne={
                      usersActiveData?.percent !== undefined
                        ? `${usersActiveData?.percent}%`
                        : '--'
                    }
                    lineTwo={'Users Active'}
                    lineThree={
                      usersActiveData?.active !== undefined
                        ? `${usersActiveData?.active}/${usersActiveData?.total}`
                        : '--'
                    }
                  />
                  {/* users per day */}
                  <StatCard
                    loading={loadingUsersPerDayData}
                    lineOne={
                      usersPerDayData?.daily_users !== undefined
                        ? `${usersPerDayData?.daily_users}`
                        : '--'
                    }
                    lineTwo={'Average Users/Day'}
                  />
                  {/* active views */}
                  <StatCard
                    loading={loadingActiveViewsData}
                    lineOne={
                      activeViewsData?.active_views !== undefined
                        ? `${activeViewsData?.active_views}`
                        : '--'
                    }
                    lineTwo={'Average Users/Day'}
                  />
                </StatCardContainer>
              </section>
              <DividerLine
                classes={
                  'max-w-7xl px-4 sm:px-6 lg:px-8 my-12 sm:my-16 lg:my-20'
                }
              />
              {/* section two */}
              <section className='mx-auto grid max-w-7xl grid-cols-12 gap-y-12 px-4 sm:gap-6 sm:px-6 lg:gap-8 lg:px-8 xl:gap-12'>
                {/* daily activity */}
                <div className='col-span-12 flex h-full w-full md:col-span-7 lg:col-span-7 xl:col-span-7'>
                  <DailyActivityLineChart
                    data={dailyActivityData}
                    loading={loadingDailyActivityData}
                    title={'Daily Activity'}
                  />
                </div>
                {/* view count by role */}
                <div className='col-span-12 flex h-full w-full md:col-span-5 lg:col-span-5 xl:col-span-5'>
                  <HorizontalBarChart
                    data={countByRoleData}
                    loading={loadingCountByRoleData}
                    dataSelectorOne={'user__groups__name'}
                    dataSelectorTwo={'Cnt'}
                    title={'View Count by Role'}
                    leftPadding={60}
                    tooltipTextOne={'User Type:'}
                    tooltipTextTwo={'View Count:'}
                  />
                </div>
              </section>
              <DividerLine
                classes={
                  'max-w-7xl px-4 sm:px-6 lg:px-8 my-12 sm:my-16 lg:my-20'
                }
              />
              {/* section three */}
              <section className='mx-auto flex w-full max-w-7xl flex-col gap-8 gap-y-12 px-4 sm:px-6 md:flex-row lg:px-8 xl:gap-12'>
                {/* most viewed pages */}
                <div className='flex h-full w-full'>
                  <HorizontalBarChart
                    data={mostViewedPagesData}
                    loading={loadingMostViewedPagesData}
                    dataSelectorOne={'page'}
                    dataSelectorTwo={'Cnt'}
                    title={'Most Viewed Pages'}
                    leftPadding={130}
                    tooltipTextOne={'User Type:'}
                    tooltipTextTwo={'View Count:'}
                  />
                </div>
                {/* who accesses views */}
                <div className='flex h-full w-full'>
                  <HorizontalBarChart
                    data={whoAccessesViewsData}
                    loading={loadingCountByRoleData}
                    dataSelectorOne={'user__username'}
                    dataSelectorTwo={'Cnt'}
                    title={'Who Accesses Views'}
                    leftPadding={110}
                    tooltipTextOne={'User Type:'}
                    tooltipTextTwo={'View Count:'}
                  />
                </div>
              </section>
              <DividerLine
                classes={
                  'max-w-7xl px-4 sm:px-6 lg:px-8 my-12 sm:my-16 lg:my-20'
                }
              />
              {/* section four */}
              <section className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                <div className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                  <h2 className='text- mb-5'>MTSS</h2>
                </div>
                <StatCardContainer>
                  {/* interventions active */}
                  <StatCard
                    loading={loadingInterventionsActiveData}
                    lineOne={
                      interventionsActiveData !== undefined
                        ? `${interventionsActiveData}`
                        : '--'
                    }
                    lineTwo={'Interventions Active'}
                  />
                  {/* new interventions */}
                  <StatCard
                    loading={loadingNewInterventionsData}
                    lineOne={
                      newInterventionsData !== undefined
                        ? `${newInterventionsData}`
                        : '--'
                    }
                    lineTwo={'New Interventions'}
                  />
                  {/* interventions ended */}
                  <StatCard
                    loading={loadingInterventionsEndedData}
                    lineOne={
                      interventionsEndedData !== undefined
                        ? `${interventionsEndedData}`
                        : '--'
                    }
                    lineTwo={'Interventions Ended'}
                  />
                  {/* open referrals */}
                  <StatCard
                    loading={loadingOpenReferralsData}
                    lineOne={
                      openReferralsData !== undefined
                        ? `${openReferralsData}`
                        : '--'
                    }
                    lineTwo={'Open Referrals'}
                  />
                  {/* referrals submitted */}
                  <StatCard
                    loading={loadingReferralsSubmittedData}
                    lineOne={
                      referralsSubmittedData !== undefined
                        ? `${referralsSubmittedData}`
                        : '--'
                    }
                    lineTwo={'Referrals Submitted'}
                  />
                  {/* referrals resolved */}
                  <StatCard
                    loading={loadingReferralsResolvedData}
                    lineOne={
                      referralsResolvedData !== undefined
                        ? `${referralsResolvedData}`
                        : '--'
                    }
                    lineTwo={'Referrals Resolved'}
                  />
                </StatCardContainer>
              </section>
              <DividerLine
                classes={
                  'max-w-7xl px-4 sm:px-6 lg:px-8 my-12 sm:my-16 lg:my-20'
                }
              />
              {/* section five */}
              <section className='mx-auto flex w-full max-w-7xl flex-col gap-8 gap-y-12 px-4 sm:px-6 md:flex-row lg:px-8 xl:gap-12'>
                {/* top treatments */}
                <div className='flex h-full w-full'>
                  <HorizontalBarChart
                    data={topTreatmentsData}
                    loading={loadingTopTreatmentsData}
                    dataSelectorOne={'treatment__treatment_name'}
                    dataSelectorTwo={'Cnt'}
                    title={'Top Treatments'}
                    leftPadding={40}
                    tooltipTextOne={'Treatment:'}
                    tooltipTextTwo={'Count:'}
                  />
                </div>
                {/* top concerns */}
                <div className='flex h-full w-full'>
                  <HorizontalBarChart
                    data={topConcernsData}
                    loading={loadingTopConcernsData}
                    dataSelectorOne={'concern__concern_name'}
                    dataSelectorTwo={'Cnt'}
                    title={'Top Concerns'}
                    leftPadding={60}
                    tooltipTextOne={'Concern:'}
                    tooltipTextTwo={'Count:'}
                  />
                </div>
              </section>
              <DividerLine
                classes={
                  'max-w-7xl px-4 sm:px-6 lg:px-8 my-12 sm:my-16 lg:my-20'
                }
              />
              {/* section six */}
              <section className='mx-auto flex w-full max-w-7xl flex-col gap-8 gap-y-12 px-4 sm:px-6 md:flex-row lg:px-8 xl:gap-12'>
                {/* supervisor load */}
                <div className='flex h-full w-full'>
                  <HorizontalBarChart
                    data={supervisorLoadData}
                    loading={loadingSupervisorLoadData}
                    dataSelectorOne={'supervisor__name'}
                    dataSelectorTwo={'Cnt'}
                    title={'Supervisor Load'}
                    leftPadding={20}
                    tooltipTextOne={'User:'}
                    tooltipTextTwo={'Count:'}
                  />
                </div>
                {/* caregiver load */}
                <div className='flex h-full w-full'>
                  <HorizontalBarChart
                    data={caregiverLoadData}
                    loading={loadingCaregiverLoadData}
                    dataSelectorOne={'caregiver__name'}
                    dataSelectorTwo={'Cnt'}
                    title={'Caregiver Load'}
                    leftPadding={20}
                    tooltipTextOne={'User:'}
                    tooltipTextTwo={'Count:'}
                  />
                </div>
              </section>
              <DividerLine
                classes={
                  'max-w-7xl px-4 sm:px-6 lg:px-8 my-12 sm:my-16 lg:my-20'
                }
              />
              {/* section seven */}
              <section className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                <StatCardContainer>
                  {/* notes created */}
                  <StatCard
                    loading={loadingNotesCreatedData}
                    lineOne={
                      notesCreatedData !== undefined
                        ? `${notesCreatedData}`
                        : '--'
                    }
                    lineTwo={'Notes Created'}
                  />
                  {/* users that created notes */}
                  <StatCard
                    loading={loadingUsersThatCreatedNotesData}
                    lineOne={
                      usersThatCreatedNotesData !== undefined
                        ? `${usersThatCreatedNotesData}`
                        : '--'
                    }
                    lineTwo={'Users That Created Notes'}
                  />
                  {/* unique students with notes */}
                  <StatCard
                    loading={loadingUniqueStudentsWithNotesData}
                    lineOne={
                      uniqueStudentsWithNotesData !== undefined
                        ? `${uniqueStudentsWithNotesData}`
                        : '--'
                    }
                    lineTwo={'Unique Students with Notes'}
                  />
                </StatCardContainer>
              </section>
              <DividerLine
                classes={
                  'max-w-7xl px-4 sm:px-6 lg:px-8 my-12 sm:my-16 lg:my-20'
                }
              />
              {/* section eight */}
              <section className='mx-auto grid max-w-7xl grid-cols-12 gap-y-12 px-4 sm:gap-6 sm:px-6 lg:gap-8 lg:px-8 xl:gap-12'>
                {/* most frequent note takers */}
                <div className='col-span-12 flex h-full w-full md:col-span-5 lg:col-span-5 xl:col-span-5'>
                  <HorizontalBarChart
                    data={mostFrequentNotesData}
                    loading={loadingMostFrequentNotesData}
                    dataSelectorOne={'author__username'}
                    dataSelectorTwo={'Cnt'}
                    title={'Most Frequent Note Takers'}
                    leftPadding={120}
                    tooltipTextOne={'User:'}
                    tooltipTextTwo={'Count:'}
                  />
                </div>
                {/* daily notes */}
                <div className='col-span-12 flex h-full w-full md:col-span-7 lg:col-span-7 xl:col-span-7'>
                  <DailyNotesLineChart
                    data={dailyNotesData}
                    loading={loadingDailyNotesData}
                    title={'Daily Notes'}
                  />
                </div>
              </section>
            </div>
          }
          maxWidth={'max-w-none'}
          heading={'Usage Report'}
          sortComponent={
            <>
              <DateSelectSlider
                setSelectedStartDate={setSelectedStartDate}
                setSelectedEndDate={setSelectedEndDate}
              />
              <SchoolFilter
                schools={schools}
                setSelectedSchools={setSelectedSchools}
              />
            </>
          }
        />
      </div>
    </AccessWrapper>
  );
}
