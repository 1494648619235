import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import CaregiverActionsTable from '../components/sections/Dashboard/CaregiverActionsTable';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';

export default function CaregiverActions() {
  let { authTokens, postPageView, user, baseUrl } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /* state variables to hold json data from the back-end */
  let [actions, setActions] = useState([]);

  useEffect(() => {
    getActions();
    postPageView(baseUrl, user.role, 'caregiver-actions');
  }, []);

  let getActions = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/mtss/interventions/caregiver_of`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setActions(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper allowedGroups={['teacher']}>
      <div>
        <SidebarLayout
          propComponents={
            <>
              <CaregiverActionsTable loading={loading} data={actions} />
            </>
          }
          heading={'Caregiver Actions'}
          caregiverActionsCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
