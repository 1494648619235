import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import CurrentActionsTable from '../components/sections/Dashboard/CurrentActionsTable';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';

export default function CurrentActions() {
  let { authTokens, postPageView, user, baseUrl } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /* state variables to hold json data from the back-end */
  let [interventions, setInterventions] = useState([]);

  useEffect(() => {
    getInterventions();
    postPageView(baseUrl, user.role, 'current-actions');
  }, []);

  let getInterventions = async () => {
    setLoading(true);
    let response = await fetch(
      `${baseUrl}/mtss/interventions/?status=1&watchlist=false`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setInterventions(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper
      allowedGroups={['mtss_member', 'mtss_supervisor', 'mtss_admin']}
    >
      <div>
        <SidebarLayout
          propComponents={
            <>
              <CurrentActionsTable loading={loading} data={interventions} />
            </>
          }
          heading={'Current Actions'}
          currentActionsCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
