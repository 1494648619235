import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import NotesAndReferralsTable from '../components/sections/Dashboard/NotesAndReferralsTable';
import AccessWrapper from '../components/AccessWrapper';

export default function NotesAndReferrals() {
  let { authTokens, baseUrl, user, postPageView } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /* state variables to hold json data from the back-end */
  const [referrals, setReferrals] = useState([]);

  /* state variables to hold form data to submit to the back-end */

  useEffect(() => {
    getReferrals();
    postPageView(baseUrl, user.role, 'notes-and-referrals');
  }, []);

  let getReferrals = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/mtss/referrals/my/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setReferrals(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'teacher',
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <>
              <NotesAndReferralsTable loading={loading} data={referrals} />
            </>
          }
          heading={'Notes & Referrals'}
          notesAndReferralsCurrent={true}
        />
      </div>
    </AccessWrapper>
  );
}
