import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import CustomBarChart from '../components/CustomBarChart';
import EthnicityBarChart from '../components/EthnicityBarChart';
import ethnicityIcon from '../images/circle_icons/ethnicity.svg';
import genderIcon from '../images/circle_icons/gender.svg';
import { Oval } from 'react-loader-spinner';
import PassRateTrendChart from '../components/PassRateTrendChart';
import MinimalPassPercentChart from '../components/MinimalPassPercentChart';
import TierPyramid from '../components/TierPyramid';
import SchoolDetailsFilter from '../components/SchoolDetailsFilter';

export default function SchoolDetails() {
  const { authTokens, postPageView, user, baseUrl } = useContext(AuthContext);
  const params = useParams();
  const id = params?.schoolId;
  const [school, setSchool] = useState({});
  const [loadingSchool, setLoadingSchool] = useState(false);
  const [studentCount, setStudentCount] = useState(0);
  const [loadingStudentCount, setLoadingStudentCount] = useState(false);
  const [genderCounts, setGenderCounts] = useState([]);
  const [loadingGenderCounts, setLoadingGenderCounts] = useState(false);
  const [ethnicityCounts, setEthnicityCounts] = useState([]);
  const [loadingEthnicityCounts, setLoadingEthnicityCounts] = useState(false);
  const [mathCurrent, setMathCurrent] = useState([]);
  const [loadingMathCurrent, setLoadingMathCurrent] = useState(false);
  const [elaCurrent, setElaCurrent] = useState([]);
  const [loadingElaCurrent, setLoadingElaCurrent] = useState(false);
  const [mathHistorical, setMathHistorical] = useState([]);
  const [loadingMathHistorical, setLoadingMathHistorical] = useState(false);
  const [elaHistorical, setElaHistorical] = useState([]);
  const [loadingElaHistorical, setLoadingElaHistorical] = useState(false);
  const [tierData, setTierData] = useState([]);

  // variables to hold selected filters
  const [ethnicityOptions, setEthnicityOptions] = useState([]);
  const [sesOptions, setSesOptions] = useState([]);
  const [selectedGenderOptions, setSelectedGenderOptions] = useState([]);
  const [selectedEthnicityOptions, setSelectedEthnicityOptions] = useState([]);
  const [selectedSesOptions, setSelectedSesOptions] = useState([]);
  const [selected504Options, setSelected504Options] = useState([]);
  const [selectedIepOptions, setSelectedIepOptions] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getSchool();
    getStudentCount();
    getGenderCounts();
    getEthnicityCounts();
    getMathCurrent();
    getElaCurrent();
    getMathHistorical();
    getElaHistorical();
    getTierData();
    getEthnicityOptions();
    getSesOptions();
  }, [
    selectedGenderOptions,
    selectedEthnicityOptions,
    selectedSesOptions,
    selected504Options,
    selectedIepOptions,
  ]);

  useEffect(() => {
    postPageView(baseUrl, user.role, 'school-details');
  }, []);

  let getSchool = async () => {
    try {
      setLoadingSchool(true);
      let response = await fetch(`${baseUrl}/sis/schools/${id}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });
      let data = await response.json();

      if (response.status === 200) {
        setSchool(data);
        setLoadingSchool(false);
      } else if (response.status === 403) {
        setLoadingSchool(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getStudentCount = async () => {
    try {
      setLoadingStudentCount(true);
      let response = await fetch(
        `${baseUrl}/sis/student-counts/?school_id=${id}&gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setStudentCount(data);
        setLoadingStudentCount(false);
      } else if (response.status === 403) {
        setLoadingStudentCount(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getGenderCounts = async () => {
    try {
      setLoadingGenderCounts(true);
      let response = await fetch(
        `${baseUrl}/sis/student-gender-counts/?school_id=${id}&gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setGenderCounts(data);
        setLoadingGenderCounts(false);
      } else if (response.status === 403) {
        setLoadingGenderCounts(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      setLoadingGenderCounts(false);
      console.log(error);
    }
  };

  let getEthnicityCounts = async () => {
    try {
      setLoadingEthnicityCounts(true);
      let response = await fetch(
        `${baseUrl}/sis/student-ethnicity-counts/?school_id=${id}&gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setEthnicityCounts(data);
        setLoadingEthnicityCounts(false);
      } else if (response.status === 403) {
        setLoadingEthnicityCounts(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      setLoadingEthnicityCounts(false);
      console.log(error);
    }
  };

  let getMathCurrent = async () => {
    try {
      setLoadingMathCurrent(true);
      let response = await fetch(
        `${baseUrl}/assessment/iar-proficiency/?school_id=${id}&subject=Math&gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setMathCurrent(data);
        setLoadingMathCurrent(false);
      } else if (response.status === 403) {
        setLoadingMathCurrent(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      setLoadingMathCurrent(false);
      console.log(error);
    }
  };

  let getElaCurrent = async () => {
    try {
      setLoadingElaCurrent(true);
      let response = await fetch(
        `${baseUrl}/assessment/iar-proficiency/?school_id=${id}&subject=ELA/L&gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setElaCurrent(data);
        setLoadingElaCurrent(false);
      } else if (response.status === 403) {
        setLoadingElaCurrent(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      setLoadingElaCurrent(false);
      console.log(error);
    }
  };

  let getMathHistorical = async () => {
    try {
      setLoadingMathHistorical(true);
      let response = await fetch(
        `${baseUrl}/assessment/iar-proficiency-historical/?school_id=${id}&subject=Math&gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setMathHistorical(data);
        setLoadingMathHistorical(false);
      } else if (response.status === 403) {
        setLoadingMathHistorical(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      setLoadingMathHistorical(false);
      console.log(error);
    }
  };

  let getElaHistorical = async () => {
    try {
      setLoadingElaHistorical(true);
      let response = await fetch(
        `${baseUrl}/assessment/iar-proficiency-historical/?school_id=${id}&subject=ELA/L&gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setElaHistorical(data);
        setLoadingElaHistorical(false);
      } else if (response.status === 403) {
        setLoadingElaHistorical(false);
        navigate('/unauthorized');
      }
    } catch (error) {
      setLoadingElaHistorical(false);
      console.log(error);
    }
  };

  let getTierData = async () => {
    try {
      let response = await fetch(
        `${baseUrl}/mtss/intervention-tier-percents/?school_id=${id}&gender=${selectedGenderOptions.join()}&ethnicity=${selectedEthnicityOptions.join()}&ses=${selectedSesOptions.join()}&iep=${selectedIepOptions.join()}&five_o_four=${selected504Options.join()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
        },
      );
      let data = await response.json();

      if (response.status === 200) {
        setTierData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getEthnicityOptions = async () => {
    try {
      let response = await fetch(`${baseUrl}/sis/fer/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });
      let data = await response.json();

      if (response.status === 200) {
        setEthnicityOptions(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getSesOptions = async () => {
    try {
      let response = await fetch(`${baseUrl}/sis/ses/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });
      let data = await response.json();

      if (response.status === 200) {
        setSesOptions(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='mt-12 sm:mt-16 lg:mt-20'>
              <div className='mx-auto'>
                <section className='mx-auto px-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl '>
                    <div className='mx-auto flex w-full flex-col justify-between md:flex-row md:space-x-6'>
                      {/* student count section */}
                      <div className='mb-5 flex flex-row items-center justify-center text-brandPrimary md:mb-0 md:w-2/12 md:flex-col'>
                        {loadingStudentCount ? (
                          <div className='py-6'>
                            <Oval
                              height={80}
                              width={80}
                              color='#0CB2DA'
                              wrapperStyle={{}}
                              wrapperClass=''
                              visible={true}
                              ariaLabel='oval-loading'
                              secondaryColor='#e5e7eb'
                              strokeWidth={2}
                              strokeWidthSecondary={2}
                            />
                          </div>
                        ) : (
                          <>
                            <div className='flex w-1/3 flex-col items-center md:w-full'>
                              <p className='text-6xl font-semibold'>
                                {studentCount[0]?.all_students
                                  ? studentCount[0]?.all_students
                                  : 0}
                              </p>
                              <p className='text-3xl font-medium'>Students</p>
                            </div>
                            <div className='flex w-2/3 flex-row justify-around md:mt-10 md:w-full md:justify-between md:px-4'>
                              <div className='flex flex-col items-center'>
                                <p className='text-3xl font-semibold'>
                                  {studentCount[0]?.iep_students
                                    ? studentCount[0]?.iep_students
                                    : 0}
                                </p>
                                <p className='text-2xl font-medium'>IEP</p>
                              </div>
                              <div className='flex flex-col items-center'>
                                <p className='text-3xl font-semibold'>
                                  {studentCount[0]?.five_o_four_students
                                    ? studentCount[0]?.five_o_four_students
                                    : 0}
                                </p>
                                <p className='text-2xl font-medium'>504</p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {/* Ethnicity Section */}
                      <div className='flex w-full md:w-4/12'>
                        <CustomBarChart
                          title={'Gender'}
                          subtitle={'Summary'}
                          icon={genderIcon}
                          data={genderCounts}
                          loading={loadingGenderCounts}
                          selectorOne={'gender'}
                          selectorTwo={'count'}
                        />
                      </div>

                      {/* Gender Section */}
                      <div className='mt-10 flex h-full w-full md:mt-0 md:w-6/12'>
                        <EthnicityBarChart
                          title={'Ethnicity'}
                          subtitle={'Summary'}
                          icon={ethnicityIcon}
                          data={ethnicityCounts}
                          loading={loadingEthnicityCounts}
                          selectorOne={'ethnicity'}
                          selectorTwo={'count'}
                        />
                      </div>
                    </div>
                  </div>
                </section>
                <section className='mt-10 flex w-full flex-col'>
                  <div className='flex w-full items-center justify-center'>
                    <h2 className='mb-5 text-2xl font-normal text-brandPrimary sm:mb-7 sm:text-3xl'>
                      MTSS
                    </h2>
                  </div>
                  <div className='flex w-full flex-col md:flex-row'>
                    <div className='flex w-full flex-col items-center justify-center'>
                      <TierPyramid data={tierData} />
                    </div>
                  </div>
                </section>
                <section className='mx-auto mt-10 w-full bg-gray-100 px-4 pb-20 pt-7 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl '>
                    <h2 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:mb-7 sm:text-3xl'>
                      Academic Proficiency
                    </h2>
                    <div className='mx-auto flex w-full flex-col justify-between md:flex-row md:space-x-6'>
                      <div className='flex w-full flex-col md:w-1/2'>
                        <MinimalPassPercentChart
                          title={'ELA'}
                          data={elaCurrent}
                          loading={loadingElaCurrent}
                          selector={'proficiency'}
                        />
                        <PassRateTrendChart
                          data={elaHistorical}
                          loading={loadingElaHistorical}
                          selectorOne={'year'}
                          selectorTwo={'proficiency'}
                        />
                        <p className='mt-1 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                          ELA Trend
                        </p>
                      </div>
                      <div className='mt-10 flex h-full w-full flex-col md:mt-0 md:w-1/2'>
                        <MinimalPassPercentChart
                          title={'Math'}
                          data={mathCurrent}
                          loading={loadingMathCurrent}
                          selector={'proficiency'}
                        />
                        <PassRateTrendChart
                          data={mathHistorical}
                          loading={loadingMathHistorical}
                          selectorOne={'year'}
                          selectorTwo={'proficiency'}
                        />
                        <p className='mt-1 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                          Math Trend
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          }
          maxWidth={'max-w-none'}
          heading={school?.name}
          sortComponent={
            <SchoolDetailsFilter
              ethnicityOptions={ethnicityOptions}
              sesOptions={sesOptions}
              setSelectedGenderOptions={setSelectedGenderOptions}
              setSelectedEthnicityOptions={setSelectedEthnicityOptions}
              setSelectedSesOptions={setSelectedSesOptions}
              setSelected504Options={setSelected504Options}
              setSelectedIepOptions={setSelectedIepOptions}
            />
          }
        />
      </div>
    </AccessWrapper>
  );
}
