import textLogo from '../images/logos/text_logo.png';
import { Link } from 'react-router-dom';
import { Fragment, useState, useEffect, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
} from '@heroicons/react/outline';
import HomeSearch from './HomeSearch';
import AuthContext from '../context/AuthContext';
import facebookIcon from '../images/social/facebook.svg';
import linkedinIcon from '../images/social/linkedin.svg';
import MyShortcuts from './MyShortcuts';
import MobileMyShortcuts from './MobileMyShortcuts';
import whiteCompassIcon from '../images/shortcut_icons/white_compass_icon.png';
import NavDropdown from './NavDropdown';

/* Dashboard Icons */
import notesAndReferralsIcon from '../images/dashboard_icons/notes_and_referrals.svg';
import actionsAndChecksIcon from '../images/dashboard_icons/actions_and_checks.svg';
import studentsIcon from '../images/dashboard_icons/students.svg';
import caregiverActionsIcon from '../images/dashboard_icons/caregiver_actions.svg';
import studentsISuperviseIcon from '../images/dashboard_icons/students_i_supervise.svg';
import caregiverReportsIcon from '../images/dashboard_icons/caregiver_reports.svg';
import mtssIcon from '../images/dashboard_icons/mtss.svg';
import standardsIcon from '../images/standards_icons/standards_icon.svg';
import mtssSchoolIcon from '../images/dashboard_icons/mtss_school.svg';

export default function StudentSidebarLayout({
  propComponents,
  heading,
  id,
  heading2,
  atAGlanceCurrent,
  gradesCurrent,
  attendanceCurrent,
  behavioralCurrent,
  notesCurrent,
  actionsCurrent,
  readingCurrent,
  mathCurrent,
  studentsCurrent,
  notesAndReferralsCurrent,
  caregiverActionsCurrent,
  actionsAndChecksCurrent,
  studentsISuperviseCurrent,
  caregiverReportsCurrent,
  mtssCurrent,
  standardsCurrent,
  mtssSchoolCurrent,
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user, logoutUser, authTokens } = useContext(AuthContext);
  const [dashboardNavigation, setDashboardNavigation] = useState([]);
  const todaysDate = new Date();

  const navigation = [
    {
      name: 'At a Glance',
      href: `/students/at-a-glance/${id}`,
      icon: HomeIcon,
      current: atAGlanceCurrent,
    },
    {
      name: 'Grades',
      href: `/students/grades/${id}`,
      icon: HomeIcon,
      current: gradesCurrent,
    },
    {
      name: 'Attendance',
      href: `/students/attendance/${id}`,
      icon: UsersIcon,
      current: attendanceCurrent,
    },
    {
      name: 'Behavioral',
      href: `/students/behavioral/${id}`,
      icon: FolderIcon,
      current: behavioralCurrent,
    },
    {
      name: 'Notes/Referrals',
      href: `/students/notes/${id}`,
      icon: CalendarIcon,
      current: notesCurrent,
    },
    {
      name: 'Actions',
      href: `/students/actions/${id}`,
      icon: InboxIcon,
      current: actionsCurrent,
    },
    {
      name: 'Reading',
      href: `/students/reading/${id}`,
      icon: InboxIcon,
      current: readingCurrent,
    },
    {
      name: 'Math',
      href: `/students/math/${id}`,
      icon: InboxIcon,
      current: mathCurrent,
    },
  ];

  useEffect(() => {
    setDashboardNavigation([]);

    if (user?.groups?.includes('teacher')) {
      setDashboardNavigation((oldDashboardNavigation) => [
        ...oldDashboardNavigation,
        {
          name: 'Students',
          href: '/students',
          icon: studentsIcon,
          current: studentsCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('teacher') ||
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin')
    ) {
      setDashboardNavigation((oldDashboardNavigation) => [
        ...oldDashboardNavigation,
        {
          name: 'Notes & Referrals',
          href: '/notes-and-referrals',
          icon: notesAndReferralsIcon,
          current: notesAndReferralsCurrent,
        },
      ]);
    }

    if (user?.groups?.includes('teacher')) {
      setDashboardNavigation((oldDashboardNavigation) => [
        ...oldDashboardNavigation,
        {
          name: 'Caregiver Actions',
          href: '/mtss/caregiver-actions',
          icon: caregiverActionsIcon,
          current: caregiverActionsCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin')
    ) {
      setDashboardNavigation((oldDashboardNavigation) => [
        ...oldDashboardNavigation,
        {
          name: 'Actions & Checks',
          href: '/actions-and-checks',
          icon: actionsAndChecksIcon,
          current: actionsAndChecksCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin')
    ) {
      setDashboardNavigation((oldDashboardNavigation) => [
        ...oldDashboardNavigation,
        {
          name: 'Students I supervise',
          href: '/mtss/students-i-supervise',
          icon: studentsISuperviseIcon,
          current: studentsISuperviseCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin')
    ) {
      setDashboardNavigation((oldDashboardNavigation) => [
        ...oldDashboardNavigation,
        {
          name: 'Caregiver Reports',
          href: '/mtss/caregiver-reports',
          icon: caregiverReportsIcon,
          current: caregiverReportsCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin') ||
      user?.groups?.includes('district_admin')
    ) {
      setDashboardNavigation((oldDashboardNavigation) => [
        ...oldDashboardNavigation,
        {
          name: 'MTSS',
          href: '/mtss',
          icon: mtssIcon,
          current: mtssCurrent,
        },
      ]);
    }

    if (
      user?.groups?.includes('mtss_member') ||
      user?.groups?.includes('mtss_admin') ||
      user?.groups?.includes('district_admin')
    ) {
      setDashboardNavigation((oldDashboardNavigation) => [
        ...oldDashboardNavigation,
        {
          name: 'School',
          href: '/mtss/school',
          icon: mtssSchoolIcon,
          current: mtssSchoolCurrent,
        },
      ]);
    }

    /* if (user?.groups?.includes('standards_admin')) {
      setDashboardNavigation((oldDashboardNavigation) => [
        ...oldDashboardNavigation,
        {
          name: 'Standards',
          href: '/standards',
          icon: standardsIcon,
          current: standardsCurrent,
        },
      ]);
    } */
  }, [user?.groups]);

  useEffect(() => {
    if (user?.exp < Math.floor(todaysDate.getTime() / 1000)) {
      logoutUser();
    }
  }, []);

  return (
    <>
      <div className='fixed top-0 z-40 flex w-screen flex-row scroll-smooth bg-white'>
        <div>
          <div className='flex w-screen items-center justify-between px-4 py-4 sm:px-6 lg:flex-row lg:px-8'>
            <Link to={'/'}>
              <div className='flex justify-center'>
                <img
                  className='h-10 w-auto'
                  src={textLogo}
                  alt='Polaris Logo'
                />
              </div>
            </Link>
            <div className='flex flex-row justify-center'>
              <div className='flex flex-row items-center justify-end'>
                <NavDropdown logoutUser={logoutUser} user={user} />
              </div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-between bg-gray-100 px-4 sm:px-6 md:justify-between lg:px-0'>
            <button
              type='button'
              className='text-brandText focus:outline-none focus:ring-0 focus:ring-inset focus:ring-brandPrimary lg:hidden'
              onClick={() => setSidebarOpen(true)}
            >
              <span className='sr-only'>Open sidebar</span>
              <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
            </button>
            <div className='hidden w-full max-w-[230px] lg:flex'></div>
            <div className='w-full items-center lg:flex lg:justify-between lg:pl-14'>
              <h1 className='py-3 text-center text-2xl font-extrabold text-brandText sm:text-2xl lg:text-4xl'>
                {heading}
              </h1>
              <h2 className='mr-11 hidden text-center text-lg font-bold text-brandPrimary sm:text-lg lg:flex lg:text-2xl'>
                {heading2 ? heading2 : ''}
              </h2>
            </div>
            <div className='w-7  md:hidden'></div>
          </div>
        </div>
      </div>
      <div className='mt-32 lg:mt-[136px]'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-50 lg:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
            </Transition.Child>

            <div className='fixed inset-0 top-0 z-50 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-brandPrimary pb-4'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute top-0 right-0 -mr-12 pt-2'>
                      <button
                        type='button'
                        className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XIcon
                          className='h-6 w-6 text-white'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className='h-0 flex-1 overflow-y-auto overflow-x-hidden'>
                    <Link className='' to={'/'}>
                      <div className='flex h-14 flex-row items-center justify-between bg-brandPrimary text-white'>
                        <div className='ml-4 h-8 w-8'></div>
                        <div className='text-base font-bold'>Dashboard</div>
                        <div className='mr-4 h-8 w-8'>
                          <img
                            className='h-8 w-8'
                            src={whiteCompassIcon}
                            alt='compass icon'
                          />
                        </div>
                      </div>
                    </Link>
                    <div className='flex w-full justify-center bg-white'>
                      <MobileMyShortcuts data={dashboardNavigation} />
                    </div>
                    <div className='px-4 pt-4'>
                      <p className='mx-auto mb-2 text-center text-base font-bold text-white'>
                        Look Up Student Insight
                      </p>
                      <HomeSearch />
                    </div>
                    <div className='flex min-h-[calc(100vh-196px)] flex-col justify-between'>
                      <nav className='mt-4'>
                        {navigation.map((item, itemIdx) => (
                          <Link
                            key={itemIdx}
                            to={item.href}
                            className={`${
                              item?.current
                                ? 'bg-white text-brandPrimary'
                                : 'text-white'
                            } flex items-center py-2 px-7 text-lg font-bold`}
                          >
                            {item.name}
                          </Link>
                        ))}
                      </nav>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className='w-14 flex-shrink-0' aria-hidden='true'>
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className=' z-50 hidden lg:fixed lg:inset-y-0 lg:top-16 lg:mt-2 lg:flex lg:w-64 lg:flex-col'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex flex-grow flex-col overflow-x-hidden overflow-y-hidden bg-brandPrimary'>
            <div className='flex flex-1 flex-col'>
              <Link
                className=' duration-300 ease-in-out hover:scale-110'
                to={'/'}
              >
                <div className='flex h-14 flex-row items-center justify-between bg-brandPrimary text-white'>
                  <div className='ml-4 flex h-8 w-8'></div>
                  <div className='text-base font-bold'>Dashboard</div>
                  <div className='mr-4 h-8 w-8'>
                    <img
                      className='h-8 w-8'
                      src={whiteCompassIcon}
                      alt='compass icon'
                    />
                  </div>
                </div>
              </Link>
              <div className='flex h-14 bg-white'></div>
              <div className='px-4 pt-4 pb-5'>
                <p className='mx-auto mb-2 text-center text-base font-bold text-white'>
                  Look Up Student Insight
                </p>
                <HomeSearch />
              </div>
              <nav className='flex-1 overflow-x-hidden overflow-y-hidden pb-4'>
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`${
                      item?.current
                        ? 'bg-white text-brandPrimary'
                        : 'text-white duration-300 ease-in-out hover:scale-110 hover:bg-white hover:text-brandPrimary'
                    } desktop-nav-item flex items-center justify-center px-8 py-3 text-base font-bold text-white`}
                  >
                    {item.name}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className='flex min-h-[calc(100vh-128px)] flex-1 flex-col justify-between sm:min-h-[calc(100vh-128px)] lg:min-h-[calc(100vh-136px)] lg:pl-64'>
          <main>
            <div className='mx-auto'>
              <div className='fixed top-[128px] left-[0px] z-50 hidden lg:fixed lg:block'>
                <MyShortcuts data={dashboardNavigation} />
              </div>
              <div className='mx-auto'>{propComponents}</div>
            </div>
          </main>
          <div className='-mt-14 flex h-14 flex-row items-center justify-between bg-gradient-to-r from-brandPrimary to-brandPrimaryLight px-8'>
            <div>
              <p className='text-sm font-medium text-white'>
                &copy; {new Date().getFullYear()} Polaris Education.{' '}
                <br className='sm:hidden' /> All rights reserved.
              </p>
            </div>
            <div className='flex space-x-2'>
              <div className=''>
                <a
                  href={
                    'https://www.linkedin.com/company/polaris-education-llc'
                  }
                  target='_blank'
                  rel='noreferrer'
                  className='text-brandText hover:text-brandText'
                >
                  <img
                    className='h-8 w-8 duration-300 ease-in-out lg:hover:scale-110'
                    src={linkedinIcon}
                    alt={`Linkedin icon`}
                  />
                </a>
              </div>
              <div className=''>
                <a
                  href={'https://www.facebook.com/PolarisEduApp'}
                  target='_blank'
                  rel='noreferrer'
                  className='text-white hover:text-brandText'
                >
                  <img
                    className='h-8 w-8 duration-300 ease-in-out lg:hover:scale-110'
                    src={facebookIcon}
                    alt={`Facebook icon`}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

StudentSidebarLayout.defaultProps = {
  atAGlanceCurrent: false,
  gradesCurrent: false,
  attendanceCurrent: false,
  behavioralCurrent: false,
  notesCurrent: false,
  actionsCurrent: false,
  readingCurrent: false,
  mathCurrent: false,
  studentsCurrent: false,
  notesAndReferralsCurrent: false,
  caregiverActionsCurrent: false,
  mtssManagementCurrent: false,
  currentActionsCurrent: false,
  openReferralsCurrent: false,
  actionsAndChecksCurrent: false,
  studentsISuperviseCurrent: false,
  caregiverReportsCurrent: false,
  watchlistCurrent: false,
  mtssListsCurrent: false,
  mtssCurrent: false,
  standardsCurrent: false,
  mtssShoolCurrent: false,
};
