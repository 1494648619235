import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

export default function YearSortFlyout({
  data,
  selectedYear,
  setSelectedYear,
}) {
  return (
    <Popover className='relative'>
      <Popover.Button className='inline-flex items-center gap-x-1 rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium leading-6 text-white duration-300 ease-out hover:scale-110 hover:border-brandPrimaryLight hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none'>
        <span>Select Year</span>
        <ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-200'
        enterFrom='opacity-0 translate-y-1'
        enterTo='opacity-100 translate-y-0'
        leave='transition ease-in duration-150'
        leaveFrom='opacity-100 translate-y-0'
        leaveTo='opacity-0 translate-y-1'
      >
        <Popover.Panel className='absolute left-0 z-10 mt-5 flex w-screen max-w-min'>
          {({ close }) => (
            <div className='flex w-full shrink flex-col items-start rounded-xl bg-white py-4 px-6 text-sm font-semibold leading-6 text-brandText shadow-lg ring-1 ring-gray-900/5'>
              {data?.map((item, itemIdx) => (
                <button
                  key={itemIdx}
                  onClick={() => {
                    setSelectedYear(item?.id);
                    close();
                  }}
                  className={`${
                    item?.id === selectedYear ? 'text-brandPrimary' : null
                  } block p-2 hover:text-brandPrimary`}
                >
                  {item?.end_year}
                </button>
              ))}
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
