import React, { useState, useEffect } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  LabelList,
  YAxis,
  ResponsiveContainer,
} from 'recharts';
import { Oval } from 'react-loader-spinner';

export default function EthnicityBarChart({
  title,
  subtitle,
  icon,
  data,
  loading,
  selectorOne,
  selectorTwo,
}) {
  const [key, setKey] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const totalCount = data.reduce((acc, curr) => acc + curr.count, 0);
    setTotalCount(totalCount);
    setKey((prevKey) => prevKey + 1);
  }, [data]);

  const renderCustomizedLabel = (props, data) => {
    const { x, y, width, height, value, index } = props;
    const count = data[index].count;
    const percentage =
      totalCount > 0 ? Math.round((count / totalCount) * 100) : 0;

    return (
      <text
        x={x + width + 5}
        y={y + height - 5}
        fill='#636363'
        textAnchor='start'
        opacity={1}
        fontWeight='bold'
      >
        {`${value} (${percentage}%)`}
      </text>
    );
  };

  return (
    <div className='flex h-96 w-full flex-row space-x-8 text-brandText'>
      <div className='flex h-full w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-3 shadow-md'>
        <div className='flex flex-row items-center'>
          <img
            className='ml-3 h-12 w-12 sm:h-14 sm:w-14'
            src={icon}
            alt='Grades'
          />
          <div>
            <h2 className='ml-2 text-2xl font-medium sm:text-3xl'>{title}</h2>
            <p className='ml-2 text-sm'>{subtitle}</p>
          </div>
        </div>
        {!loading && data?.length > 0 ? (
          <div className='mt-3 h-full w-full px-1 text-brandText'>
            <ResponsiveContainer key={key}>
              <BarChart
                data={data}
                layout='vertical'
                barCategoryGap='23%'
                margin={{ top: 0, right: 260, left: 0, bottom: 15 }}
              >
                <defs>
                  <linearGradient id='colorUv' x1='0' y1='0' x2='1' y2='0'>
                    <stop offset='0' stopColor='#11CCEA' stopOpacity={1} />
                    <stop offset='1' stopColor='#e9fbfd' stopOpacity={1} />
                  </linearGradient>
                </defs>
                <XAxis type='number' hide />
                <YAxis
                  type='category'
                  width={30}
                  padding={{ left: 0 }}
                  dataKey={selectorOne}
                  tickLine={false}
                  axisLine={false}
                  tick={false}
                />
                <Bar
                  dataKey={selectorTwo}
                  radius={[5, 5, 5, 5]}
                  fill='#11CCEA'
                  stroke='#636363'
                  strokeWidth={1}
                >
                  <LabelList
                    dataKey={selectorOne}
                    content={(props) => renderCustomizedLabel(props, data)}
                    position='insideRight'
                  />
                </Bar>
                <path
                  d={`M${0},${275} h ${1000}`}
                  stroke='#636363'
                  strokeWidth={3}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div className='flex h-full w-full flex-col items-center justify-center pb-5 text-4xl text-gray-200'>
            {loading ? (
              <Oval
                height={80}
                width={80}
                color='#0CB2DA'
                ariaLabel='oval-loading'
                secondaryColor='#e5e7eb'
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            ) : (
              <div>No Records</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
