import FilterMultiCheck from './FilterMultiCheck';

export default function CourseListFilter({
  setOnlyShowCoursesWithGrades,
  schools,
  setSelectedSchools,
}) {
  const handleChange = (event) => {
    setOnlyShowCoursesWithGrades(event.target.checked ? '1' : '0');
  };

  return (
    <div className='flex max-h-[calc(100vh-236px)] flex-col space-y-6 overflow-y-auto overflow-x-hidden pb-10 lg:max-h-[calc(100vh-288px)]'>
      <fieldset className=''>
        <div>
          <legend className='sr-only'>{'Grades'}</legend>
          <p
            className={`mx-auto mb-2 text-center text-base font-bold text-white`}
          >
            Grades
          </p>
        </div>
        <div className='flex flex-col space-y-5'>
          <div className='relative flex items-start pl-4'>
            <div className='flex h-5 items-center'>
              <input
                id={`onlyShowCoursesWithGrades`}
                type='checkbox'
                onChange={handleChange}
                className='h-4 w-4 rounded border-white text-brandPrimaryLight focus:ring-brandPrimaryLight'
              />
            </div>
            <div className='ml-3 mr-3 text-sm'>
              <label
                id='onlyShowCoursesWithGrades'
                htmlFor={`onlyShowCoursesWithGrades`}
                className='font-semibold text-white'
              >
                Only show courses with grades
              </label>
            </div>
          </div>
        </div>
      </fieldset>
      <FilterMultiCheck
        label={'Schools'}
        data={schools}
        dotName={'name'}
        idSelector={'school'}
        setSelectedData={setSelectedSchools}
      />
    </div>
  );
}
