import noteIcon from '../../images/circle_icons/note.svg';
import React, { useState, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import Notification from '../formComponents/Notification';

export default function AAGCreateANote({ student, update }) {
  let { authTokens, baseUrl, user } = useContext(AuthContext);
  const [issueBorderColor, setIssueBorderColor] = useState('border-gray-200');
  const [teacherActionsBorderColor, setTeacherActionsBorderColor] =
    useState('border-gray-200');

  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);

  /* state variables to hold form data to submit to the back-end */
  let [issue, setIssue] = useState('');
  let [teacherActions, setTeacherActions] = useState('');

  const validateFormData = (e) => {
    e.preventDefault();
    if (issue?.trim().length >= 1 && teacherActions?.trim().length >= 1) {
      setIssueBorderColor('border-gray-200');
      setTeacherActionsBorderColor('border-gray-200');
      handleSubmit(e);
    } else {
      if (issue?.trim().length >= 1) {
        setIssueBorderColor('border-gray-200');
      } else {
        setIssueBorderColor('border-red-500');
      }

      if (teacherActions?.trim().length >= 1) {
        setTeacherActionsBorderColor('border-gray-200');
      } else {
        setTeacherActionsBorderColor('border-red-500');
      }

      setShowFailed(true);
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(`${baseUrl}/mtss/referrals/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
        body: JSON.stringify({
          student: student?.id,
          issue: issue,
          teacher_actions: teacherActions,
        }),
      });

      let data = await response.json();

      if (response.status === 201) {
        setIssue('');
        setTeacherActions('');
        setShowSuccess(true);
        update();
      } else {
        setShowFailed(true);

        let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: '/students/create-a-note',
            district: baseUrl,
            user: user.name,
            error_description:
              'User failed to create a note and received a status code.',
            response_text: JSON.stringify(data),
          }),
        });
      }
    } catch (error) {
      setShowFailed(true);
      console.log(error);

      let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: '/students/create-a-note',
          district: baseUrl,
          user: user.name,
          error_description:
            'User failed to create a note and received an error.',
          response_text: JSON.stringify(error),
        }),
      });
    }
  };

  return (
    <div className='flex w-full flex-col overflow-hidden rounded-lg border-2 border-x-slate-100 border-t-slate-50 border-b-slate-100 bg-white pt-5 pb-5 text-brandText shadow-md'>
      <div className='flex flex-row items-end'>
        <div className='ml-3'>
          <img
            className='h-12 w-12 sm:h-14 sm:w-14'
            src={noteIcon}
            alt='Note'
          />
        </div>
        <div>
          <h2 className='ml-2 text-2xl font-medium sm:text-3xl'>
            Create a Note
          </h2>
        </div>
      </div>
      <div className='mt-3 h-10 bg-gradient-to-r from-slate-100 to-white'></div>
      <div className='-mt-8 flex flex-col xl:flex-row'>
        <div className='flex w-full flex-col'>
          <div>
            <p className='pl-6 font-medium'>What is the issue?*</p>
          </div>
          <div className='mt-5 ml-5 mr-5 xl:mr-0'>
            <textarea
              rows={4}
              name='issue'
              id='issue'
              onChange={(e) => setIssue(e.target.value)}
              value={issue}
              className={`${issueBorderColor} block w-full rounded-md shadow-md focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm`}
            />
          </div>
        </div>
        <div className='mt-5 h-10 bg-gradient-to-r from-slate-100 to-white xl:hidden'></div>
        <div className='-mt-8 flex w-full flex-col xl:mt-0'>
          <div>
            <p className='pl-6 font-medium'>What have you tried?*</p>
          </div>
          <div className='mt-5 ml-5 mr-5'>
            <textarea
              rows={4}
              name='What have you tried'
              id='teacher_actions'
              onChange={(e) => setTeacherActions(e.target.value)}
              value={teacherActions}
              className={`${teacherActionsBorderColor} block w-full rounded-md border-gray-200 shadow-md focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm`}
            />
          </div>
        </div>
      </div>
      <div className='mr-5 mt-5 flex justify-end'>
        <button
          onClick={validateFormData}
          className='rounded-md border border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
        >
          Create Note
        </button>
      </div>
      <Notification
        success={false}
        show={showFailed}
        setShow={setShowFailed}
        messageOne={'Failed to create note!'}
        messageTwo={'Please ensure that all fields have been completed.'}
      />
      <Notification
        success={true}
        show={showSuccess}
        setShow={setShowSuccess}
        messageOne={'Successfully created note!'}
      />
    </div>
  );
}
