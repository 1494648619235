import { useEffect, useState } from 'react';

export default function SchoolFilter({ schools, setSelectedSchools }) {
  const [selectedIds, setSelectedIds] = useState('');
  const [dataIsLoaded, setDataIsLoaded] = useState(false);

  const handleChange = (event) => {
    const { checked, value } = event.currentTarget;

    setSelectedIds((prevIds) => {
      // Split the comma-separated string into an array
      const idsArray = prevIds.split(',').filter((id) => id !== ''); // Remove empty elements

      if (checked) {
        // Add the ID to the array if checked and not already present
        if (!idsArray.includes(value)) {
          idsArray.push(value);
        }
      } else {
        // Remove the ID from the array if unchecked
        const index = idsArray.indexOf(value);
        if (index !== -1) {
          idsArray.splice(index, 1);
        }
      }

      // Join the array back into a comma-separated string
      return idsArray.join(',');
    });
  };

  useEffect(() => {
    if (schools?.length > 1 && !dataIsLoaded) {
      // Initialize selectedIds with all school IDs separated by commas
      const tempIds = schools.map((a) => a.id).join(',');
      setSelectedIds(tempIds);
      setDataIsLoaded(true);
    }
  }, [schools]);

  useEffect(() => {
    // Update the selected schools in the parent component
    setSelectedSchools(selectedIds);
  }, [selectedIds]);

  return (
    <div className='flex max-h-[calc(100vh-236px)] flex-col space-y-6 overflow-y-auto overflow-x-hidden pb-10 lg:max-h-[calc(100vh-288px)]'>
      <fieldset className=''>
        <div>
          <legend className='sr-only'>Schools</legend>
          <p
            className={`mx-auto mb-2 text-center text-base font-bold text-white`}
          >
            Schools
          </p>
        </div>
        <div className='flex flex-col space-y-5'>
          {schools?.map((item, itemIdx) => (
            <div key={itemIdx} className='relative flex items-start pl-4'>
              <div className='flex h-5 items-center'>
                <input
                  id={`school${itemIdx}`}
                  value={item?.id}
                  checked={selectedIds.split(',').includes(item.id.toString())}
                  type='checkbox'
                  onChange={handleChange}
                  className='h-4 w-4 rounded border-white text-brandPrimaryLight focus:ring-brandPrimaryLight'
                />
              </div>
              <div className='ml-3 mr-3 text-sm'>
                <label
                  htmlFor={`school${itemIdx}`}
                  className='font-semibold text-white'
                >
                  {item?.name}
                </label>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
}
