import squareLogo from '../../images/logos/square_logo.png';
import React, { useContext, useState } from 'react';
import Notification from '../formComponents/Notification';
import AuthContext from '../../context/AuthContext';
import Spinner from '../sections/Login/Spinner';
import { useNavigate } from 'react-router-dom';

export default function PasswordResetForm({ uidb64, token }) {
  const { baseUrl, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailed, setShowFailed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordLabelColor, setPasswordLabelColor] =
    useState('text-brandText');
  const [passwordBorderColors, setPasswordBorderColors] = useState(
    'border-gray-300 focus:ring-brandPrimary focus:border-brandPrimary',
  );
  const [confirmPasswordLabelColor, setConfirmPasswordLabelColor] =
    useState('text-brandText');
  const [confirmPasswordBorderColors, setConfirmPasswordBorderColors] =
    useState(
      'border-gray-300 focus:ring-brandPrimary focus:border-brandPrimary',
    );

  const validateFormData = (e) => {
    e.preventDefault();

    if (password?.trim().length >= 1 && password === confirmPassword) {
      // Password and confirmPassword are not empty and match
      setPasswordLabelColor('text-brandText');
      setPasswordBorderColors(
        'border-gray-300 focus:ring-brandPrimary focus:border-brandPrimary',
      );
      setConfirmPasswordLabelColor('text-brandText');
      setConfirmPasswordBorderColors(
        'border-gray-300 focus:ring-brandPrimary focus:border-brandPrimary',
      );
      handleSubmit(e);
    } else {
      // Password or confirmPassword is empty or they don't match
      setPasswordLabelColor('text-red-500');
      setPasswordBorderColors(
        'border-red-500 focus:ring-red-500 focus:border-red-500',
      );
      setConfirmPasswordLabelColor('text-red-500');
      setConfirmPasswordBorderColors(
        'border-red-500 focus:ring-red-500 focus:border-red-500',
      );
      setShowFailed(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const url = `${baseUrl}/core/password_reset_confirm/ `;
    const headers = {
      'Content-Type': 'application/json',
    };
    const requestBody = {
      new_password: password,
      confirm_password: confirmPassword,
      uidb64: uidb64,
      token: token,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
      });

      if (response.status === 200) {
        setLoading(false);
        navigate('/login', { state: { passwordReset: true } });
      } else {
        const data = await response.json();
        setShowFailed(true);
        setLoading(false);

        logError('/password-update', baseUrl, user?.name, {
          error_description:
            'User submitted their new password but received an error code',
          response_text: JSON.stringify(data),
        });
      }
    } catch (error) {
      setShowFailed(true);
      setLoading(false);
      console.error(error);

      logError('/password-reset', baseUrl, user?.name, {
        error_description:
          'User submitted their new password and received an error.',
        response_text: JSON.stringify(error),
      });
    }
  };

  const logError = async (page, district, userName, errorData) => {
    const url = `${baseUrl}/support/error-log/`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const requestBody = {
      page: page,
      district: district,
      user: userName,
      ...errorData,
    };

    try {
      await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestBody),
      });
    } catch (error) {
      console.error('Failed to log error:', error);
    }
  };

  return (
    <>
      <Spinner loading={loading} />
      <div className='flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <img
            className='mx-auto h-20 w-auto'
            src={squareLogo}
            alt='Polaris Education Logo'
          />
          <h2 className='mt-6 text-center text-3xl font-extrabold tracking-tight text-brandText'>
            Enter a new password
          </h2>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <form
              className='space-y-6'
              onSubmit={(e) => {
                validateFormData(e);
              }}
            >
              <div>
                <label
                  htmlFor='username'
                  className={`${passwordLabelColor} block text-sm font-medium text-brandText`}
                >
                  New password
                </label>
                <div className='mt-1'>
                  <input
                    type='password'
                    id='new-password'
                    name='new-password'
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    value={password}
                    placeholder='New password'
                    className={`${passwordBorderColors} block w-full appearance-none rounded-md border bg-white px-3 py-2 text-brandText placeholder-gray-400 shadow-sm focus:bg-white focus:text-brandText focus:outline-none sm:text-sm`}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor='confirm-password'
                  className={`${confirmPasswordLabelColor} block text-sm font-medium text-brandText`}
                >
                  Confirm password
                </label>
                <div className='mt-1'>
                  <input
                    type='password'
                    id='confirm-password'
                    name='confirm-password'
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                    value={confirmPassword}
                    placeholder='Confirm password'
                    className={`${confirmPasswordBorderColors} block w-full appearance-none rounded-md border bg-white px-3 py-2 text-brandText placeholder-gray-400 shadow-sm focus:bg-white focus:text-brandText focus:outline-none sm:text-sm`}
                  />
                </div>
              </div>
              <div className=''>
                <button
                  type='submit'
                  className='flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary py-2 px-4 text-base font-medium text-white duration-300 ease-in-out hover:bg-brandPrimaryLight focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 sm:hover:scale-105'
                >
                  Reset password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Notification
        success={false}
        show={showFailed}
        setShow={setShowFailed}
        messageOne={'Something went wrong!'}
        messageTwo={
          'Please make sure to fill out both fields and ensure the passwords match. If you continue to have issues, please contact support.'
        }
      />
    </>
  );
}
