import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../formComponents/TextInput';
import CheckBox from '../../formComponents/CheckBox';
import UpdateMultiCheckBox from '../../formComponents/UpdateMultiCheckBox';
import Notification from '../../formComponents/Notification';

export default function OutcomesForm({ params }) {
  const { authTokens, logoutUser, baseUrl, user } = useContext(AuthContext);
  const [outcomeLabelColor, setOutcomeLabelColor] = useState('text-brandText');
  const [outcomeBorderColor, setOutcomeBorderColor] =
    useState('border-gray-300');
  const [schoolLabelColor, setSchoolLabelColor] = useState('text-brandText');
  const [schoolBorderColor, setSchoolBorderColor] = useState('border-gray-300');

  /* state variables to hold json data from the back-end */
  const [schools, setSchools] = useState([]);
  const [previousDetails, setPreviousDetails] = useState();

  /* state variables to hold form data to submit to the back-end */
  const [outcomeName, setOutcomeName] = useState('');
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [status, setStatus] = useState(false);

  const [show, setShow] = useState(false);

  const validateFormData = (e) => {
    e.preventDefault();
    if (outcomeName?.trim().length >= 1 && selectedSchools.length !== 0) {
      setOutcomeLabelColor('text-brandText');
      setOutcomeBorderColor('border-gray-300');
      setSchoolLabelColor('text-brandText');
      setSchoolBorderColor('border-gray-300');
      handleSubmit(e);
    } else {
      if (outcomeName?.trim().length >= 1) {
        setOutcomeLabelColor('text-brandText');
        setOutcomeBorderColor('border-gray-300');
      } else {
        setOutcomeLabelColor('text-red-500');
        setOutcomeBorderColor('border-red-500');
      }

      if (selectedSchools.length !== 0) {
        setSchoolLabelColor('text-brandText');
        setSchoolBorderColor('border-gray-300');
      } else {
        setSchoolLabelColor('text-red-500');
        setSchoolBorderColor('border-red-500');
      }

      setShow(true);
    }
  };

  useEffect(() => {
    getPreviousDetails();
    getSchools();
  }, []);

  const navigate = useNavigate();

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(`${baseUrl}/mtss/outcomes/${params}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
        body: JSON.stringify({
          outcome_name: outcomeName,
          school: selectedSchools,
          status: status,
        }),
      });

      let data = await response.json();

      if (response.status === 200) {
        navigate(-1);
      } else {
        setShow(true);

        let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: '/mtss/update-outcome',
            district: baseUrl,
            user: user.name,
            error_description:
              'User failed to update the outcome and received a status code.',
            response_text: JSON.stringify(data),
          }),
        });
      }
    } catch (error) {
      setShow(true);
      console.log(error);

      let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: '/mtss/update-outcome',
          district: baseUrl,
          user: user.name,
          error_description:
            'User failed to update the outcome and received an error.',
          response_text: JSON.stringify(error),
        }),
      });
    }
  };

  let getPreviousDetails = async () => {
    let response = await fetch(`${baseUrl}/mtss/outcomes/${params}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setPreviousDetails(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  let getSchools = async () => {
    let response = await fetch(`${baseUrl}/sis/schools/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setSchools(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  useEffect(() => {
    setOutcomeName(previousDetails?.outcome_name);
    setSelectedSchools(previousDetails?.school);
    setStatus(previousDetails?.status);
  }, [previousDetails]);

  return (
    <div>
      <form
        className='mx-auto flex max-w-3xl flex-col space-y-8 px-4 py-12 sm:px-6 sm:py-16 lg:px-8 lg:py-20'
        action=''
      >
        <TextInput
          name={'outcome_name'}
          displayName={'Outcome Name*'}
          data={outcomeName}
          setData={setOutcomeName}
          labelColor={outcomeLabelColor}
          borderColor={outcomeBorderColor}
        />
        <UpdateMultiCheckBox
          title={'School*'}
          hint={''}
          data={schools}
          previousData={previousDetails?.school}
          setSelectedData={setSelectedSchools}
          dotName={'name'}
          labelColor={schoolLabelColor}
          borderColor={schoolBorderColor}
        />
        <CheckBox
          label={'Active Status'}
          srName={'Active'}
          name={'Active'}
          id={'Active'}
          text={'Active'}
          textHint={''}
          isChecked={status}
          setIsChecked={setStatus}
        />
        <button
          onClick={validateFormData}
          className='inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
        >
          Submit
        </button>
      </form>
      <Notification
        success={false}
        show={show}
        setShow={setShow}
        messageOne={'Failed to submit form!'}
        messageTwo={
          'Please ensure that all fields and selections have been completed.'
        }
      />
    </div>
  );
}
