import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import CourseListTable from '../components/sections/Dashboard/CourseListTable';
import AccessWrapper from '../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';
import Pagination from '../components/Pagination';
import CourseListFilter from '../components/pages/mtss/school/courses/course_list/CourseListFilter';

export default function CourseList() {
  let { authTokens, postPageView, user, baseUrl } = useContext(AuthContext);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(50);
  const [showPagination, setShowPagination] = useState(true);
  const [schools, setSchools] = useState([]);
  const [loadingSchools, setLoadingSchools] = useState(false);
  const [onlyShowCoursesWithGrades, setOnlyShowCoursesWithGrades] =
    useState('0');
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [numberSortDirection, setNumberSortDirection] = useState(true);
  const [titleSortDirection, setTitleSortDirection] = useState(true);
  const [levelSortDirection, setLevelSortDirection] = useState(true);
  const [departmentSortDirection, setDepartmentSortDirection] = useState(true);
  const [schoolSortDirection, setSchoolSortDirection] = useState(true);
  const [sectionCountSortDirection, setSectionCountSortDirection] =
    useState(true);
  const [studentCountSortDirection, setStudentCountSortDirection] =
    useState(true);
  const [passRateSortDirection, setPassRateSortDirection] = useState(true);
  const [lastSortedColumn, setLastSortedColumn] = useState('number');

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  /* const currentCourses = courses.slice(indexOfFirstItem, indexOfLastItem); */
  let filteredCourses = courses;

  if (selectedSchools.length > 0) {
    filteredCourses = courses.filter((course) =>
      selectedSchools.includes(course?.school_name),
    );
  }

  const currentCourses = filteredCourses.slice(
    indexOfFirstItem,
    indexOfLastItem,
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();

  useEffect(() => {
    getSchools();
    postPageView(baseUrl, user.role, 'course-list');
  }, []);

  useEffect(() => {
    getCourses();
  }, [onlyShowCoursesWithGrades]);

  let getCourses = async () => {
    setLoading(true);
    let response = await fetch(
      `${baseUrl}/sis/detailed-course-list/?active=true&has_grades=${onlyShowCoursesWithGrades}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setCourses(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  let getSchools = async () => {
    setLoadingSchools(true);
    let response = await fetch(`${baseUrl}/sis/schools/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setSchools(data);
      setLoadingSchools(false);
    } else if (response.status === 403) {
      setLoadingSchools(false);
      navigate('/unauthorized');
    }
  };

  const sortCoursesByNumber = () => {
    const direction =
      lastSortedColumn === 'number' ? !numberSortDirection : true;
    setNumberSortDirection(direction);
    setLastSortedColumn('number');
    const sortedCourses = [...courses].sort((a, b) => {
      const numberA = a.number ? a.number.toLowerCase() : '';
      const numberB = b.number ? b.number.toLowerCase() : '';
      return numberA.localeCompare(numberB);
    });
    if (!direction) {
      sortedCourses.reverse();
    }
    setCourses(sortedCourses);
  };

  const sortCoursesByTitle = () => {
    const direction = lastSortedColumn === 'title' ? !titleSortDirection : true;
    setTitleSortDirection(direction);
    setLastSortedColumn('title');
    const sortedCourses = [...courses].sort((a, b) => {
      const titleA = a.title ? a.title.toLowerCase() : '';
      const titleB = b.title ? b.title.toLowerCase() : '';
      return titleA.localeCompare(titleB);
    });
    if (!direction) {
      sortedCourses.reverse();
    }
    setCourses(sortedCourses);
  };

  const sortCoursesByLevel = () => {
    const direction = lastSortedColumn === 'level' ? !levelSortDirection : true;
    setLevelSortDirection(direction);
    setLastSortedColumn('level');
    const sortedCourses = [...courses].sort((a, b) => {
      const levelA = a.level_name ? a.level_name.toLowerCase() : '';
      const levelB = b.level_name ? b.level_name.toLowerCase() : '';
      return levelA.localeCompare(levelB);
    });
    if (!direction) {
      sortedCourses.reverse();
    }
    setCourses(sortedCourses);
  };

  const sortCoursesByDepartment = () => {
    const direction =
      lastSortedColumn === 'department' ? !departmentSortDirection : true;
    setDepartmentSortDirection(direction);
    setLastSortedColumn('department');
    const sortedCourses = [...courses].sort((a, b) => {
      const departmentA = a.department ? a.department.toLowerCase() : '';
      const departmentB = b.department ? b.department.toLowerCase() : '';

      if (departmentA === '' && departmentB === '') {
        return 0;
      } else if (departmentA === '') {
        return 1;
      } else if (departmentB === '') {
        return -1;
      }

      return departmentA.localeCompare(departmentB);
    });
    if (!direction) {
      sortedCourses.reverse();
    }
    setCourses(sortedCourses);
  };

  const sortCoursesBySchool = () => {
    const direction =
      lastSortedColumn === 'school' ? !schoolSortDirection : true;
    setSchoolSortDirection(direction);
    setLastSortedColumn('school');
    const sortedCourses = [...courses].sort((a, b) => {
      const schoolA = a.school_name ? a.school_name.toLowerCase() : '';
      const schoolB = b.school_name ? b.school_name.toLowerCase() : '';
      return schoolA.localeCompare(schoolB);
    });
    if (!direction) {
      sortedCourses.reverse();
    }
    setCourses(sortedCourses);
  };

  const sortCoursesBySectionCount = () => {
    const direction =
      lastSortedColumn === 'sectionCount' ? !sectionCountSortDirection : true;
    setSectionCountSortDirection(direction);
    setLastSortedColumn('sectionCount');
    const sortedCourses = [...courses].sort((a, b) => {
      const sectionCountA = a.section_count || 0;
      const sectionCountB = b.section_count || 0;
      return sectionCountA - sectionCountB;
    });
    if (!direction) {
      sortedCourses.reverse();
    }
    setCourses(sortedCourses);
  };

  const sortCoursesByStudentCount = () => {
    const direction =
      lastSortedColumn === 'studentCount' ? !studentCountSortDirection : true;
    setStudentCountSortDirection(direction);
    setLastSortedColumn('studentCount');
    const sortedCourses = [...courses].sort((a, b) => {
      const studentCountA = a.student_count || 0;
      const studentCountB = b.student_count || 0;
      return studentCountA - studentCountB;
    });
    if (!direction) {
      sortedCourses.reverse();
    }
    setCourses(sortedCourses);
  };

  const sortCoursesByPassRate = () => {
    const direction =
      lastSortedColumn === 'passRate' ? !passRateSortDirection : true;
    setPassRateSortDirection(direction);
    setLastSortedColumn('passRate');
    const sortedCourses = [...courses].sort((a, b) => {
      const passRateA = a.pass_percent || 0;
      const passRateB = b.pass_percent || 0;
      return passRateA - passRateB;
    });
    if (!direction) {
      sortedCourses.reverse();
    }
    setCourses(sortedCourses);
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-12 sm:pb-16 lg:pb-20'>
              <CourseListTable
                sortCoursesByNumber={sortCoursesByNumber}
                sortCoursesByTitle={sortCoursesByTitle}
                sortCoursesByLevel={sortCoursesByLevel}
                sortCoursesByDepartment={sortCoursesByDepartment}
                sortCoursesBySchool={sortCoursesBySchool}
                sortCoursesBySectionCount={sortCoursesBySectionCount}
                sortCoursesByStudentCount={sortCoursesByStudentCount}
                sortCoursesByPassRate={sortCoursesByPassRate}
                loading={loading}
                data={currentCourses}
                allData={courses}
                setShowPagination={setShowPagination}
                lastSortedColumn={lastSortedColumn}
                numberSortDirection={numberSortDirection}
                titleSortDirection={titleSortDirection}
                levelSortDirection={levelSortDirection}
                departmentSortDirection={departmentSortDirection}
                schoolSortDirection={schoolSortDirection}
                sectionCountSortDirection={sectionCountSortDirection}
                studentCountSortDirection={studentCountSortDirection}
                passRateSortDirection={passRateSortDirection}
              />
              {showPagination && (
                <Pagination
                  itemsPerPage={itemsPerPage}
                  totalItems={filteredCourses.length}
                  paginate={paginate}
                  currentPage={currentPage}
                  currentItems={currentCourses}
                />
              )}
            </div>
          }
          heading={'Courses'}
          courseListCurrent={true}
          sortComponent={
            <CourseListFilter
              setOnlyShowCoursesWithGrades={setOnlyShowCoursesWithGrades}
              schools={schools}
              setSelectedSchools={setSelectedSchools}
            />
          }
        />
      </div>
    </AccessWrapper>
  );
}
