import SidebarLayout from '../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import AccessWrapper from '../components/AccessWrapper';
import { Link, useNavigate } from 'react-router-dom';
import YearSortFlyout from '../components/YearSortFlyout';
import VerticalBarChart from '../components/pages/mtss/school/performance_summary/VerticalBarChart';
import PercentCircle from '../components/pages/mtss/school/performance_summary/PercentCircle';
import ValueAddedChart from '../components/pages/mtss/school/performance_summary/ValueAddedChart';
import HorizontalBarChart from '../components/pages/mtss/school/performance_summary/HorizontalBarChart';
import TrendChart from '../components/pages/mtss/school/performance_summary/TrendChart';
import StarTrendTable from '../components/pages/mtss/school/performance_summary/StarTrendTable';
import IarTrendTable from '../components/pages/mtss/school/performance_summary/IarTrendTable';
import ScatterChart from '../components/pages/mtss/school/performance_summary/ScatterChart';
import GradeSortFlyout from '../components/GradeSortFlyout';
import SubjectSelectorButton from '../components/pages/mtss/school/performance_summary/SubjectSelectorButton';

export default function YearlyPerformanceSummary() {
  const { authTokens, postPageView, baseUrl, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [schoolYears, setSchoolYears] = useState([]);
  const [loadingSchoolYears, setLoadingSchoolYears] = useState(false);
  const [selectedYear, setSelectedYear] = useState(null);
  const [starScores, setStarScores] = useState([]);
  const [loadingStarScores, setLoadingStarScores] = useState(false);
  const [iarElaScores, setIarElaScores] = useState([]);
  const [loadingIarElaScores, setLoadingIarElaScores] = useState(false);
  const [iarMathScores, setIarMathScores] = useState([]);
  const [loadingIarMathScores, setLoadingIarMathScores] = useState(false);
  const [valueAddedScores, setValueAddedScores] = useState([]);
  const [loadingValueAddedScores, setLoadingValueAddedScores] = useState(false);
  const [starBarData, setStarBarData] = useState([]);
  const [loadingStarBarData, setLoadingStarBarData] = useState(false);
  const [starScatterData, setStarScatterData] = useState([]);
  const [loadingStarScatterData, setLoadingStarScatterData] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedStarScatterSubject, setSelectedStarScatterSubject] =
    useState('reading');
  const [iarProficiencyData, setIarProficiencyData] = useState([]);
  const [loadingIarProficiencyData, setLoadingIarProficiencyData] =
    useState(false);
  const [starTrendData, setStarTrendData] = useState([]);
  const [starTrendDataSliced, setStarTrendDataSliced] = useState([]);
  const [loadingStarTrendData, setLoadingStarTrendData] = useState(false);
  const [iarTrendData, setIarTrendData] = useState([]);
  const [iarTrendDataSliced, setIarTrendDataSliced] = useState([]);
  const [loadingIarTrendData, setLoadingIarTrendData] = useState(false);

  useEffect(() => {
    getSchoolYears();
  }, []);

  useEffect(() => {
    if (selectedYear) {
      getStarScores();
      getIarElaScores();
      getIarMathScores();
      getValueAddedScores();
      getStarBarData();
      getStarScatterData();
      getIarProficiencyData();
      getStarTrendData();
      getIarTrendData();
    }
  }, [selectedYear]);

  useEffect(() => {
    getStarScatterData();
  }, [selectedStarScatterSubject, selectedGrade]);

  useEffect(() => {
    postPageView(baseUrl, user.role, 'yearly-performance-summary');
  }, []);

  const getSchoolYears = async () => {
    try {
      setLoadingSchoolYears(true);

      const response = await fetch(`${baseUrl}/sis/school-years/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      const data = await response.json();

      setLoadingSchoolYears(false);

      if (response.ok) {
        setSchoolYears(data);
        setSelectedYear(data[0]?.id);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching school years: ${response.status}`);
      }
    } catch (error) {
      setLoadingSchoolYears(false);
      console.error(error);
    }
  };

  const getStarScores = async () => {
    try {
      setLoadingStarScores(true);

      const response = await fetch(
        `${baseUrl}/assessment/star-percents/?year=${selectedYear}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingStarScores(false);

      if (response.ok) {
        setStarScores(data?.data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching star scores: ${response.status}`);
      }
    } catch (error) {
      setLoadingStarScores(false);
      console.error(error);
    }
  };

  const getIarElaScores = async () => {
    try {
      setLoadingIarElaScores(true);

      const response = await fetch(
        `${baseUrl}/assessment/iar-proficiency-historical/?subject=ela/L&year=${selectedYear}&district_wide=1`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingIarElaScores(false);

      if (response.ok) {
        setIarElaScores(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching iar ela scores: ${response.status}`);
      }
    } catch (error) {
      setLoadingIarElaScores(false);
      console.error(error);
    }
  };

  const getIarMathScores = async () => {
    try {
      setLoadingIarMathScores(true);

      const response = await fetch(
        `${baseUrl}/assessment/iar-proficiency-historical/?subject=math&year=${selectedYear}&district_wide=1`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingIarMathScores(false);

      if (response.ok) {
        setIarMathScores(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching iar math scores: ${response.status}`);
      }
    } catch (error) {
      setLoadingIarMathScores(false);
      console.error(error);
    }
  };

  const getValueAddedScores = async () => {
    try {
      setLoadingValueAddedScores(true);

      const response = await fetch(
        `${baseUrl}/assessment/value-added-summary/?year=${selectedYear}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingValueAddedScores(false);

      if (response.ok) {
        setValueAddedScores(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching value added scores: ${response.status}`);
      }
    } catch (error) {
      setLoadingValueAddedScores(false);
      console.error(error);
    }
  };

  const getStarBarData = async () => {
    try {
      setLoadingStarBarData(true);

      const response = await fetch(
        `${baseUrl}/assessment/star-score-by-grade/?year=${selectedYear}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingStarBarData(false);

      if (response.ok) {
        setStarBarData(data?.data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching star bar data: ${response.status}`);
      }
    } catch (error) {
      setLoadingStarBarData(false);
      console.error(error);
    }
  };

  const getStarScatterData = async () => {
    try {
      setLoadingStarScatterData(true);

      const response = await fetch(
        `${baseUrl}/assessment/star-spring-fall/?year=${selectedYear}&subject=${selectedStarScatterSubject}${
          selectedGrade !== null ? `&grade_level=${selectedGrade}` : ''
        }`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingStarScatterData(false);

      if (response.ok) {
        setStarScatterData(data?.data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching star scatter data: ${response.status}`);
      }
    } catch (error) {
      setLoadingStarScatterData(false);
      console.error(error);
    }
  };

  const getIarProficiencyData = async () => {
    try {
      setLoadingIarProficiencyData(true);

      const response = await fetch(
        `${baseUrl}/assessment/iar-proficiency-by-grade/?year=${selectedYear}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      setLoadingIarProficiencyData(false);

      if (response.ok) {
        setIarProficiencyData(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(
          `Error fetching iar proficiency data: ${response.status}`,
        );
      }
    } catch (error) {
      setLoadingIarProficiencyData(false);
      console.error(error);
    }
  };

  const getStarTrendData = async () => {
    try {
      setLoadingStarTrendData(true);

      const response = await fetch(
        `${baseUrl}/assessment/star-proficiency-trend/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      if (response.ok) {
        setStarTrendData(data?.data);
        setStarTrendDataSliced(data?.data?.slice(-5));
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching star trend data: ${response.status}`);
      }
      setLoadingStarTrendData(false);
    } catch (error) {
      setLoadingStarTrendData(false);
      console.error(error);
    }
  };

  const getIarTrendData = async () => {
    try {
      setLoadingIarTrendData(true);

      const response = await fetch(
        `${baseUrl}/assessment/iar-proficiency-trend/`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        },
      );

      const data = await response.json();

      if (response.ok) {
        setIarTrendData(data);
        setIarTrendDataSliced(data?.slice(-5));
      } else if (response.status === 403) {
        navigate('/unauthorized');
      } else {
        console.error(`Error fetching iar trend data: ${response.status}`);
      }
      setLoadingIarTrendData(false);
    } catch (error) {
      setLoadingIarTrendData(false);
      console.error(error);
    }
  };

  return (
    <AccessWrapper
      allowedGroups={[
        'mtss_member',
        'mtss_supervisor',
        'mtss_admin',
        'district_admin',
      ]}
    >
      <div>
        <SidebarLayout
          propComponents={
            <div className='mt-12 pb-20 sm:mt-16 lg:mt-20'>
              <div className='mx-auto'>
                <section className='mx-auto px-4 sm:px-6 lg:px-8'>
                  <div className='mx-auto max-w-7xl'>
                    <YearSortFlyout
                      data={schoolYears}
                      selectedYear={selectedYear}
                      setSelectedYear={setSelectedYear}
                    />
                  </div>
                  {/* section one grid */}
                  <div className='mx-auto mt-6 grid max-w-7xl grid-cols-6 justify-between gap-x-6 gap-y-12 sm:mt-8 sm:gap-x-8 sm:gap-y-16 xl:gap-y-0'>
                    {/* vertical bar chart */}
                    <div className='col-span-6 w-full sm:col-span-3 xl:col-span-2'>
                      <VerticalBarChart
                        data={starScores}
                        loading={loadingStarScores}
                      />
                    </div>
                    {/* IAR */}
                    <div className='col-span-6 flex w-full flex-col sm:col-span-3 xl:col-span-2'>
                      <div className='flex h-[225px] w-full flex-row justify-between space-x-8'>
                        <div className='flex h-full w-full'>
                          <PercentCircle
                            data={iarElaScores}
                            loading={loadingIarElaScores}
                            selector={'proficiency'}
                            title={'ELA'}
                          />
                        </div>
                        <div className='flex h-full w-full'>
                          <PercentCircle
                            data={iarMathScores}
                            loading={loadingIarMathScores}
                            selector={'proficiency'}
                            title={'Math'}
                          />
                        </div>
                      </div>
                      <div className=''>
                        <h2 className='mt-5 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                          IAR Proficiency
                        </h2>
                      </div>
                    </div>
                    {/* value added */}
                    <div className='col-span-6 w-full xl:col-span-2'>
                      <div className='h-[225px] w-full'>
                        <ValueAddedChart
                          data={valueAddedScores}
                          loading={loadingValueAddedScores}
                        />
                      </div>
                      <div className=''>
                        <h2 className='mt-5 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                          Value Added
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* divider */}
                  <div className='mx-auto mt-12 h-[3px] max-w-7xl rounded-3xl bg-gray-100 sm:mt-16 lg:mt-20'></div>
                </section>
                <section className='mx-auto mt-12 px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
                  <div className='mx-auto grid max-w-7xl grid-cols-2 gap-x-6 gap-y-12 sm:gap-x-8 sm:gap-y-16'>
                    <div className='col-span-2 w-full xl:col-span-1'>
                      <HorizontalBarChart
                        starBarData={starBarData}
                        loadingStarBarData={loadingStarBarData}
                        iarProficiencyData={iarProficiencyData}
                        loadingIarProficiencyData={loadingIarProficiencyData}
                      />
                      <h2 className='mt-5 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                        Star Averages &{' '}
                        <br className='hidden xl:block 2xl:hidden' />{' '}
                        Proficiency by Grade
                      </h2>
                    </div>
                    <div className='col-span-2 w-full xl:col-span-1'>
                      <div className='flex h-10 w-full flex-row items-center justify-center space-x-6'>
                        <GradeSortFlyout
                          selectedGrade={selectedGrade}
                          setSelectedGrade={setSelectedGrade}
                        />
                        <SubjectSelectorButton
                          selectedSubject={selectedStarScatterSubject}
                          setSelectedSubject={setSelectedStarScatterSubject}
                        />
                      </div>
                      <ScatterChart
                        data={starScatterData}
                        loading={loadingStarScatterData}
                      />
                      <h2 className='mt-5 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                        Star Spring/Fall{' '}
                        <br className='hidden xl:block 2xl:hidden' />{' '}
                        {selectedStarScatterSubject === 'reading'
                          ? 'ELA '
                          : 'Math '}
                        Scores Comparison
                      </h2>
                    </div>
                  </div>
                  {/* divider */}
                  <div className='mx-auto mt-12 h-[3px] max-w-7xl rounded-3xl bg-gray-100 sm:mt-16 lg:mt-20'></div>
                </section>
                <section className='mx-auto mt-12 px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
                  <div className='mx-auto max-w-7xl'>
                    <h2 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
                      Trends
                    </h2>
                  </div>
                  <div className='mx-auto grid max-w-7xl grid-cols-2 gap-x-6 gap-y-12 sm:gap-x-8 sm:gap-y-16'>
                    <div className='col-span-2 w-full md:col-span-1 lg:col-span-2 xl:col-span-1'>
                      <TrendChart
                        data={starTrendDataSliced}
                        loading={loadingStarTrendData}
                        title={'Star'}
                      />
                    </div>
                    <div className='col-span-2 w-full md:col-span-2 lg:col-span-2 xl:col-span-1'>
                      <TrendChart
                        data={iarTrendDataSliced}
                        loading={loadingIarTrendData}
                        title={'IAR'}
                      />
                    </div>
                  </div>
                </section>
                <section className='mx-auto mt-12 px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
                  <div className='mx-auto grid max-w-7xl grid-cols-2 gap-x-6 gap-y-12 sm:gap-x-8 sm:gap-y-16'>
                    <div className='col-span-2 h-full w-full 2xl:col-span-1'>
                      <StarTrendTable data={starTrendData} />
                    </div>
                    <div className='col-span-2 h-full w-full 2xl:col-span-1'>
                      <IarTrendTable data={iarTrendData} />
                    </div>
                  </div>
                </section>
                {/* <section className='mx-auto mt-12 px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
                  <div className='mx-auto flex h-96 max-w-7xl items-center justify-center rounded-2xl bg-gray-100'>
                    <p className='text-2xl font-bold text-brandText'>
                      Coming Soon
                    </p>
                  </div>
                </section> */}
                <section className='mx-auto mt-12 px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
                  <div className='mx-auto flex h-full w-full max-w-7xl flex-row items-center justify-center space-x-4 sm:space-x-6 lg:space-x-8'>
                    <Link
                      to={'/mtss/teacher-list'}
                      className='flex w-full items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:px-8 lg:w-auto'
                    >
                      Teachers
                    </Link>
                    <Link
                      to={'/mtss/course-list'}
                      className='flex w-full items-center justify-center whitespace-nowrap rounded-md border-2 border-transparent bg-brandPrimary px-4 py-2 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-110 hover:bg-brandPrimaryLight focus:border-brandPrimary focus:outline-none focus:ring-1 focus:ring-brandPrimary sm:px-8 lg:w-auto'
                    >
                      Courses
                    </Link>
                  </div>
                </section>
              </div>
            </div>
          }
          maxWidth={'max-w-none'}
          heading={'Overall Performance Summary'}
        />
      </div>
    </AccessWrapper>
  );
}
