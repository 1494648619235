import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import LoadingSpinner from '../../../../elements/LoadingSpinner';

export default function HorizontalBarChart({
  data,
  loading,
  dataSelectorOne,
  dataSelectorTwo,
  title,
  leftPadding,
  rightPadding,
  topPadding,
  bottomPadding,
  tooltipTextOne,
  tooltipTextTwo,
  height,
}) {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div className='rounded-md bg-white p-4 text-lg font-medium shadow-md'>
          <p className='text-brandText'>{`${tooltipTextOne} ${data[dataSelectorOne]}`}</p>
          <p className='text-brandText'>{`${tooltipTextTwo} ${data[dataSelectorTwo]}`}</p>
        </div>
      );
    }

    return null;
  };

  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const windowKey = `${windowDimensions.width}-${windowDimensions.height}`;

  // Check if data is loaded and not empty
  const isDataLoaded = !loading && Array.isArray(data) && data.length > 0;

  // Sort and slice the data if it's loaded
  const sortedData = isDataLoaded
    ? [...data] // Create a copy of the data array
        .sort((a, b) => b[dataSelectorTwo] - a[dataSelectorTwo]) // Sort in descending order
        .slice(0, 5) // Slice to get top 5 items
    : [];

  return (
    <div className='flex w-full flex-col items-center'>
      <h2 className='mb-5 text-center text-2xl font-normal text-brandPrimary sm:text-3xl'>
        {title}
      </h2>
      {loading ? (
        <div
          style={{ height: height }}
          className={`flex w-full items-center justify-center`}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div style={{ width: '100%', height: height }}>
          <ResponsiveContainer key={windowKey}>
            <BarChart
              layout='vertical'
              data={sortedData}
              margin={{
                top: topPadding,
                right: rightPadding,
                left: leftPadding,
                bottom: bottomPadding,
              }}
            >
              <defs>
                <linearGradient id='colorUv2' x1='0' y1='0' x2='1' y2='0'>
                  <stop offset='0%' stopColor='#11CCEA' stopOpacity={1} />
                  <stop offset='100%' stopColor='#e9fbfd' stopOpacity={1} />
                </linearGradient>
              </defs>
              <XAxis type='number' hide={true} />
              <YAxis
                dataKey={dataSelectorOne}
                type='category'
                tickLine={false}
                axisLine={false}
                stroke='#636363'
                fontWeight={600}
                fontSize={13}
                tickMargin={10}
                tick={{ dy: -5, fontSize: 12, letterSpacing: '-0.3px' }}
              />
              <Tooltip
                cursor={{ fill: 'transparent' }}
                content={CustomTooltip}
              />
              <Bar
                dataKey={dataSelectorTwo}
                /* fill='url(#colorUv2)' */
                fill='#11CCEA'
                radius={[5, 5, 5, 5]}
                isAnimationActive={false}
                stroke='#636363'
                strokeWidth={1}
              ></Bar>
              <path
                d={`M${0},${223} h ${1000}`}
                stroke='#636363'
                strokeWidth={3}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}

HorizontalBarChart.defaultProps = {
  leftPadding: 0,
  rightPadding: 1,
  topPadding: 0,
  bottomPadding: 15,
  tooltipTextOne: '',
  tooltipTextTwo: '',
  height: 225,
};
