import SidebarLayout from '../../components/SidebarLayout';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import Table from '../../components/MtssManagement/Table';
import AccessWrapper from '../../components/AccessWrapper';
import { useNavigate } from 'react-router-dom';

export default function Outcomes() {
  let { authTokens, postPageView, baseUrl, user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  /* state variables to hold json data from the back-end */
  let [outcomes, setOutcomes] = useState([]);

  useEffect(() => {
    getOutcomes();
    postPageView(baseUrl, user.role, 'mtss-management-outcomes');
  }, []);

  let getOutcomes = async () => {
    setLoading(true);
    let response = await fetch(`${baseUrl}/mtss/outcomes/manage_list/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setOutcomes(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  return (
    <AccessWrapper allowedGroups={['mtss_admin']}>
      <div>
        <SidebarLayout
          propComponents={
            <div className='pb-12 sm:pb-16 lg:pb-20'>
              <Table
                loading={loading}
                data={outcomes}
                path={'/mtss/outcome-update/'}
                dotSelector={'outcome_name'}
                createNewLink={'/mtss/add-outcome'}
              />
            </div>
          }
          heading={'Outcomes'}
        />
      </div>
    </AccessWrapper>
  );
}
