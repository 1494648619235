import { Dialog, Transition } from '@headlessui/react';
import AtAGlanceWrapper from '../Dashboard/AtAGlanceWrapper';
import React, { useState, useEffect, useContext, Fragment } from 'react';
import AuthContext from '../../../context/AuthContext';
import { useNavigate, useParams, Link } from 'react-router-dom';
import {
  getAttendanceSummary,
  getHistoricalGradeSummary,
} from '../../../common/api/student';

export default function AtAGlanceModal({ open, setOpen, id }) {
  const { authTokens, logoutUser, baseUrl } = useContext(AuthContext);
  const navigate = useNavigate();

  /* state variable to hold selected student */
  const [student, setStudent] = useState();
  const [studentSchedule, setStudentSchedule] = useState(undefined);
  const [studentCounts, setStudentCounts] = useState();
  const [readingScores, setReadingScores] = useState([]);
  const [mathScores, setMathScores] = useState([]);
  const [studentSummary, setStudentSummary] = useState();
  const [studentGradeSummary, setStudentGradeSummary] = useState(undefined);
  const [loadingHistoricalGrades, setLoadingHistoricalGrades] = useState(false);

  useEffect(() => {
    if (id !== undefined) {
      getStudent();
      getStudentSchedule();
      getStudentCounts();
      getReadingScores();
      getMathScores();
    }
  }, [id]);

  useEffect(() => {
    if (id !== undefined) {
      (async () => {
        try {
          const responseData = await getAttendanceSummary(
            authTokens,
            logoutUser,
            id,
            baseUrl,
          );

          const data = responseData?.data;

          if (Array.isArray(data)) {
            const [studentSummary] = data;
            setStudentSummary(studentSummary);
          }
        } catch (error) {
          // already caught
        }
      })();
    }
  }, [id]);

  useEffect(() => {
    if (id !== undefined) {
      (async () => {
        setLoadingHistoricalGrades(true);
        try {
          const responseData = await getHistoricalGradeSummary(
            authTokens,
            logoutUser,
            id,
            baseUrl,
          );
          const data = responseData?.data;
          setStudentGradeSummary(
            Array.isArray(data) && data.length ? data : data,
          );
          setLoadingHistoricalGrades(false);
        } catch (error) {
          // already caught
          setLoadingHistoricalGrades(false);
        }
      })();
    }
  }, [id]);

  let getStudent = async () => {
    let response = await fetch(`${baseUrl}/sis/students/${id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudent(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  let getStudentSchedule = async () => {
    let response = await fetch(`${baseUrl}/sis/stu-scheds/?student=${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudentSchedule(data.results);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  let getStudentCounts = async () => {
    let response = await fetch(`${baseUrl}/mtss/mtss-counts/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setStudentCounts(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  let getReadingScores = async () => {
    let response = await fetch(
      `${baseUrl}/assessment/star/?student=${id}&subject=reading`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setReadingScores(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  let getMathScores = async () => {
    let response = await fetch(
      `${baseUrl}/assessment/star/?student=${id}&subject=math`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setMathScores(data);
    } else if (response.statusText === 'Unauthorized') {
      logoutUser();
    }
  };

  const navigateToAAG = () => {
    navigate(`/students/at-a-glance/${id}`);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-50' onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-50 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-0 text-center sm:items-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='relative w-full transform overflow-hidden bg-white px-0 pt-5 text-left shadow-xl transition-all sm:max-w-none lg:my-8 lg:mx-8 lg:rounded-lg'>
                <div className='flex flex-row justify-between space-x-4 px-4 pb-3 sm:px-6 sm:pb-5 lg:px-8'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md border border-transparent bg-brandPrimary px-4 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:bg-brandPrimaryLight focus:outline-none focus:ring-brandPrimary md:focus:ring-2 md:focus:ring-offset-2'
                    onClick={() => setOpen(false)}
                  >
                    Close
                  </button>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md border border-transparent bg-brandPrimary px-4 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:bg-brandPrimaryLight focus:outline-none focus:ring-brandPrimary md:focus:ring-2 md:focus:ring-offset-2'
                    onClick={() => navigateToAAG()}
                  >
                    View Full Details
                  </button>
                </div>
                <div>
                  {
                    <AtAGlanceWrapper
                      student={student}
                      studentSchedule={studentSchedule}
                      studentCounts={studentCounts}
                      readingScores={readingScores}
                      mathScores={mathScores}
                      studentSummary={studentSummary}
                      studentGradeSummary={studentGradeSummary}
                      getStudentCounts={getStudentCounts}
                      loadingHistoricalGrades={loadingHistoricalGrades}
                    />
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
