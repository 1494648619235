import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

const MultiDateRangeSlider = ({ setSelectedStartDate, setSelectedEndDate }) => {
  const [startVal, setStartVal] = useState('2020-08-01');
  const [endVal, setEndVal] = useState(new Date().toISOString().split('T')[0]);
  const range = useRef(null);

  const dateToTimestamp = (date) => {
    return new Date(date).getTime();
  };

  const timestampToPercentage = (timestamp) => {
    return (
      ((timestamp - dateToTimestamp('2020-08-01')) /
        (dateToTimestamp(new Date()) - dateToTimestamp('2020-08-01'))) *
      100
    );
  };

  // Define a debounced version of the handleStartChange function with a short delay (10ms)
  const debouncedHandleStartChange = useRef(
    debounce((timestamp) => {
      setStartVal(new Date(timestamp).toISOString().split('T')[0]);
    }, 100), // Short delay
  ).current;

  // Define a debounced version of the handleEndChange function with a short delay (10ms)
  const debouncedHandleEndChange = useRef(
    debounce((timestamp) => {
      setEndVal(new Date(timestamp).toISOString().split('T')[0]);
    }, 100), // Short delay
  ).current;

  useEffect(() => {
    const startPercentage = timestampToPercentage(dateToTimestamp(startVal));
    const endPercentage = timestampToPercentage(dateToTimestamp(endVal));

    if (range.current) {
      range.current.style.left = `${startPercentage}%`;
      range.current.style.width = `${endPercentage - startPercentage + 0.01}%`;
    }
  }, [startVal, endVal]);

  useEffect(() => {
    setSelectedStartDate(startVal);
    setSelectedEndDate(endVal);
  }, [startVal, endVal, setSelectedStartDate, setSelectedEndDate]);

  return (
    <div className='flex w-full flex-col items-center'>
      <div>
        <p className='slider-label mb-4 text-left font-bold text-white'>
          Date Range
        </p>
      </div>
      <div className='slider-height flex h-4 flex-col'>
        <input
          type='range'
          min={dateToTimestamp('2020-08-01')}
          max={dateToTimestamp(new Date())}
          value={dateToTimestamp(startVal)}
          onChange={(event) => {
            const timestamp = Math.min(
              Number(event.target.value),
              dateToTimestamp(endVal) - 86400000,
            );
            debouncedHandleStartChange(timestamp);
          }}
          className='thumb thumb--left'
          style={{
            zIndex:
              dateToTimestamp(startVal) >
                dateToTimestamp(new Date()) - 86400000 * 30 && '5',
          }}
        />
        <input
          type='range'
          min={dateToTimestamp('2020-08-01')}
          max={dateToTimestamp(new Date())}
          value={dateToTimestamp(endVal)}
          onChange={(event) => {
            const timestamp = Math.max(
              Number(event.target.value),
              dateToTimestamp(startVal) + 86400000,
            );
            debouncedHandleEndChange(timestamp);
          }}
          className='thumb thumb--right'
        />
        <div className='slider'>
          <div className='slider__track' />
          <div ref={range} className='slider__range' />
        </div>
      </div>
      <div>
        <p className='slider-label mb-6 text-left text-sm font-bold text-white'>
          {startVal} - {endVal}
        </p>
      </div>
    </div>
  );
};

MultiDateRangeSlider.propTypes = {
  setSelectedStartDate: PropTypes.func.isRequired,
  setSelectedEndDate: PropTypes.func.isRequired,
};

export default MultiDateRangeSlider;
