import ActionDetailsWrapper from '../components/sections/Dashboard/ActionDetailsWrapper';
import SidebarLayout from '../components/SidebarLayout';
import AccessWrapper from '../components/AccessWrapper';
import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';

export default function ActionDetails() {
  const { baseUrl, user, postPageView } = useContext(AuthContext);
  const [subheading, setSubheading] = useState('');

  useEffect(() => {
    postPageView(baseUrl, user.role, 'action-details');
  }, []);
  return (
    <AccessWrapper
      allowedGroups={['mtss_member', 'mtss_supervisor', 'mtss_admin']}
    >
      <div>
        <SidebarLayout
          propComponents={
            <ActionDetailsWrapper setSubheading={setSubheading} />
          }
          altHeading={'Action Details'}
          altSubheading={subheading}
        />
      </div>
    </AccessWrapper>
  );
}
