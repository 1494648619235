import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../context/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from '../../formComponents/DatePicker';
import TextBox from '../../formComponents/TextBox';
import TextInput from '../../formComponents/TextInput';
import SelectMenu from '../../formComponents/SelectMenu';
import ActionDetailsActionTable from './ActionDetailsActionsTable';
import ActionDetailsReferralsTable from './ActionDetailsReferralsTable';
import ActionDetailsChecksTable from './ActionDetailsChecksTable';
import Notification from '../../formComponents/Notification';
import TodaysDate from '../../../utils/TodaysDate';
import AddDays from '../../../utils/AddDays';
import ActionDetailsScatter from './ActionDetailsScatter';
import { Oval } from 'react-loader-spinner';
import WarningModal from '../../WarningModal';
import { InformationCircleIcon } from '@heroicons/react/outline';
import DisclaimerModal from '../../DisclaimerModal';

export default function ActionDetailsWrapper({ setSubheading }) {
  const { authTokens, baseUrl, user } = useContext(AuthContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [actionId, setActionId] = useState(location?.state?.item?.id);
  const [actionDetails, setActionDetails] = useState(null);
  const [show, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showCheckWarning, setShowCheckWarning] = useState(false);
  const [loadingChecks, setLoadingChecks] = useState(false);
  const [loadingActions, setLoadingActions] = useState(false);
  const [loadingSupports, setLoadingSupports] = useState(false);
  const [success, setSuccess] = useState(false);
  const [messageOne, setMessageOne] = useState('');
  const [messageTwo, setMessageTwo] = useState('');
  const [checkDuration, setCheckDuration] = useState(
    location?.state?.item?.treatment?.check_duration
      ? location?.state?.item?.treatment?.check_duration
      : 7,
  );
  const [nextCheckLabelColor, setNextCheckLabelColor] =
    useState('textBrandText ');
  const [nextCheckBorderColor, setNextCheckBorderColor] =
    useState('border-gray-300');
  const [commentLabelColor, setCommentLabelColor] = useState('textBrandText ');
  const [commentBorderColor, setCommentBorderColor] =
    useState('border-gray-300');
  const [prevURL, setPrevURL] = useState(undefined);
  const [assessmentIsNullOrNone, setAssessmentIsNullOrNone] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  /* state variables to hold json data from the back-end */
  const [categories, setCategories] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [concerns, setConcerns] = useState([]);
  const [sstMembers, setSstMembers] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [caregivers, setCaregivers] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [outcomes, setOutcomes] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [checks, setChecks] = useState([]);
  const [scatterData, setScatterData] = useState(undefined);

  /* state variables to hold form data to submit to the back-end */
  const [category, setCategory] = useState('');
  const [subject, setSubject] = useState('');
  const [concern, setConcern] = useState();
  const [selectedAssessment, setSelectedAssessment] = useState();
  const [selectedCheckAssessment, setSelectedCheckAssessment] = useState();
  const [sstMember, setSstMember] = useState();
  const [assessment, setAssessment] = useState(undefined);
  const [treatment, setTreatment] = useState();
  const [caregiver, setCaregiver] = useState();
  const [startDate, setStartDate] = useState(undefined);
  const [assessmentComment, setAssessmentComment] = useState(null);
  const [assessmentPrimaryScore, setAssessmentPrimaryScore] = useState(null);
  const [assessmentAltScore, setAssessmentAltScore] = useState(null);
  const [outcome, setOutcome] = useState();
  const [goal, setGoal] = useState('');
  const [comment, setComment] = useState('');
  const [id, setId] = useState();
  const [student, setStudent] = useState();
  const [nextCheck, setNextCheck] = useState(
    AddDays(TodaysDate(), checkDuration),
  );
  const [assessmentDate, setAssessmentDate] = useState(TodaysDate());
  const [checkCount, setCheckCount] = useState(0);

  // validate check
  const validateCheck = (e) => {
    if (
      comment?.trim().length >= 1 &&
      nextCheck !== null &&
      nextCheck !== undefined &&
      nextCheck !== ''
    ) {
      if (assessment === null) {
        handleCheck(e);
      } else if (assessment?.id === selectedCheckAssessment.id) {
        handleCheck(e);
      } else {
        setShowCheckWarning(true);
      }
    } else {
      setSuccess(false);
      setMessageOne('Failed to add check-in!');
      setMessageTwo('Please ensure that all fields have been completed.');
      setShow(true);

      if (nextCheck !== null && nextCheck !== undefined && nextCheck !== '') {
        setNextCheckLabelColor('textBrandText ');
        setNextCheckBorderColor('border-gray-300');
      } else {
        setNextCheckLabelColor('text-red-500 ');
        setNextCheckBorderColor('border-red-500');
      }

      if (comment?.trim().length >= 1 && comment !== undefined) {
        setCommentLabelColor('textBrandText ');
        setCommentBorderColor('border-gray-300');
      } else {
        setCommentLabelColor('text-red-500 ');
        setCommentBorderColor('border-red-500');
      }
    }
  };

  // validate update
  const validateUpdate = (e) => {
    if (startDate !== undefined && goal?.trim().length >= 1) {
      if (assessment === null) {
        handleUpdate(e);
      } else if (assessment.id === selectedAssessment.id) {
        handleUpdate(e);
      } else {
        setShowWarning(true);
      }
    } else {
      setSuccess(false);
      setMessageOne('Failed to update intervention!');
      setMessageTwo('Please ensure that all fields have been completed.');
      setShow(true);
    }
  };

  useEffect(() => {
    getActionDetails();
    getCategories();
    getSubjects();
    getConcerns();
    getSstMembers();
    getTreatments();
    getCaregivers();
    getAssessments();
    getOutcomes();
    getInterventions();
    getReferrals();
    getChecks();
    getScatterData();
  }, [checkCount]);

  // handle update
  let handleUpdate = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(
        `${baseUrl}/mtss/interventions/${location?.state?.item?.id}/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
          body: JSON.stringify({
            category: category?.id,
            subject: subject?.id,
            concern: concern?.id,
            treatment: treatment?.id,
            supervisor: sstMember?.id,
            caregiver: caregiver?.id,
            progress_assessment: selectedAssessment?.id,
            goal: goal,
            start_date: startDate,
          }),
        },
      );

      let data = await response.json();

      if (response.status === 200) {
        setSuccess(true);
        setAssessment(selectedAssessment);
        setMessageOne('Intervention updated successfully!');
        setMessageTwo('');
        setShow(true);
        setShowWarning(false);
      } else {
        setSuccess(false);
        setMessageOne('Failed to update intervention!');
        setMessageTwo('Please ensure that all fields have been completed.');
        setShow(true);
        setShowWarning(false);

        let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: '/mtss/action-details(update action)',
            district: baseUrl,
            user: user.name,
            error_description:
              'User failed to update action and received a status code.',
            response_text: JSON.stringify(data),
          }),
        });
      }
    } catch (error) {
      setSuccess(false);
      setMessageOne('Failed to update intervention!');
      setMessageTwo('Please ensure that all fields have been completed.');
      setShow(true);
      setShowWarning(false);
      console.log(error);

      let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: '/mtss/action-details(update action)',
          district: baseUrl,
          user: user.name,
          error_description:
            'User failed to update action and received an error.',
          response_text: JSON.stringify(error),
        }),
      });
    }
  };

  // handle check
  let handleCheck = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(`${baseUrl}/mtss/checks/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
        body: JSON.stringify({
          intervention: id,
          student: student,
          comment_text: comment,
          next_check: nextCheck,
          assessment_comment: assessmentComment,
          assessment_score: assessmentPrimaryScore,
          alt_assessment_score: assessmentAltScore,
          assessment_date: assessmentDate,
          progress_assessment: selectedCheckAssessment?.id,
        }),
      });

      let data = await response.json();

      if (response.status === 201) {
        setMessageOne('Check-in added successfully!');
        setMessageTwo('');
        setSuccess(true);
        setShow(true);
        setComment('');
        setAssessmentComment(null);
        setAssessmentPrimaryScore(null);
        setAssessmentAltScore(null);
        setNextCheck(AddDays(TodaysDate(), checkDuration));
        setNextCheckLabelColor('textBrandText ');
        setNextCheckBorderColor('border-gray-300');
        setCommentLabelColor('textBrandText ');
        setCommentBorderColor('border-gray-300');
        setCheckCount(checkCount + 1);
      } else {
        setNextCheckLabelColor('textBrandText ');
        setNextCheckBorderColor('border-gray-300');
        setCommentLabelColor('textBrandText ');
        setCommentBorderColor('border-gray-300');
        setMessageOne('Failed to submit check-in!');
        setMessageTwo(
          'Please ensure that all fields and selections have been completed.',
        );
        setSuccess(false);
        setShow(true);

        let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: '/mtss/action-details(check-in)',
            district: baseUrl,
            user: user.name,
            error_description:
              'User failed to add check and received a status code.',
            response_text: JSON.stringify(data),
          }),
        });
      }
      setShowCheckWarning(false);
    } catch (error) {
      setMessageOne('Failed to submit check-in!');
      setMessageTwo(
        'Please ensure that all fields and selections have been completed.',
      );
      setSuccess(false);
      setShow(true);
      console.log(error);

      let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: '/mtss/action-details(check-in)',
          district: baseUrl,
          user: user.name,
          error_description: 'User failed to add check and received an error.',
          response_text: JSON.stringify(error),
        }),
      });
    }
  };

  // handle end
  let handleEnd = async (e) => {
    e.preventDefault();
    try {
      let response = await fetch(
        `${baseUrl}/mtss/interventions/${location?.state?.item?.id}/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + String(authTokens.access),
          },
          body: JSON.stringify({
            status: 0,
            outcome: outcome.id,
          }),
        },
      );

      let data = await response.json();

      if (response.status === 200) {
        navigate(-1);
      } else {
        setSuccess(false);
        setMessageOne('Failed to end action!');
        setMessageTwo('Please try again.');
        setShow(true);

        let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            page: '/mtss/action-details(end action)',
            district: baseUrl,
            user: user.name,
            error_description:
              'User failed to end action and received a status code.',
            response_text: JSON.stringify(data),
          }),
        });
      }
    } catch (error) {
      setSuccess(false);
      setMessageOne('Failed to end action!');
      setMessageTwo('Please try again.');
      setShow(true);
      console.log(error);

      let errorResponse = await fetch(`${baseUrl}/support/error-log/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          page: '/mtss/action-details(end action)',
          district: baseUrl,
          user: user.name,
          error_description: 'User failed to end action and received an error.',
          response_text: JSON.stringify(error),
        }),
      });
    }
  };

  const navigateToForm = () => {
    navigate(`/mtss/add-action`, {
      state: {
        studentFirstName: location?.state?.item?.student?.first_name,
        studentLastName: location?.state?.item?.student?.last_name,
        studentId: location?.state?.item?.student?.id,
      },
    });
  };

  let getActionDetails = async () => {
    let response = await fetch(`${baseUrl}/mtss/interventions/${actionId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setActionDetails(data);
      setLoading(false);
    } else if (response.status === 403) {
      setLoading(false);
      navigate('/unauthorized');
    }
  };

  let getCategories = async () => {
    let response = await fetch(`${baseUrl}/mtss/categories/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setCategories(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getSubjects = async () => {
    let response = await fetch(`${baseUrl}/mtss/subjects/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setSubjects(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getConcerns = async () => {
    let response = await fetch(`${baseUrl}/mtss/concerns/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setConcerns(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getSstMembers = async () => {
    let response = await fetch(
      `${baseUrl}/sis/teachers/?active=true&profile__is_sst=true`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setSstMembers(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getTreatments = async () => {
    let response = await fetch(`${baseUrl}/mtss/treatments/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setTreatments(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getCaregivers = async () => {
    let response = await fetch(
      `${baseUrl}/sis/teachers/?active=true&profile__is_sst=true`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setCaregivers(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getOutcomes = async () => {
    let response = await fetch(`${baseUrl}/mtss/outcomes/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + String(authTokens.access),
      },
    });
    let data = await response.json();

    if (response.status === 200) {
      setOutcomes(data);
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  let getInterventions = async () => {
    setLoadingActions(true);
    let response = await fetch(
      `${baseUrl}/mtss/interventions/?student=${location?.state?.item?.student?.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setInterventions(data);
      setInterventions((current) =>
        current?.filter(
          (intervention) => intervention?.id !== location?.state?.item?.id,
        ),
      );
      setLoadingActions(false);
    } else if (response.status === 403) {
      setLoadingActions(false);
      navigate('/unauthorized');
    }
  };

  let getReferrals = async () => {
    setLoadingSupports(true);
    let response = await fetch(
      `${baseUrl}/mtss/referrals/?student=${location?.state?.item?.student?.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setReferrals(data);
      setLoadingSupports(false);
    } else if (response.status === 403) {
      setLoadingSupports(false);
      navigate('/unauthorized');
    }
  };

  const getAssessments = async () => {
    try {
      const response = await fetch(`${baseUrl}/mtss/progress-assessments/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      });
      const data = await response.json();

      if (response.status === 200) {
        setAssessments(data);
      } else if (response.status === 403) {
        navigate('/unauthorized');
      }
    } catch (error) {
      console.log(error);
    }
  };

  let getChecks = async () => {
    setLoadingChecks(true);
    let response = await fetch(
      `${baseUrl}/mtss/interventions/${location?.state?.item?.id}/`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setChecks(data?.checks);
      setLoadingChecks(false);
    } else if (response.status === 403) {
      setLoadingChecks(false);
      navigate('/unauthorized');
    }
  };

  let getScatterData = async () => {
    let response = await fetch(
      `${baseUrl}/mtss/check-scores/?intervention_id=${location?.state?.item?.id}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + String(authTokens.access),
        },
      },
    );
    let data = await response.json();

    if (response.status === 200) {
      setScatterData(data?.reverse());
    } else if (response.status === 403) {
      navigate('/unauthorized');
    }
  };

  useEffect(() => {
    if (actionDetails !== null) {
      setCategory(actionDetails?.category);
      setSubject(actionDetails?.subject);
      setConcern(actionDetails?.concern);
      setTreatment(actionDetails?.treatment);
      setSstMember(actionDetails?.supervisor);
      setCaregiver(actionDetails?.caregiver);
      setStartDate(actionDetails?.start_date);
      setSelectedCheckAssessment(actionDetails?.progress_assessment);
      setAssessment(actionDetails?.progress_assessment);
      setGoal(actionDetails?.goal);
      setComment(actionDetails?.comment);
      setId(actionDetails?.id);
      setStudent(actionDetails?.student?.id);
    }
  }, [actionDetails]);

  useEffect(() => {
    setSubheading(
      `${location?.state?.item?.student?.last_name}, ${location?.state?.item?.student?.first_name} - ${location?.state?.item?.treatment?.treatment_name}`,
    );
    setPrevURL(location?.state?.from);
  }, [location]);

  const handleSettingSelectedAssessment = () => {
    if (assessment === null || assessment === undefined) {
      setAssessmentIsNullOrNone(true);
      const tempAssessment = assessments?.filter(
        (item) => item?.assessment_name === 'None',
      );
      setSelectedAssessment(tempAssessment[0]);
    } else {
      assessment?.assessment_name === 'None'
        ? setAssessmentIsNullOrNone(true)
        : setAssessmentIsNullOrNone(false);
    }
    setSelectedAssessment(assessment);
  };

  const checkAssessmentIsNullOrNone = () => {
    if (assessment?.assessment_name === 'None') {
      setAssessmentIsNullOrNone(true);
    } else {
      setAssessmentIsNullOrNone(false);
    }
  };

  useEffect(() => {
    checkAssessmentIsNullOrNone();
    setSelectedCheckAssessment(assessment);
    handleSettingSelectedAssessment();
    getScatterData();
  }, [assessment]);

  return (
    <>
      {loading ? (
        <div className='flex h-screen w-full flex-row items-center justify-center'>
          <Oval
            height={80}
            width={80}
            color='#0CB2DA'
            wrapperStyle={{}}
            wrapperClass=''
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor='#e5e7eb'
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className='mt-12 px-4 sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
          {/* top navigation button section */}
          <div className='mx-auto flex flex-col justify-between lg:flex-row lg:space-x-3'>
            <button
              onClick={() => {
                navigate(prevURL);
              }}
              className='inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2'
            >
              Back to List
            </button>
            <button
              onClick={navigateToForm}
              className='mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 lg:mt-0'
            >
              Add Action
            </button>
            <button
              onClick={() => {
                document.getElementById('update').scrollIntoView();
              }}
              className='mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 lg:mt-0'
            >
              <div>Update Action</div>
            </button>
          </div>

          {/* check in section */}
          <div className='mt-5 flex w-full flex-col justify-evenly space-y-8 overflow-hidden rounded-lg border-2 border-slate-200 bg-white py-8 px-4 shadow-md sm:mt-6 sm:px-6 lg:px-8'>
            <div className='flex flex-row items-center justify-between'>
              <h2 className='text-2xl font-semibold text-brandPrimary'>
                Check In
              </h2>
              {assessmentIsNullOrNone && (
                <button
                  onClick={() => {
                    setShowDisclaimer(true);
                  }}
                  className='flex flex-row items-center space-x-1'
                >
                  <p className='font-medium text-brandPrimary'>
                    Progress Assessments
                  </p>
                  <InformationCircleIcon className='h-7 w-7 text-brandPrimary' />
                </button>
              )}
            </div>
            <div className='flex w-full flex-col space-x-8 xl:flex-row xl:items-center'>
              {!assessmentIsNullOrNone && (
                <div className='flex flex-col items-center justify-center xl:w-2/3'>
                  <div className='mx-auto flex w-full items-center justify-center'>
                    <h3 className='mb-5 text-2xl font-semibold text-brandPrimary'>
                      {assessment?.assessment_name}
                    </h3>
                  </div>
                  {scatterData !== undefined ? (
                    <div className='mx-auto flex w-full flex-col'>
                      {scatterData?.length === 0 ? (
                        <div className='mx-auto flex h-96 w-full flex-row items-center justify-center rounded-2xl p-10'>
                          <p className='text-4xl font-normal text-gray-200'>
                            No Records
                          </p>
                        </div>
                      ) : (
                        <div className='mx-auto flex w-full flex-col'>
                          <div className='mx-auto flex w-full flex-col'>
                            <ActionDetailsScatter
                              data={scatterData}
                              assessment={assessment}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className='mx-auto flex w-full flex-row items-center justify-center rounded-2xl p-10'>
                      <Oval
                        height={80}
                        width={80}
                        color='#0CB2DA'
                        wrapperStyle={{}}
                        wrapperClass=''
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor='#e5e7eb'
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    </div>
                  )}
                </div>
              )}
              <div
                className={`${
                  !assessmentIsNullOrNone ? 'xl:w-1/3 ' : 'xl:w-full'
                } mt-12 flex flex-col space-y-8 xl:mt-0`}
              >
                <DatePicker
                  label={'Next Check*'}
                  name={'Next Check'}
                  data={nextCheck}
                  setData={setNextCheck}
                  labelColor={nextCheckLabelColor}
                  borderColor={nextCheckBorderColor}
                />
                <TextBox
                  name={'Comment'}
                  displayName={'Comment*'}
                  data={comment}
                  setData={setComment}
                  rows={4}
                  labelColor={commentLabelColor}
                  borderColor={commentBorderColor}
                />
                {!assessmentIsNullOrNone && (
                  <>
                    <div>
                      <label
                        htmlFor={'Progress Assessment Type'}
                        className='block text-sm font-medium text-brandText'
                      >
                        Progress Assessment Type
                      </label>
                      <input
                        className='mt-1 block w-full rounded-md border-gray-300 text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
                        type='text'
                        disabled={true}
                        value={`${selectedCheckAssessment?.assessment_name}`}
                      />
                    </div>
                    <DatePicker
                      label={'Assessment Date'}
                      name={'Assessment Date'}
                      data={assessmentDate}
                      setData={setAssessmentDate}
                    />
                    <TextBox
                      name={'Assessment Note'}
                      displayName={'Assessment Note'}
                      data={assessmentComment}
                      setData={setAssessmentComment}
                      rows={2}
                    />
                    <div className='flex flex-row space-x-8'>
                      <div className='flex w-full flex-col'>
                        <label
                          htmlFor={
                            assessment?.score1_name
                              ? assessment?.score1_name
                              : 'Primary Score'
                          }
                          className='block text-sm font-medium text-brandText'
                        >
                          {assessment?.score1_name
                            ? assessment?.score1_name
                            : 'Primary Score'}
                        </label>
                        <div className='mt-1 flex w-full flex-col'>
                          <input
                            type={'number'}
                            name={'Primary Score'}
                            id={'primary-score'}
                            onChange={(e) => {
                              setAssessmentPrimaryScore(e.target.value);
                            }}
                            value={assessmentPrimaryScore}
                            className='block w-full rounded-md border-gray-300 text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
                          />
                        </div>
                      </div>
                      <div className='flex w-full flex-col'>
                        <label
                          htmlFor={
                            assessment?.score2_name
                              ? assessment?.score2_name
                              : 'Alt Score'
                          }
                          className='block text-sm font-medium text-brandText'
                        >
                          {assessment?.score2_name
                            ? assessment?.score2_name
                            : 'Alt Score'}
                        </label>
                        <div className='mt-1 flex w-full flex-col'>
                          <input
                            type={'number'}
                            name={'Alt Score'}
                            id={'alt-score'}
                            onChange={(e) => {
                              setAssessmentAltScore(e.target.value);
                            }}
                            value={assessmentAltScore}
                            className='block w-full rounded-md border-gray-300 text-brandText shadow-sm focus:border-brandPrimary focus:ring-brandPrimary sm:text-sm'
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <button
                  onClick={validateCheck}
                  className={`${
                    !assessmentIsNullOrNone && 'xl:w-full '
                  } flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 md:w-40`}
                >
                  Check In
                </button>
              </div>
            </div>
          </div>

          {/* check-ins for this action table */}
          <div className='mt-12 sm:mt-16 lg:mt-20'>
            <div>
              <ActionDetailsChecksTable loading={loadingChecks} data={checks} />
            </div>
          </div>

          {/* other actions table */}
          <div className='mt-12 sm:mt-16 lg:mt-20'>
            <div>
              <ActionDetailsActionTable
                loading={loadingActions}
                data={interventions}
                prevURL={prevURL}
              />
            </div>
          </div>

          {/* teacher supports and comments table */}
          <div className='mt-12 sm:mt-16 lg:mt-20'>
            <div>
              <ActionDetailsReferralsTable
                loading={loadingSupports}
                data={referrals}
              />
            </div>
          </div>

          {/* update action section */}
          <div className='relative mt-12 flex w-full flex-col justify-evenly space-y-8 overflow-x-hidden overflow-y-visible rounded-lg border-2 border-slate-200 bg-white py-8 px-4 shadow-md sm:mt-16 sm:px-6 lg:mt-20 lg:px-8'>
            <div id='update' className='absolute top-0 -mt-64'></div>
            <h2 className='text-2xl font-semibold text-brandPrimary'>
              Update Action
            </h2>
            <div className='grid grid-cols-1 gap-x-4 gap-y-8 lg:grid-cols-2'>
              <SelectMenu
                label={'Category'}
                data={categories}
                selectedData={category}
                setSelectedData={setCategory}
                dotSelector={'category_name'}
              />
              <SelectMenu
                label={'Subject'}
                data={subjects}
                selectedData={subject}
                setSelectedData={setSubject}
                dotSelector={'subject_name'}
              />
              <SelectMenu
                label={'Concern'}
                data={concerns}
                setSelectedData={setConcern}
                selectedData={concern}
                dotSelector={'concern_name'}
              />
              <SelectMenu
                label={'Treatment'}
                data={treatments}
                setSelectedData={setTreatment}
                selectedData={treatment}
                dotSelector={'treatment_name'}
              />
              <SelectMenu
                label={'SST Member'}
                data={sstMembers}
                setSelectedData={setSstMember}
                selectedData={sstMember}
                dotSelector={'name'}
              />
              <SelectMenu
                label={'Caregiver'}
                data={caregivers}
                setSelectedData={setCaregiver}
                selectedData={caregiver}
                dotSelector={'name'}
              />
              <SelectMenu
                label={'Progress Assessment'}
                data={assessments}
                setSelectedData={setSelectedAssessment}
                selectedData={selectedAssessment}
                dotSelector={'assessment_name'}
              />
              <TextInput
                name={'Goal'}
                displayName={'Goal'}
                data={goal}
                setData={setGoal}
              />
              <DatePicker
                label={'Start Date'}
                name={'Start Date'}
                data={startDate}
                setData={setStartDate}
              />
            </div>
            <button
              onClick={validateUpdate}
              className='inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm duration-300 ease-in-out hover:scale-105 hover:bg-brandPrimaryLight focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 md:w-40'
            >
              Update
            </button>
          </div>

          {/* end action section */}
          <div className='my-12 flex w-full flex-col justify-evenly space-y-8 overflow-hidden rounded-lg border-2 border-slate-200 bg-white py-8 px-4 shadow-md sm:my-16 sm:px-6 lg:my-20 lg:px-8'>
            <h2 className='text-2xl font-semibold text-brandPrimary'>
              End Action
            </h2>
            <SelectMenu
              label={'Select an Outcome'}
              data={outcomes}
              setSelectedData={setOutcome}
              selectedData={outcome}
              dotSelector={'outcome_name'}
            />
            <button
              onClick={handleEnd}
              className='inline-flex w-full items-center justify-center rounded-md border border-transparent bg-brandPrimary px-6 py-3 text-base font-medium text-white shadow-sm hover:scale-105 hover:bg-brandPrimaryLight hover:duration-300 hover:ease-in-out focus:outline-none focus:ring-2 focus:ring-brandPrimary focus:ring-offset-2 md:w-40'
            >
              End
            </button>
          </div>

          {/* notification modal */}
          <Notification
            success={success}
            show={show}
            setShow={setShow}
            messageOne={messageOne}
            messageTwo={messageTwo}
          />
          {/* warning modal */}
          <WarningModal
            open={showWarning}
            setOpen={setShowWarning}
            title={'Warning! - Updating Assessment Type'}
            message={
              'If you change the assessment type for this intervention, the progress chart will only show scores for the new assessment type. Are you sure you want to change the progress assessment type?'
            }
            action={handleUpdate}
            buttonText={'Confirm'}
          />
          <WarningModal
            open={showCheckWarning}
            setOpen={setShowCheckWarning}
            title={'Warning! - Updating Assessment Type'}
            message={
              'If you select an assessment type that is different than the one assigned to this intervention, the score will not be shown on the check in scatter chart. Are you sure you want to change the assessment type?'
            }
            action={handleCheck}
            buttonText={'Confirm'}
          />
          <DisclaimerModal
            title={'Progress Assessment'}
            open={showDisclaimer}
            setOpen={setShowDisclaimer}
            message={
              'The Progress Assessment fields and graph are currently hidden as there is no assessment type associated with this action. To view and enter this information, please update the intervention with the relevant assessment type.'
            }
          />
        </div>
      )}
    </>
  );
}
