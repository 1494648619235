export default function SubjectSelectorButton({
  selectedSubject,
  setSelectedSubject,
}) {
  return (
    <div className='flex flex-row space-x-1'>
      <button
        onClick={() => {
          setSelectedSubject('reading');
        }}
        className={`${
          selectedSubject === 'reading'
            ? 'bg-brandPrimaryLight text-white'
            : 'bg-brandPrimary text-white/40'
        } inline-flex items-center rounded-l-md border-2 border-transparent bg-brandPrimary px-4 py-1 text-base font-medium leading-6 duration-300 ease-out hover:bg-brandPrimaryLight focus:outline-none`}
      >
        ELA
      </button>
      <button
        onClick={() => {
          setSelectedSubject('math');
        }}
        className={`${
          selectedSubject === 'math'
            ? 'bg-brandPrimaryLight text-white'
            : 'bg-brandPrimary text-white/40'
        } inline-flex items-center rounded-r-md border-2 border-transparent bg-brandPrimary px-4 py-1 text-base font-medium leading-6 duration-300 ease-out  hover:bg-brandPrimaryLight focus:outline-none`}
      >
        Math
      </button>
    </div>
  );
}
